import { remove, sortedUniq } from 'lodash';
import store from '@/store';

const initialState = () => ({
    products: {
        interest: [],
        external: [],
    },
    oldClipboardProducts: [], // IDs array
    oldClipboardApplied: false, // die alten IDs in den aktuellen Merkzettel aufgenommen
    oldClipboardCloudReset: false, // IDs wurden in der cloud zurückgesetzt
});

const state = initialState();

// Getter functions
const getters = {
    getOldClipboardProducts(state) {
        return sortedUniq(state.oldClipboardProducts);
    },
    getClipboardProducts: (state) => {
        return state.products;
    },
    getClipboardProductSection: (state) => (section) => {
        let clipboardProducts = state.products[section];
        const excludedProducts = ['Vorsorge_Ausreichend_Vermoegen', 'Immobilie_Abgezahlte_Immobilie'];
        // Filter out excluded products
        clipboardProducts = clipboardProducts.filter((productId) => excludedProducts.indexOf(productId) === -1);
        return clipboardProducts;
    },
    oldClipboardApplied: (state) => {
        return state.oldClipboardApplied;
    },
    oldClipboardCloudReset: (state) => {
        return state.oldClipboardCloudReset;
    },
};
// Actions
const actions = {
    oldClipboardApplied({ commit }) {
        commit('mutate', {
            property: 'oldClipboardApplied',
            value: true,
        });
    },
    oldClipboardCloudReset({ commit }) {
        commit('mutate', {
            property: 'oldClipboardCloudReset',
            value: true,
        });
    },
    addToClipboard(context, { productId, clipboardSection }) {
        let clipboard = context.getters.getClipboardProducts[clipboardSection];

        // Add product to clipboard
        clipboard.push(productId);

        // Sort items by productId
        clipboard.sort();

        // Make items in clipboard unique
        clipboard = sortedUniq(clipboard);

        context.commit('ADD_TO_CLIPBOARD', {
            clipboardSection,
            clipboard,
        });

        if (clipboardSection === 'interest') {
            // add bounce animation of clipboard icon
            store.commit('UPDATE_ANIMATION_STATE', {
                animation: 'bounceClipboardIcon',
                payload: true,
            });

            if (store.state.animations.showClipboardTooltip && store.state.route.name === 'module') {
                store.commit('UPDATE_ANIMATION_STATE', {
                    animation: 'showClipboardTooltip',
                    payload: false,
                });

                if (clipboard.length > 0) {
                    // show clipboard tooltip
                    store.commit('UPDATE_VIEW', {
                        view: 'clipboardTooltip',
                        payload: true,
                    });
                    setTimeout(() => {
                        store.commit('UPDATE_VIEW', {
                            view: 'clipboardTooltip',
                            payload: false,
                        });
                    }, 3000);
                }
            }
        }
    },
    removeFromClipboard(context, { productId, clipboardSection }) {
        let clipboard = context.getters.getClipboardProducts[clipboardSection];

        // Remove product from clipboard
        clipboard = remove(clipboard, function(p) {
            return p !== productId;
        });

        // Sort items by productId
        clipboard.sort();

        // Make items in clipboard unique
        clipboard = sortedUniq(clipboard);

        context.commit('REMOVE_FROM_CLIPBOARD', {
            clipboardSection,
            clipboard,
        });
    },
    reset({ commit }) {
        commit('RESET');
    },
};

// Mutations
const mutations = {
    /**
     * Generic mutation
     *
     * commit('mutate', {
     *   property: <propertyNameHere>,
     *   value: <valueGoesHere>
     * });
     *
     * @param {String} state
     * @param payload
     */
    mutate(state, payload) {
        if (!payload.hasOwnProperty('property') || !payload.hasOwnProperty('value')) {
            throw new Error("Invalid mutation! Payload need to have following keys 'property', 'value'");
        }
        state[payload.property] = payload.value;
    },
    /**
     * Generic mutation for push an item to an array
     *
     * commit('addValue', {
     *   property: <propertyNameHere>,
     *   value: <valueGoesHere>
     * });
     *
     * @param {String} state
     * @param payload
     */
    addValue(state, payload) {
        if (!payload.hasOwnProperty('property') || !payload.hasOwnProperty('value')) {
            throw new Error("Invalid mutation! Payload need to have following keys 'property', 'value'");
        }
        state[payload.property].push(payload.value);
    },
    ADD_TO_CLIPBOARD(state, { clipboardSection, clipboard }) {
        state.products[clipboardSection] = clipboard;
    },
    REMOVE_FROM_CLIPBOARD(state, { clipboardSection, clipboard }) {
        state.products[clipboardSection] = clipboard;
    },
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
