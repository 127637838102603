<template>
    <fade-in-out>
        <div v-if="open" class="overlay-bg" />
    </fade-in-out>
</template>

<script>
import FadeInOut from '@/components/transitions/FadeInOut';
import { eventBus } from '@/eventBus';

export default {
    name: 'ModalBackground',
    components: { FadeInOut },
    data() {
        return {
            open: false,
        };
    },
    mounted() {
        eventBus.$on('modal-background', (value) => {
            this.open = value;
        });
    },
    beforeDestroy() {
        eventBus.$off('modal-background');
    },
};
</script>
