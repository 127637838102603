<template>
    <a @click="pushToProduct" :class="productFieldObject.moduleId" class="recommendation-product-field">
        <div class="recommendation-product-field-content">
            <app-icon class="mr-1" :icon="productFieldObject.moduleId" size-px="48px" />
            <div>
                <h2>
                    {{ productFieldObject.headline }}
                </h2>
                <p>
                    {{ productFieldObject.subline }}
                </p>
            </div>
            <app-icon class="goto__icon" icon="goto" size-px="24px" />
        </div>
    </a>
</template>

<script>
import AppIcon from '@/components/AppIcon';
import { mapGetters } from 'vuex';
export default {
    name: 'RecommendationsProductField',
    components: { AppIcon },
    props: {
        productField: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            productFieldObject: null,
        };
    },
    computed: {
        ...mapGetters({
            getProductField: 'getProductField',
        }),
    },
    created() {
        this.productFieldObject = this.getProductField(this.productField);
    },
    methods: {
        pushToProduct() {
            this.$matomo &&
                this.$matomo.trackEvent('Click', 'Bankempfehlung-Produktfeldempfehlung', this.productFieldObject.id);
            this.$store.commit('UPDATE_CURRENTLY_OPEN_PRODUCTLIST', this.productField);
            this.$router.push({
                name: 'module',
                params: {
                    module: this.productFieldObject.moduleId,
                },
            });
        },
    },
};
</script>
