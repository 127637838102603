<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie hoch ist Ihr derzeitiges monatliches Nettoeinkommen?
            </h3>
        </div>
        <div class="question__content">
            <CustomRangeSlider v-model="inputValue" :values="incomeValues" label="€" />

            <InfoBox class="pdr-3">
                <b>Unser Tipp:</b> Wir empfehlen Ihnen einen Liquiditätspuffer von
                <b class="color-cta">{{ liquidityBuffer }}</b>
                <span
                    class="info-btn"
                    v-tooltip.top.hover.ios="
                        'Ihr Liquiditätspuffer sollte Ihr dreifaches monatliches Nettoeinkommen betragen, mindestens jedoch 5.000 €!'
                    "
                >
                    <svg class="icon" width="20" height="20">
                        <use xlink:href="#icon-info" />
                    </svg>
                </span>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import Helpers from '@/helpers';
import CustomRangeSlider from '@/components/inputs/CustomRangeSlider';

export default {
    components: { CustomRangeSlider },
    mixins: [questionPropertiesMixin],
    data() {
        return {
            incomeValues: [
                0,
                // 0 - 5.000 (20 á 250)
                250,
                500,
                750,
                1000,
                1250,
                1500,
                1750,
                2000,
                2250,
                2500,
                2750,
                3000,
                3250,
                3500,
                3750,
                4000,
                4250,
                4500,
                4750,
                5000,
                // 5.000 - 15.000 (20 á 500)
                5500,
                6000,
                6500,
                7000,
                7500,
                8000,
                8500,
                9000,
                9500,
                10000,
                10500,
                11000,
                11500,
                12000,
                12500,
                13000,
                13500,
                14000,
                14500,
                15000,
            ],
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('income');
            },
            set(value) {
                this.updateQuestionState('income', value);
            },
        },
        liquidityBuffer() {
            let buffer = 3 * Math.round(this.inputValue / 25) * 25;
            if (buffer <= 5000) {
                buffer = 5000;
            }
            if (buffer >= 25000) {
                buffer = 25000;
            }
            this.updateQuestionState('liquidity_buffer', buffer);
            return Helpers.formatNumberAsEuro(buffer);
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
};
</script>
