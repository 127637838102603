<template>
    <div class="radio-buttons">
        <div class="radio" v-for="(option, index) in options" :key="index">
            <input
                :id="`option-${_uid}-${index}`"
                type="radio"
                :name="`radiogroup-${_uid}`"
                :value="index"
                v-model="inputValue"
                @click="toggleSelection(index)"
            /><label :for="`option-${_uid}-${index}`"
                ><span>{{ option.label }}</span></label
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        toggleSelection(v) {
            if (v == this.inputValue) {
                this.inputValue = '';
            }
        },
    },
};
</script>
