<template>
    <button class="module__sound" @click="playAudio()" :class="{ 'is-active': isPlaying }">
        <app-icon custom-class="icon" icon="sound" size-px="24px" />
    </button>
</template>

<script>
import { eventBus } from '@/eventBus';
import { playE2 } from '@/audio';
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';

export default {
    name: 'ModuleSoundButton',
    components: { AppIcon },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isPlaying: false,
        };
    },
    mounted() {
        eventBus.$on('global-audio-stop', () => {
            this.isPlaying = false;
        });
    },
    computed: {
        ...mapGetters(['getPersonalData']),
    },
    methods: {
        playAudio() {
            eventBus.$emit('global-audio-stop');
            this.isPlaying = true;
            const isRetired = this.getPersonalData.isRetired && this.module.id === 'vorsorge';
            playE2(this.module, isRetired).then(() => {
                this.isPlaying = false;
            });
        },
    },
    beforeDestroy() {
        eventBus.$off('global-audio-stop');
    },
};
</script>
