export default {
    liquiditaet: {
        Liquiditaet_Premium: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 25,
                },
                Question2a: {
                    key: 'value',
                    value: 'local',
                },
            },
        ],
        Liquiditaet_Privat: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 25,
                },
                Question2a: {
                    key: 'value',
                    value: 'online',
                },
                Question2b: {
                    key: 'value',
                    value: 'more_than_30',
                },
            },
        ],
        Liquiditaet_Direkt: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 25,
                },
                Question2a: {
                    key: 'value',
                    value: 'online',
                },
                Question2b: {
                    key: 'value',
                    value: 'less_than_30',
                },
            },
        ],
        /*
    Liquiditaet_Girokonto: [
      never??????????????
    ],
    */
        Liquiditaet_Jugendkonto: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age < 25,
                },
            },
        ],
        /*
    Liquiditaet_Geschaeftskonto: [
      never
    ],
    */
        /*
    Liquiditaet_Mitarbeiterkonto: [
      never
    ],
     */
        Liquiditaet_ClassicCard: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question3: {
                    key: 'value',
                    value: 'no',
                },
                Question5: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        Liquiditaet_GoldCard: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question3: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        Liquiditaet_Ratenkredit: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question6: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        /*
    Liquiditaet_PlatinumCard: [
      never
    ],
     */
        /*
    Liquiditaet_DirectCard: [
      never
    ],
     */
        Liquiditaet_BasicCard: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age < 18,
                },
                Question5: {
                    key: 'value',
                    value: 'yes',
                },
            },
            {
                Question1: {
                    key: 'age',
                    value: (age) => age < 18,
                },
                Question3: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        /*
    Liquiditaet_BusinessCard: [
      never???????????
    ],
     */
        Liquiditaet_mobile_App: [
            {
                Question7: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
    },
    vorsorge: {
        Vorsorge_Riester_Ruerup: [
            {
                Question2: {
                    key: 'job',
                    value: (job) => [1, 2, 3, 4, 6].includes(parseInt(job)),
                },
            },
        ],
        Vorsorge_Betriebliche_Altersvorsorge: [
            {
                Question2: {
                    key: 'job',
                    value: (job) => [1, 3].includes(parseInt(job)),
                },
            },
        ],
        /*
    Vorsorge_Ausreichend_Vermoegen: [
      // cant be recommended
    ],
     */
        // ---
        Vorsorge_Renten_Kapital: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 16 && age <= 55,
                },
                Question3: {
                    key: 'value',
                    value: 'yes',
                },
            },
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 56,
                },
                Question4: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        Vorsorge_Berufsunfaehigkeit: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 16 && age <= 45,
                },
                Question2: {
                    key: 'job',
                    value: (job) => [1, 2, 3, 4, 7].includes(parseInt(job)),
                },
            },
        ],
    },
    absicherung: {
        Absicherung_Haftpflicht: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        Absicherung_KFZ: [
            {
                Question4: {
                    key: 'car',
                    value: 'yes',
                },
            },
        ],
        Absicherung_Rechtsschutz: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        Absicherung_Risikoleben: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18 && age <= 50,
                },
                Question2: {
                    key: 'family_status',
                    value: 'couple',
                },
            },
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18 && age <= 50,
                },
                Question2: {
                    key: 'family_status',
                    value: 'single',
                },
                Question3: {
                    key: 'children',
                    value: 'yes',
                },
            },
        ],
        Absicherung_Unfall: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        // ---
        Absicherung_Restschuld: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question5: {
                    key: 'value',
                    value: 'yes',
                },
            },
        ],
        Absicherung_Krankenzusatz: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        /*
    Absicherung_Krankenvoll: [
      // never recommended
    ]
    */
    },
    vermoegen: {
        Vermoegen_Finanzpuffer: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        // ---
        Vermoegen_Depot: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question2: {
                    key: 'liquidity',
                    value: (liquidity, answers) => {
                        return (
                            answers.Question2.liquidity > 3 * answers.Question3.income &&
                            answers.Question2.liquidity >= 5000
                        );
                    },
                },
                Question5: {
                    key: 'risk_profile',
                    value: (risk) => risk > 0,
                },
                Question6: {
                    key: 'consulting',
                    value: (consulting) => consulting === 'personal' || consulting === 'none',
                },
            },
        ],
        Vermoegen_digitales_Depot: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question2: {
                    key: 'liquidity',
                    value: (liquidity, answers) => {
                        return (
                            answers.Question2.liquidity > 3 * answers.Question3.income &&
                            answers.Question2.liquidity >= 5000
                        );
                    },
                },
                Question5: {
                    key: 'risk_profile',
                    value: (risk) => risk > 0,
                },
                Question6: {
                    key: 'consulting',
                    value: (consulting) => consulting === 'digital',
                },
            },
        ],
        Vermoegen_Fondssparplan: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question4: {
                    key: 'reserve',
                    value: (reserve) => reserve >= 25,
                },
                Question5: {
                    key: 'risk_profile',
                    value: (risk) => risk > 0,
                },
                Question6: {
                    key: 'consulting',
                    value: (consulting) => consulting === 'personal' || consulting === 'none',
                },
            },
        ],
        Vermoegen_digitaler_Fondssparplan: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question4: {
                    key: 'reserve',
                    value: (reserve) => reserve >= 25,
                },
                Question5: {
                    key: 'risk_profile',
                    value: (risk) => risk > 0,
                },
                Question6: {
                    key: 'consulting',
                    value: (consulting) => consulting === 'digital',
                },
            },
        ],
    },
    immobilie: {
        Immobilie_Bausparvertrag: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age < 18,
                },
                Question4a: {
                    key: 'property',
                    value: 'yes',
                },
            },
            {
                Question2a: {
                    key: 'property',
                    value: 'yes',
                },
                Question3a: {
                    key: 'renovation',
                    value: 'yes',
                },
                Question3b: {
                    key: 'renovation_timing',
                    value: 'not_next_year',
                },
            },
            {
                Question2a: {
                    key: 'property',
                    value: 'no',
                },
                Question4a: {
                    key: 'property',
                    value: 'yes',
                },
                Question4b: {
                    key: 'property_timing',
                    value: 'not_next_year',
                },
            },
        ],
        Immobilie_Baufinanzierung: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question2a: {
                    key: 'property',
                    value: 'yes',
                },
                Question3a: {
                    key: 'renovation',
                    value: 'yes',
                },
                Question3b: {
                    key: 'renovation_timing',
                    value: 'next_year',
                },
            },
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question2a: {
                    key: 'property',
                    value: 'no',
                },
                Question4a: {
                    key: 'property',
                    value: 'yes',
                },
                Question4b: {
                    key: 'property_timing',
                    value: 'next_year',
                },
            },
        ],
        /*
    Immobilie_Abgezahlte_Immobilie: [
      // cant be recommended
    ]
    */
        Immobilie_Hausrat: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
            },
        ],
        Immobilie_Wohngebaeude: [
            {
                Question1: {
                    key: 'age',
                    value: (age) => age >= 18,
                },
                Question2a: {
                    key: 'property',
                    value: 'yes',
                },
                Question2b: {
                    key: 'value',
                    value: 'house',
                },
            },
        ],
    },
};
