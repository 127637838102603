<template>
    <div class="module-wrap" @click="trackClick" :class="{ 'is-promoted': module.id === promotedModule }">
        <router-link class="module-teaser" :class="module.id" :to="{ name: 'module', params: { module: module.id } }">
            <span v-if="module.id === promotedModule" class="recommendation-label">
                {{ $t('modules.recommendation-label') }}
            </span>
            <div class="columns is-vcentered is-multiline">
                <div class="column is-5-desktop is-4-tablet is-12-mobile">
                    <app-icon class="module-teaser__icon" :icon="module.icon" size-px="84px" />
                </div>
                <div class="column">
                    <div class="text-centered">
                        <h2 class="module-teaser__name">
                            {{ $t(`global.module.${module.id}`) }}
                        </h2>
                        <progress-bars
                            class="module-teaser__bars"
                            :filled="filledProductFieldCount"
                            :dashed="dashedProductFieldCount"
                            :retired="retiredProductFieldCount"
                            :max="productFieldCount"
                        />
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { modulePropertiesMixin } from '@/mixins/moduleProperties';
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';
import ProgressBars from '@/components/ProgressBars';

export default {
    components: { ProgressBars, AppIcon },
    mixins: [modulePropertiesMixin],
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            promotedModule: 'recommendation/getPromotedModule',
        }),
    },
    methods: {
        trackClick() {
            if (this.module.id === this.promotedModule) {
                this.$matomo && this.$matomo.trackEvent('Module', 'click-recommended', this.module.id);
            } else {
                this.$matomo && this.$matomo.trackEvent('Module', 'click', this.module.id);
            }
        },
    },
};
</script>
