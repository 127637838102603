<template>
    <div
        id="app"
        class="pagewrap"
        :class="[
            {
                'is-scrollable': isScrollable && !isScrolledToEnd,
                'is-scrolled': isScrolled,
                'is-scrolled-to-end': isScrolledToEnd,
                'show-scrolled-module-state': isScrolledModuleShown,
                'is-prefilled': isPrefilled,
            },
            $route.meta.appClass,
        ]"
        v-resize:debounce.50="updateScrollableState"
        ref="appcontainer"
    >
        <HeaderStandalone
            v-if="isStandalone"
            :showScrolledModuleState="isScrolledModuleShown"
            :isScrolled="isScrolled"
        />
        <Header v-else :showScrolledModuleState="isScrolledModuleShown" :isScrolled="isScrolled" />
        <transition name="fadepage" mode="out-in">
            <Splashscreen v-if="$store.state.splashscreen" />
            <router-view v-else />
        </transition>
        <Footer />
        <OverlayBG :is-active="getViewState.overlayBG" />
        <ContactOverlay type="contact" :is-active="getViewState.contactOverlay" />
        <ContactOverlay type="clipboard" :is-active="getViewState.clipboardOverlay" />
        <QuestionnaireResultOverlay
            type="questionnaire-results"
            :is-active="getViewState.questionnaireResultsOverlay"
        />
        <Overlay
            :headline="$t('overlays.form-success.headline')"
            :is-active="this.getViewState.formSuccessOverlay"
            view-name="formSuccessOverlay"
        >
            <p v-html="$t('overlays.form-success.message')"></p>
        </Overlay>
        <Overlay
            :headline="$t('overlays.form-error.headline')"
            :is-active="this.getViewState.formErrorOverlay"
            view-name="formErrorOverlay"
        >
            <div class="flex">
                <app-icon icon="alert" size-px="64px" class="mr-1" />
                <p v-html="$t('overlays.form-error.message')"></p>
            </div>
        </Overlay>
        <popup-bookmark />
        <modal-background />
        <cookie-settings-modal />
        <portal-target name="root" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/navigation/Header.vue';
import Footer from '@/components/navigation/Footer.vue';
import OverlayBG from '@/components/OverlayBG.vue';
import QuestionnaireResultOverlay from '@/components/QuestionnaireResultOverlay.vue';
import Overlay from '@/components/Overlay';
import ContactOverlay from '@/components/ContactOverlay.vue';
import Splashscreen from '@/views/Splashscreen.vue';
import resize from 'vue-resize-directive';
import HeaderStandalone from '@/components/navigation/HeaderStandalone';
import PopupBookmark from '@/components/PopupBookmark';
import AppIcon from '@/components/AppIcon';
import ModalBackground from '@/components/modal/ModalBackground';
import CookieSettingsModal from '@/components/navigation/CookieSettingsModal';

export default {
    name: 'app',
    directives: {
        resize,
    },
    components: {
        CookieSettingsModal,
        ModalBackground,
        AppIcon,
        HeaderStandalone,
        PopupBookmark,
        Header,
        Footer,
        OverlayBG,
        Overlay,
        QuestionnaireResultOverlay,
        ContactOverlay,
        Splashscreen,
    },
    data() {
        return {
            isScrollable: false,
            isScrolledToEnd: false,
            isScrolled: false,
            isScrolledModuleShown: false,
        };
    },
    computed: {
        ...mapGetters(['getViewState', 'isPrefilled', 'isStandalone']),
    },
    methods: {
        isAppScrollable() {
            return document.documentElement.clientHeight < this.$refs.appcontainer.offsetHeight;
        },
        isAppScrolled() {
            let y = document.documentElement.scrollTop;
            const offset = document.getElementsByClassName('site-header__inner')[0].offsetHeight;
            return y >= offset;
        },
        showScrolledModuleHeader() {
            let y = document.documentElement.scrollTop;
            const header = document.getElementsByClassName('site-header__inner');
            const moduleHeader = document.getElementsByClassName('module__header');
            if (moduleHeader.length > 0) {
                const offset = moduleHeader[0].offsetHeight - header[0].offsetHeight;
                return y >= offset;
            }
        },
        isAppScrolledToEnd() {
            let elementScrollHeight = document.documentElement.offsetHeight;
            let windowHeight = window.innerHeight;
            let y = document.documentElement.scrollTop;
            let offset = 10;
            return y + offset + windowHeight >= elementScrollHeight;
        },
        updateScrollableState() {
            this.isScrollable = this.isAppScrollable();
            this.isScrolledToEnd = this.isAppScrolledToEnd();
            this.isScrolled = this.isAppScrolled();
            setTimeout(() => {
                this.isScrolledModuleShown = this.showScrolledModuleHeader();
            }, 50);
        },
    },
    mounted() {
        this.updateScrollableState();
    },
    created() {
        window.addEventListener('scroll', this.updateScrollableState);
        window.addEventListener('resize', this.updateScrollableState);
    },
    beforeDestroy() {
        window.removeEventListener('scroll');
        window.removeEventListener('resize');
    },
};
</script>
