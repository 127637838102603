<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Inwieweit sind Sie bereit, für eine höhere Rendite ein gewisses Risiko einzugehen – zumindest bei einem
                Teil Ihres Liquiditätsüberschusses?
            </h3>
        </div>
        <div class="question__content">
            <div class="risk-calculator">
                <RiskSlider v-model="inputValue" />

                <div class="risk-graph">
                    <div class="risk-graph__chart">
                        <div class="risk-graph__bar">
                            <div
                                class="risk-graph__status color-negative"
                                v-bind:style="{ height: riskChartHeight + '%' }"
                            ></div>
                        </div>
                        <div class="risk-graph__bar">
                            <div
                                class="risk-graph__status bg-green"
                                v-bind:style="{ height: returnChartHeight + '%' }"
                            ></div>
                        </div>
                    </div>
                    <div class="risk-graph__legend">
                        <div class="risk-graph__label color-negative">
                            <span>Risiko</span>
                        </div>
                        <div class="risk-graph__label bg-green">
                            <span>Rendite</span>
                        </div>
                    </div>
                </div>

                <InfoBox class="risk-profile">
                    <p>
                        <span class="risk-profile__label">Ihr Typ: </span>
                        <span class="risk-profile__value">{{ riskProfile.type }}</span>
                    </p>
                    <p>
                        <span class="risk-profile__label">Ihr Anlageziel: </span>
                        <span class="risk-profile__value">{{ riskProfile.goal }}</span>
                    </p>
                </InfoBox>
            </div>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import riskLevels from '@/data/riskLevels';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            riskLevels: riskLevels,
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('risk_profile');
            },
            set(value) {
                this.updateQuestionState('risk_profile', value);

                this.$store.commit('UPDATE_PERSONAL_DATA', {
                    data: 'riskProfile',
                    payload: value,
                });
            },
        },
        riskProfile() {
            return this.riskLevels[this.inputValue];
        },
        riskChartHeight() {
            let maxLevel = Object.keys(this.riskLevels).length;
            let currentValue = this.inputValue;
            if (currentValue === 0) {
                return 0;
            }
            // change here the rate of scaling!
            let height = (currentValue / maxLevel) * 100 - 1.75 * Object.keys(this.riskLevels).length;

            return height.toFixed(2);
        },
        returnChartHeight() {
            let maxLevel = Object.keys(this.riskLevels).length;
            let currentValue = this.inputValue + 1;
            let height = (currentValue / maxLevel) * 100;

            if (currentValue === 1) {
                height = 0;
            }

            return height;
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
};
</script>
