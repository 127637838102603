<template>
    <footer class="site-footer">
        <div class="site-footer__inner wrapper wrapper--narrow" :class="{ 'is-centered': !isPrefilled }">
            <div class="site-footer__links">
                <a v-for="(item, index) in menuItems" :key="index" :href="item.link" :target="item.target">{{
                    item.text
                }}</a>
                <span v-if="getSplashscreen" class="has-pointer">{{ $t('overlays.cookies.menu-label') }}</span>
                <a v-else class="has-pointer" disabled @click="openCookieSettings">{{
                    $t('overlays.cookies.menu-label')
                }}</a>
            </div>
            <div class="pull-right is-flex">
                <div v-if="consultantMode" class="consultant-mode">
                    <app-icon icon="consultant" size-px="10px" />
                    {{ $t('global.consultant-mode') }}
                </div>
                <div v-if="isPrefilled" class="update-date">
                    {{ $t('global.update-date', { date: updateDate }) }}
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';
import { eventBus } from '@/eventBus';

export default {
    components: { AppIcon },
    data() {
        return {
            menuItems: this.$t('global.site-menu'),
        };
    },
    methods: {
        openCookieSettings() {
            eventBus.$emit('cookie-settings-modal', true);
        },
    },
    computed: {
        ...mapGetters(['getUpdateDate', 'isPrefilled', 'consultantMode', 'getSplashscreen']),
        updateDate() {
            let date = this.getUpdateDate;
            return date !== null ? date : 'unbekannt';
        },
    },
};
</script>
