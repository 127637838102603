export default {
    liquiditaet: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
    absicherung: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
    vermoegen: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
    vorsorge: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
    immobilie: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
    extra: {
        activeQuestion: 'Question1',
        nextQuestion: '',
        highestVisitedQuestion: 'Question1',
        isCompleted: false,
    },
};
