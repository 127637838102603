<template>
    <button
        :disabled="audioIsDisabled"
        class="bankstatus__button bankstatus__button--sound"
        @click="playSound()"
        :class="{ 'is-active': isPlaying, 'pulse': showAudioTooltip }"
        v-tooltip.top.notrigger="{
            content: $t('bankstatus.tooltip'),
            visible: showAudioTooltip,
            class: 'fade-in',
        }"
    >
        <svg class="icon" width="24" height="24">
            <use xlink:href="#icon-sound" />
        </svg>
    </button>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '@/eventBus';
import { playE1 } from '@/audio';

export default {
    name: 'BankStatusSoundButton',
    data() {
        return {
            isPlaying: false,
        };
    },
    mounted() {
        eventBus.$on('global-audio-stop', () => {
            this.isPlaying = false;
        });
    },
    methods: {
        playSound() {
            eventBus.$emit('global-audio-stop');
            this.isPlaying = true;
            let activeRecommendation = false;

            if (this.getPromotedProductFields.length > 0) {
                activeRecommendation = this.getPromotedProductFields[0];
            }

            playE1(this.getAppModules, this.statusCount, activeRecommendation).then(() => {
                this.isPlaying = false;
            });
        },
    },
    computed: {
        ...mapGetters({
            getAnimationState: 'getAnimationState',
            getAppModules: 'getAppModules',
            bankstatusCount: 'bankstatusCount',
            getPromotedProductFields: 'recommendation/getPromotedProductFields',
        }),
        statusCount() {
            return this.bankstatusCount.filled.length;
        },
        showAudioTooltip() {
            return this.getAnimationState('audioTooltip');
        },
        audioIsDisabled() {
            return this.getAnimationState('showIntroSimulation');
        },
    },
    beforeDestroy() {
        eventBus.$off('global-audio-stop');
    },
};
</script>
