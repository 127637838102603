<template>
    <div class="modules">
        <ModuleTeaser
            class="column is-4-desktop is-4-tablet is-6-mobile"
            :module="module"
            v-for="(module, index) in getAppModules"
            :key="index"
            :class="{ 'animate-shake': showShakeAnimation && module.id === promotedModule }"
            :id="`module-${module.id}`"
        />
    </div>
</template>

<script>
import ModuleTeaser from '@/components/ModuleTeaser.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        ModuleTeaser,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            getAppModules: 'getAppModules',
            isPrefilled: 'isPrefilled',
            getAnimationState: 'getAnimationState',
            getFilledProductFieldCount: 'getFilledProductFieldCount',
            promotedModule: 'recommendation/getPromotedModule',
        }),
        showShakeAnimation() {
            return this.getAnimationState('shakeModule');
        },
    },
};
</script>
