<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Besitzen Sie eine eigene Immobilie?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="optionsProperty" v-model="inputValueProperty" />
        </div>
        <div v-if="inputValueProperty === 'yes'">
            <div class="question__header">
                <div class="question__headline">
                    Handelt es sich um ein Haus oder eine Wohnung?
                </div>
            </div>
            <div class="question__content">
                <RadioGroup :options="optionsHouseType" v-model="inputValueHouseType" />
            </div>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            optionsProperty: {
                yes: {
                    icon: '',
                    label: 'Ja',
                },
                no: {
                    icon: '',
                    label: 'Nein',
                },
            },
            optionsHouseType: {
                house: {
                    icon: '',
                    label: 'Haus',
                },
                flat: {
                    icon: '',
                    label: 'Wohnung',
                },
            },
        };
    },
    computed: {
        inputValueProperty: {
            get() {
                return this.getPrefillableQuestions.property;
            },
            set(value) {
                this.isAnswered = value === 'no' || (value === 'yes' && this.inputValueHouseType !== '');
                this.updateQuestionState('property', value);
            },
        },
        inputValueHouseType: {
            get() {
                return this.getQuestionState('value', 'Question2b');
            },
            set(value) {
                this.isAnswered = this.inputValueProperty === 'yes' && value !== '';
                this.updateQuestionState('value', value, 'Question2b');
            },
        },
    },
    mounted() {
        if (this.inputValueProperty !== '') {
            this.updateQuestionStateOnly('property', this.inputValueProperty);
        }
    },
};
</script>
