<template>
    <portal v-if="open" to="root">
        <fade-in-out>
            <section v-if="delayedOpen" class="overlay" :class="{ 'overlay--wide': wide }">
                <div class="overlay__inner">
                    <header class="overlay__header">
                        <h3 class="overlay__headline">{{ title }}</h3>
                        <button class="icon-btn close-btn" @click="closeModal()">
                            <app-icon custom-class="icon" size-px="16px" icon="x" />
                        </button>
                    </header>
                    <div class="overlay__content">
                        <slot />
                    </div>
                </div>
            </section>
        </fade-in-out>
    </portal>
</template>

<script>
import FadeInOut from '@/components/transitions/FadeInOut';
import AppIcon from '@/components/AppIcon';
import { eventBus } from '@/eventBus';

export default {
    name: 'ModalWrapper',
    components: { AppIcon, FadeInOut },
    props: {
        /**
         * You have to use :open.sync="yourState" !
         * It does not work without .sync
         */
        open: {
            type: Boolean,
            required: true,
        },
        /**
         * Title of the modal
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Makes the modal more wide
         */
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Matomo event name (for open and close)
         */
        matomoName: {
            type: [Boolean, String],
            required: false,
            default: false,
            validator: (value) => {
                return value === false || typeof value === 'string';
            },
        },
    },
    data() {
        return {
            delayedOpen: false,
        };
    },
    /**
     * Add listener for escape
     */
    mounted() {
        document.addEventListener('keyup', this.escape);
    },
    methods: {
        /**
         * Info: To work properly with the .sync prop, the event name need to start with "update:"
         * so both (child and parent) states are in sync!
         */
        closeModal() {
            this.$emit('update:open', false);
        },
        /**
         * Check keycode
         */
        escape(event) {
            if (event.keyCode === 27) {
                this.closeModal();
            }
        },
    },
    watch: {
        /**
         * Disabling the scroll in body, if modal is open!
         * @param {Boolean} currentState
         */
        open(currentState) {
            if (currentState) {
                if (this.matomoName !== false) {
                    this.$matomo && this.$matomo.trackEvent('Modals', 'open', this.matomoName);
                }
                eventBus.$emit('modal-background', true);
                document.documentElement.style.overflow = 'hidden';
                document.body.scroll = 'no';

                setTimeout(() => {
                    this.delayedOpen = true;
                }, 100);
            } else {
                if (this.matomoName !== false) {
                    this.$matomo && this.$matomo.trackEvent('Modals', 'close', this.matomoName);
                }
                eventBus.$emit('modal-background', false);
                document.documentElement.style.overflow = 'scroll';
                document.body.scroll = 'yes';
                this.delayedOpen = false;
            }
        },
    },
    /**
     * Destroy eventListener for escape
     */
    beforeDestroy() {
        eventBus.$emit('modal-background', false);
        document.removeEventListener('keyup', this.escape);
        document.documentElement.style.overflow = 'scroll';
        document.body.scroll = 'yes';
    },
};
</script>
