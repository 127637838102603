<template>
    <div class="progress-bars" :class="{ 'color-invert': colorInvert }">
        <div class="is-relative">
            <div class="bars bar-background"><div class="infill"></div></div>
            <div :style="{ width: filledWidth + '%' }" class="bars bar-filled"></div>
            <div :style="{ width: dashedWidth + '%' }" class="bars bar-dashed"></div>
            <div :style="{ width: retiredWidth + '%' }" class="bars bar-retired"></div>
            <div class="separator">
                <div
                    v-for="(fakeStep, index) in max + 1"
                    :key="`step-${index}`"
                    :style="{ left: index * stepWidth + '%' }"
                    class="fake-step"
                />
            </div>
            <div class="border-wrapper">
                <div
                    v-for="(progress, index) in progressArray"
                    :key="`progress-${index}`"
                    class="bar-border"
                    :class="progress"
                >
                    <div class="infill"></div>
                </div>
            </div>
        </div>
        <div class="progress-bars__text">{{ this.filled + this.dashed }}/{{ this.max }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBars',
    props: {
        colorInvert: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Number,
            default: 0,
        },
        dashed: {
            type: Number,
            default: 0,
        },
        retired: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        stepWidth() {
            return (1 / this.max) * 100;
        },
        filledWidth() {
            const width = this.filled * this.stepWidth;
            return width > 100 ? 100 : width;
        },
        dashedWidth() {
            const width = this.filledWidth + this.dashed * this.stepWidth;
            return width > 100 ? 100 : width;
        },
        retiredWidth() {
            const width = this.dashedWidth + this.retired * this.stepWidth;
            return width > 100 ? 100 : width;
        },
        progressArray() {
            let progressArray = [];
            for (let i = 0; i < this.filled; i++) {
                if (progressArray.length < this.max) {
                    progressArray.push('filled');
                }
            }
            for (let i = 0; i < this.dashed; i++) {
                if (progressArray.length < this.max) {
                    progressArray.push('dashed');
                }
            }
            for (let i = 0; i < this.retired; i++) {
                if (progressArray.length < this.max) {
                    progressArray.push('retired');
                }
            }
            for (let i = 0; i < this.max; i++) {
                if (progressArray.length < this.max) {
                    progressArray.push('empty');
                }
            }

            return progressArray;
        },
    },
};
</script>
