<template>
    <transition name="component-fade">
        <button class="nav-arrow nav-arrow--left" @click="$emit('click', $event)">
            <svg class="icon" width="18px" height="18px">
                <use xlink:href="#icon-arrow-left" />
            </svg>
            <span class="nav-arrow__text">{{ backButtonText }}</span>
        </button>
    </transition>
</template>

<script>
export default {
    props: {
        moduleName: {
            type: String,
        },
        type: {
            type: String,
        },
    },
    computed: {
        backButtonText() {
            let btnText = this.$t('global.buttons.back-to-home');

            if (this.type === 'questionnaire') {
                btnText = this.$t('global.buttons.back-to-module', {
                    module: this.moduleName,
                });
            }

            return btnText;
        },
    },
};
</script>
