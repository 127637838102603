export const backButtonMixin = {
    computed: {
        fromRoute() {
            return this.$store.state.route.name;
        },
    },
    methods: {
        /**
         * Handle Back
         * @desc Extends default router back functionality
         * @param {string} fallback - The fallback path if there is no history to use with $router.back(). This is usually the case if the page was visited directly or from another site
         **/
        handleBack(fallback) {
            if (!this.fromRoute) {
                this.$router.push(fallback);
            } else if (this.fromRoute === 'clipboard' || this.fromRoute === 'module') {
                this.$router.push({ name: 'home' });
            } else {
                this.$router.back();
            }
        },
    },
};
