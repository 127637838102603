<template>
    <div class="clipboard-product" :class="productColorClass">
        <div class="clipboard-product__inner">
            <app-icon :icon="productIcon" size-px="48px" custom-class="clipboard-product__icon" />
            <div class="clipboard-product__content">
                <h5 class="clipboard-product__headline">
                    {{ product.headline }}
                </h5>
                <ul v-if="featureCount > 1">
                    <li v-for="(feature, index) in product.description" :key="index">
                        {{ feature }}
                    </li>
                </ul>
                <p v-else>{{ product.description[0] }}</p>
            </div>
        </div>

        <div v-if="section === 'recommendations'" class="clipboard-product__buttons">
            <button class="icon-btn" @click="editProduct()" v-tooltip.left="$t('overlays.recommendations.goto')">
                <app-icon size-px="24px" icon="goto" />
            </button>
        </div>
        <div v-else class="clipboard-product__buttons">
            <button class="icon-btn" @click="editProduct()" v-tooltip.left="$t('clipboard.tooltip.edit')">
                <app-icon size-px="24px" icon="edit" />
            </button>
            <a
                v-if="hasExternalLink"
                class="icon-btn"
                @click="trackOnlineContract(product.id)"
                :href="product.external_link"
                target="_blank"
                v-tooltip.left="$t('clipboard.tooltip.external')"
            >
                <app-icon size-px="24px" icon="external-link" />
            </a>
            <a
                class="icon-btn"
                @click="trackAppointment(product.id)"
                :href="$t('clipboard.appointment-link')"
                target="_blank"
                v-tooltip.left="$t('clipboard.tooltip.appointment')"
            >
                <app-icon size-px="24px" icon="calendar" />
            </a>
            <button
                class="icon-btn"
                @click="deleteProductFromClipboard()"
                v-tooltip.left="$t('clipboard.tooltip.delete')"
            >
                <app-icon size-px="24px" icon="trash" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';
import IconMapping from '@/data/productIconMapping.json';

export default {
    components: { AppIcon },
    props: {
        product: {
            type: Object,
            required: true,
        },
        section: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['getModule']),
        hasExternalLink() {
            return this.product.hasOwnProperty('external_link') && this.product.external_link != '';
        },
        productColorClass() {
            if (this.section === 'EXTERNAL') {
                return 'bg-gray';
            } else {
                return 'bg-' + this.product.color;
            }
        },
        productIcon() {
            return IconMapping[this.product.id];
        },
        featureCount() {
            let count = 0,
                key;
            for (key in this.product.description) {
                if (this.product.description.hasOwnProperty(key)) {
                    count++;
                }
            }
            return count;
        },
    },
    methods: {
        trackOnlineContract(productId) {
            this.$matomo && this.$matomo.trackEvent('Click', 'Online-Direktabschluss', productId);
        },
        trackAppointment(productId) {
            this.$matomo && this.$matomo.trackEvent('Click', 'Termin vereinbaren', productId);
        },
        deleteProductFromClipboard() {
            const self = this;
            const options = {
                animation: 'fade',
                okText: this.$t('clipboard.delete-popup.delete-btn'),
                cancelText: this.$t('clipboard.delete-popup.cancel-btn'),
            };

            // Show confirmation dialog
            this.$dialog
                .confirm(this.$t('clipboard.delete-popup.prompt'), options)
                .then(function() {
                    // Update product status options, which will update clipboard status automatically
                    self.$store.dispatch('updateProductStatus', {
                        productId: self.product.id,
                        option: self.section,
                        payload: false,
                    });
                })
                .catch(function() {
                    // Abort, do nothing :)
                });
        },
        editProduct() {
            this.$store.commit('UPDATE_CURRENTLY_OPEN_PRODUCTLIST', this.product.productFieldId);
            this.$router.push({
                name: 'module',
                params: {
                    module: this.product.moduleId,
                },
            });
        },
    },
};
</script>
