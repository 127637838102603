<template>
    <transition name="component-fade">
        <section v-if="isActive" class="overlay">
            <div class="overlay__inner">
                <header class="overlay__header">
                    <h3 class="overlay__headline">{{ headline }}</h3>
                    <button class="icon-btn close-btn" @click="closeOverlay()">
                        <svg class="icon" width="16px" height="16px">
                            <use xlink:href="#icon-x" />
                            >
                        </svg>
                    </button>
                </header>
                <div class="overlay__content">
                    <slot></slot>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
        },
        headline: {
            type: String,
            default: 'Headline',
        },
        viewName: {
            type: String,
            default: 'overlay',
        },
    },
    methods: {
        closeOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: this.viewName,
                payload: false,
            });
        },
        keyListeners: function(e) {
            // Listen to ESC key
            if (e.keyCode === 27) {
                this.closeOverlay();
            }
        },
    },
    watch: {
        isActive(newValue) {
            if (newValue === true) {
                document.addEventListener('keyup', this.keyListeners);
            } else {
                document.removeEventListener('keyup', this.keyListeners);
            }
        },
    },
};
</script>
