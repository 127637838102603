<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wertpapieranlagen lohnen sich!
            </h3>
        </div>
        <div class="question__content">
            <InfoBox v-if="cashReserveIsPositive" class="pdr-3">
                Wenn Sie Ihren Liquiditätsüberschuss in Höhe von
                <b class="color-cta">{{ cashReserveGap }}</b> in Wertpapieren angelegt hätten, hätten Sie pro Jahr in
                den letzten 10 Jahren im Durschnitt ca.
                <b
                    ><span class="color-cta">{{ cashIncrement }}</span> mehr verdienen können</b
                >.
                <span
                    class="info-btn"
                    v-tooltip.top.hover.ios="
                        'Anlagen in Wertpapiere sind mit Kursrisiken verbunden und sollten immer an die individuelle Anlagestrategie angepasst werden.'
                    "
                >
                    <svg class="icon" width="20" height="20">
                        <use xlink:href="#icon-info" />
                    </svg>
                </span>
            </InfoBox>
            <InfoBox class="pdr-3" v-else>
                Schon bei einer Anlage von
                <b class="color-cta">{{ cashReserveGap }}</b> hätten Sie pro Jahr in den letzten 10 Jahren im
                Durchschnitt ca.
                <b
                    ><span class="color-cta">{{ cashIncrement }}</span> mehr verdienen können</b
                >.
                <span
                    class="info-btn"
                    v-tooltip.top.hover.ios="
                        'Anlagen in Wertpapiere sind mit Kursrisiken verbunden und sollten immer an die individuelle Anlagestrategie angepasst werden.'
                    "
                >
                    <svg class="icon" width="20" height="20">
                        <use xlink:href="#icon-info" />
                    </svg>
                </span>
            </InfoBox>
            <p class="small-text italic-text color-gray">
                Hierbei handelt es sich nicht um eine Anlageberatung, sondern lediglich um ein Rechenbeispiel auf Basis
                der vergangenen Jahre.
            </p>
            <button class="btn btn--small btn--outline btn--icon" @click="openCalculatorOverlay">
                Berechnen Sie selbst
                <svg class="icon" width="16px" height="16px">
                    <use xlink:href="#icon-arrow-right" />
                </svg>
            </button>
        </div>
        <Overlay :headline="`Wertpapieranlagen lohnen sich!`" :is-active="this.getViewState.overlay">
            <strong>Ihre Anlagesumme:</strong>
            <RangeSlider
                :minRange="5000"
                :maxRange="250000"
                label="€"
                :step="1000"
                v-model="inputValueCustomInvestmentAmount"
            />
            <strong>Ihre gewünschter Anlagezeitraum:</strong>
            <RangeSlider
                :minRange="1"
                :maxRange="30"
                label="Jahre"
                :step="1"
                v-model="inputValueCustomInvestmentDuration"
            />
            <strong>Ihre Tagesgeld-Rendite:</strong>
            <RangeSlider
                :minRange="0"
                :maxRange="5"
                label="%"
                :step="0.1"
                :precision="1"
                v-model="inputValueCustomReturnCallMoney"
            />
            <strong>Beispiel-Rendite bei Anlage in Wertpapieren:</strong>
            <RangeSlider
                :minRange="0"
                :maxRange="10"
                label="%"
                :step="0.1"
                :precision="1"
                v-model="inputValueCustomReturn"
            />
            <InfoBox>
                Bei einer Anlage in Wertpapieren hätten Sie pro Jahr in den letzten
                {{ inputValueCustomInvestmentDuration }} Jahren im Durschnitt ca.
                <b class="color-cta">{{ cashCustomIncrement }}</b> mehr verdienen können.
            </InfoBox>
            <p class="small-text mb-0 mt-1">
                Hinweis: In der Vergangenheit war eine Wertpapierrendite von durchschnittlich 4,0 % pro Jahr ein
                angemessener Richtwert.
            </p>
        </Overlay>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import Helpers from '@/helpers';
import investmentCalculation from '@/data/investmentCalculation';

export default {
    mixins: [questionPropertiesMixin],
    computed: {
        cashReserveGap() {
            return Helpers.formatNumberAsEuro(this.getCashReserveGap());
        },
        cashReserveIsPositive() {
            return this.getQuestionState('cash_reserve_gap_is_positive', 'QuestionCalculateCashReserve');
        },
        cashIncrement() {
            let increment = this.calcCashIncrement(
                investmentCalculation.assumedReturn,
                investmentCalculation.assumedReturnCallMoney,
                investmentCalculation.investmentDuration,
                this.getCashReserveGap()
            );
            return Helpers.formatNumberAsEuro(increment / 10);
        },
        cashCustomIncrement() {
            let increment = this.calcCashIncrement(
                this.getQuestionState('custom_return'),
                this.getQuestionState('custom_return_call_money'),
                this.getQuestionState('custom_investment_duration'),
                this.getQuestionState('custom_investment_amount')
            );
            return Helpers.formatNumberAsEuro(increment / this.inputValueCustomInvestmentDuration);
        },
        inputValueCustomInvestmentAmount: {
            get() {
                return this.getQuestionState('custom_investment_amount');
            },
            set(value) {
                this.updateQuestionState('custom_investment_amount', value);
            },
        },
        inputValueCustomInvestmentDuration: {
            get() {
                return this.getQuestionState('custom_investment_duration');
            },
            set(value) {
                this.updateQuestionState('custom_investment_duration', value);
            },
        },
        inputValueCustomReturnCallMoney: {
            get() {
                // get value as floating number with max 1 decimal (4.8)
                return Helpers.formatPercentAsNumber(this.getQuestionState('custom_return_call_money'), 1);
            },
            set(value) {
                // save value as floating number 0.048 with max 3 decimals
                this.updateQuestionState('custom_return_call_money', Helpers.formatNumberAsPercent(value, 3));
            },
        },
        inputValueCustomReturn: {
            get() {
                // get value as floating number with max 1 decimal (4.8)
                return Helpers.formatPercentAsNumber(this.getQuestionState('custom_return'), 1);
            },
            set(value) {
                // save value as floating number with max 3 decimals (0.048)
                this.updateQuestionState('custom_return', Helpers.formatNumberAsPercent(value, 3));
            },
        },
    },
    methods: {
        getCashReserveGap() {
            let total = 5000;
            if (this.cashReserveIsPositive) {
                total = this.getQuestionState('cash_reserve_gap', 'QuestionCalculateCashReserve');
            }
            return total;
        },
        calcCashIncrement(assumedReturn, assumedReturnCallMoney, investmentDuration, investmentAmount) {
            let total =
                investmentAmount * Math.pow(1 + assumedReturn, investmentDuration) -
                investmentAmount * Math.pow(1 + assumedReturnCallMoney, investmentDuration);
            return total;
        },
        openCalculatorOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'overlay',
                payload: true,
            });
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
    watch: {
        inputValueCustomReturnCallMoney(value) {
            if (value > this.inputValueCustomReturn) {
                this.inputValueCustomReturn = value;
            }
        },
        inputValueCustomReturn(value) {
            if (value <= this.inputValueCustomReturnCallMoney) {
                this.inputValueCustomReturnCallMoney = value;
            }
        },
    },
};
</script>
