var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-header",attrs:{"id":"site-header"}},[_c('div',{staticClass:"site-header__top"},[_c('div',{staticClass:"site-header__inner wrapper"},[_vm._m(0),_c('transition',{attrs:{"name":"fadepage","mode":"out-in"}},[(!_vm.$store.state.splashscreen)?_c('ul',{staticClass:"site-header__meta menu"},[_c('li',{staticClass:"menu__item"},[_c('button',{staticClass:"icon-btn icon-btn--primary",on:{"click":_vm.openContactForm}},[_c('svg',{staticClass:"icon",attrs:{"width":"28px","height":"24px"}},[_c('use',{attrs:{"xlink:href":"#icon-email"}})]),_c('span',{staticClass:"icon-btn__text"},[_vm._v(_vm._s(_vm.$t('global.buttons.contact')))])])]),_c('li',{staticClass:"menu__item menu__item--clipboard"},[_c('router-link',{staticClass:"icon-btn icon-btn--primary",attrs:{"to":{ name: 'clipboard' }}},[_c('div',{staticClass:"clipboard-btn",class:{ 'animate-bounce': _vm.showClipboardBounceAnimation },attrs:{"data-count":_vm.clipboardCount}},[_c('svg',{staticClass:"icon",attrs:{"width":"24px","height":"24px"}},[_c('use',{attrs:{"xlink:href":"#icon-clipboard"}})])]),_c('span',{staticClass:"icon-btn__text"},[_vm._v(_vm._s(_vm.$t('global.buttons.clipboard')))])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showClipboardTooltip)?_c('div',{staticClass:"tooltip tooltip--clipboard"},[_c('button',{staticClass:"icon-btn close-btn",on:{"click":_vm.closeClipboardTooltip}},[_c('svg',{staticClass:"icon",attrs:{"width":"12","height":"12"}},[_c('use',{attrs:{"xlink:href":"#icon-x"}}),_vm._v(" > ")])]),_c('div',{staticClass:"tooltip__inner"},[_c('p',{domProps:{"innerHTML":_vm._s(
                                            _vm.$t('clipboard.cta-popup.text', {
                                                clipboard: _vm.$t('clipboard.name'),
                                            })
                                        )}}),_c('router-link',{staticClass:"btn btn--cta btn--small btn--rounded",attrs:{"to":{ name: 'clipboard' }}},[_vm._v(_vm._s(_vm.$t('clipboard.cta-popup.cta-btn')))])],1)]):_vm._e(),(_vm.showClipboardReminderTooltip)?_c('div',{staticClass:"tooltip tooltip--clipboard-reminder"},[_c('div',{staticClass:"tooltip__inner"},[_c('p',{domProps:{"innerHTML":_vm._s(
                                            _vm.$t('clipboard.cta-popup.reminder-text', {
                                                clipboard: _vm.$t('clipboard.name'),
                                            })
                                        )}}),_c('router-link',{staticClass:"btn btn--cta btn--small btn--rounded",attrs:{"to":{ name: 'clipboard' }}},[_vm._v(_vm._s(_vm.$t('clipboard.cta-popup.cta-btn')))])],1)]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showClipboardTooltip)?_c('div',{staticClass:"tooltip-overlay",on:{"click":_vm.closeClipboardTooltip}}):_vm._e()])],1)]):_vm._e()])],1)]),_c('transition',{attrs:{"name":"fadepage","mode":"out-in"}},[(!_vm.$store.state.splashscreen)?_c('div',{staticClass:"site-header__bottom"},[_c('div',{staticClass:"site-header__inner wrapper"},[_c('div',{staticClass:"back-btn-wrap"},[_c('BackButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'clipboard'),expression:"$route.name === 'clipboard'"}],attrs:{"moduleName":_vm.module,"type":_vm.type},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showSimulationTooltip)?_c('div',{staticClass:"tooltip tooltip--simulation",on:{"click":_vm.closeSimulationTooltip}},[_c('div',{staticClass:"tooltip__inner"},[_c('svg',{staticClass:"icon",attrs:{"width":"24px","height":"24px"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('circle',{attrs:{"stroke":"#D8D8D8","stroke-width":"2","opacity":".9","cx":"12","cy":"12","r":"11"}}),_c('g',{attrs:{"transform":"translate(4 10)"}},[_c('rect',{attrs:{"id":"simulation-pill-3","x":"12","width":"4","height":"4","rx":"2"}}),_c('rect',{attrs:{"id":"simulation-pill-2","x":"6","width":"4","height":"4","rx":"2"}}),_c('rect',{attrs:{"id":"simulation-pill-1","x":"0","width":"4","height":"4","rx":"2"}})])])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('global.simulation.cta-popup'))+" ")])])]):_vm._e()]),_c('span',{staticClass:"app-title"},[_vm._v("Kontofinder")]),_c('div',{staticClass:"reset-btn-wrap"})],1)]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../../public/assets/img/logo.svg"),"alt":"Leipziger Volksbank","width":"140","height":"35"}})])}]

export { render, staticRenderFns }