<template>
    <div v-if="maxItems > 0">
        <button @click="openModal" :class="parentClass" class="product__img-modal">
            <app-icon icon="img-modal" size-px="20px" />
        </button>

        <modal-wrapper-inline
            :open.sync="open"
            :title="productData.description[0]"
            matomo-name="Bildstrecke"
            class="product-gallery"
        >
            <div class="product-gallery__inner">
                <div class="product-gallery__imgwrap">
                    <div class="nav-buttons">
                        <button @click="prev" class="nav-btn nav-btn--prev">
                            <app-icon icon="arrow-left-short" size-px="24px" />
                        </button>
                        <button @click="next" class="nav-btn nav-btn--next">
                            <app-icon icon="arrow-right-short" size-px="24px" />
                        </button>
                    </div>
                    <img
                        v-for="(item, index) in gallery"
                        :key="`gallery-${productId}-${index}`"
                        class="product-gallery__img"
                        v-show="index === currentVisible"
                        :src="item.src"
                        alt=""
                    />
                </div>

                <div class="status-bar">
                    <div class="status-bar__text">{{ currentVisible + 1 }} von {{ maxItems }}</div>
                    <div class="progress-indicator">
                        <span
                            v-for="index in maxItems"
                            :key="`indicator-${productId}-${index}`"
                            class="progress-indicator__bubble"
                            :class="{ 'is-filled': currentVisible + 1 === index }"
                        />
                    </div>
                </div>
                <div class="product-gallery__description" :data-text="longestText">
                    <p
                        v-for="(item, index) in gallery"
                        :key="`gallery-${productId}-${index}-text`"
                        v-show="index === currentVisible"
                    >
                        {{ gallery[index].text }}
                        <template v-if="markWithAd.includes(productId)">
                            <i>(Werbung)</i>
                        </template>
                    </p>
                </div>
            </div>
        </modal-wrapper-inline>
    </div>
</template>

<script>
import AppIcon from '@/components/AppIcon';
import ModalWrapperInline from '@/components/modal/ModalWrapperInline';
import { mapGetters } from 'vuex';

const PREFIX = '/assets/img/gallery/';

export default {
    name: 'ProductImageModal',
    components: { ModalWrapperInline, AppIcon },
    props: {
        productId: {
            type: String,
            required: true,
        },
        parentClass: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentVisible: 0,
            maxItems: 0,
            open: false,
            productData: undefined,
            gallery: [],
            longestText: '',
            markWithAd: [
                'Vermoegen_Depot',
                'Vermoegen_digitales_Depot',
                'Vermoegen_Fondssparplan',
                'Vermoegen_digitaler_Fondssparplan',
            ],
        };
    },
    created() {
        this.productData = this.getProductById(this.productId);
        if (
            this.productData['gallery'][`${this.getAgePrefix}_gallery_1_text`] &&
            this.productData['gallery'][`${this.getAgePrefix}_gallery_1_picture`]
        ) {
            this.gallery.push({
                text: this.productData['gallery'][`${this.getAgePrefix}_gallery_1_text`],
                src: PREFIX + this.productData['gallery'][`${this.getAgePrefix}_gallery_1_picture`],
            });
        }
        if (
            this.productData['gallery'][`${this.getAgePrefix}_gallery_2_text`] &&
            this.productData['gallery'][`${this.getAgePrefix}_gallery_2_picture`]
        ) {
            this.gallery.push({
                text: this.productData['gallery'][`${this.getAgePrefix}_gallery_2_text`],
                src: PREFIX + this.productData['gallery'][`${this.getAgePrefix}_gallery_2_picture`],
            });
        }
        if (
            this.productData['gallery'][`${this.getAgePrefix}_gallery_3_text`] &&
            this.productData['gallery'][`${this.getAgePrefix}_gallery_3_picture`]
        ) {
            this.gallery.push({
                text: this.productData['gallery'][`${this.getAgePrefix}_gallery_3_text`],
                src: PREFIX + this.productData['gallery'][`${this.getAgePrefix}_gallery_3_picture`],
            });
        }
        if (this.gallery.length > 0) {
            this.gallery.forEach((item) => {
                if (item.text.length > this.longestText.length) {
                    this.longestText = item.text;
                }
            });
        }
        this.maxItems = this.gallery.length;
    },
    methods: {
        openModal() {
            this.open = true;
            this.$matomo && this.$matomo.trackEvent('Bildstrecke', 'click', this.productId);
        },
        next() {
            this.$matomo && this.$matomo.trackEvent('Bildstrecke', 'click', this.productId);
            if (this.currentVisible + 1 < this.maxItems) {
                this.currentVisible++;
            } else {
                this.currentVisible = 0;
            }
        },
        prev() {
            this.$matomo && this.$matomo.trackEvent('Bildstrecke', 'click', this.productId);
            if (this.currentVisible > 0) {
                this.currentVisible--;
            } else {
                this.currentVisible = this.maxItems - 1;
            }
        },
    },
    computed: {
        ...mapGetters(['getProductById', 'getAgePrefix']),
    },
};
</script>
