<template>
    <transition name="component-fade">
        <section v-if="isActive" class="overlay tariffs-overlay">
            <div class="overlay__inner">
                <header class="overlay__header">
                    <h3 class="overlay__headline">
                        {{ $t(`overlays.${type}.headline`) }}
                    </h3>
                    <button class="icon-btn close-btn" @click="closeOverlay()">
                        <svg class="icon" width="16px" height="16px">
                            <use xlink:href="#icon-x" />
                        </svg>
                    </button>
                </header>
                <div class="overlay__content">
                    <div class="tariff-table">
                        <table>
                            <thead>
                                <tr>
                                    <th class="service-header">{{ $t('overlays.tariffs.text.table-headline') }}</th>
                                    <th v-for="product in services" :key="product.title" class="bg-blue service-header">
                                        {{ product.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(category, index) in serviceCategories"
                                    :key="category.name"
                                    :class="{ 'first-of-category': [0, 4, 6, 8].includes(index) }"
                                >
                                    <th width="50%" class="size-small" v-html="category.text" />
                                    <td
                                        v-for="(product, index) in services"
                                        :key="product.title"
                                        class="size-small"
                                        :class="{
                                            'color-primary': service(index, category.name).includes('✓'),
                                        }"
                                        width="12.5%"
                                        v-html="service(index, category.name)"
                                    ></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="small-text mt-1 mb-0 color-gray">
                        {{ $t('overlays.tariffs.text.notice') }}
                    </p>
                    <p class="small-text color-gray mt-1">
                        <small>{{ $t('overlays.tariffs.text.footnote-1') }}</small>
                        <br />
                        <small>{{ $t('overlays.tariffs.text.footnote-2') }}</small>
                        <br />
                        <small>{{ $t('overlays.tariffs.text.footnote-3') }}</small>
                        <br />
                        <small>{{ $t('overlays.tariffs.text.footnote-4') }}</small>
                        <br />
                        <small>{{ $t('overlays.tariffs.text.footnote-5') }}</small>
                        <br />
                        <small>{{ $t('overlays.tariffs.text.footnote-6') }}</small>
                    </p>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/helpers';
export default {
    props: {
        type: {
            type: String,
            default: 'bankstatus',
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            serviceCategories: [
                // --- Kontoführung
                { name: 'status_1', text: 'Kontoführung p.M. im Klassik-Status' },
                { name: 'status_2', text: 'Kontoführung p.M. im Komfort-Status' },
                { name: 'status_3', text: 'Kontoführung p.M. im Silber-Status' },
                { name: 'status_4', text: 'Kontoführung p.M. im Gold-Status' },
                // --- Bargeld Ein-/Auszahlung
                { name: 'cash_1', text: 'Bargeldein-/auszahlungen am Geldautomaten mit der Debitkarte<sup>1)</sup>' },
                { name: 'cash_2', text: 'Bargeldein-/auszahlungen am Schalter<sup>2)</sup>' },
                // --- Buchungen
                {
                    name: 'booking_1',
                    text: 'Buchungen Beleglos/elektronisch übermittelt/im Online-Banking<sup>3)</sup>',
                },
                { name: 'booking_2', text: 'Überweisung beleghaft, Scheckeinreichung<sup>3)</sup>' },
                // ---
                { name: 'service_1', text: 'Kontoauszug im elektronischen Postfach<sup>4)</sup>' },
                { name: 'service_2', text: 'Kontoauszug am Kontoauszugdrucker<sup>4)</sup>' },
                { name: 'service_3', text: 'Ausgabe einer Debitkarte – girocard (p.a.)' },
                { name: 'service_4', text: 'Ausgabe einer Debitkarte – digitale girocard (p.a.)' },
                { name: 'service_5', text: 'Kreditkarte Classic oder Basic' },
                { name: 'service_6', text: 'Kreditkarte Gold mit inkludierten Auslandsleistungen<sup>5)</sup>' },
                { name: 'service_7', text: 'OnlineBanking-Zugang, VR-BankingApp, TAN-Erzeugung' },
            ],
            services: [
                {
                    title: 'PremiumKonto',
                    status_1: 12.99,
                    status_2: 9.99,
                    status_3: 6.99,
                    status_4: 3.99,
                    // ---
                    cash_1: true,
                    cash_2: true,
                    // ---
                    booking_1: true,
                    booking_2: true,
                    // ---
                    service_1: true,
                    service_2: true,
                    service_3: 'leipzig-special',
                    service_4: true,
                    service_5: 30,
                    service_6: 40,
                    service_7: true,
                },
                {
                    title: 'PrivatKonto',
                    status_1: 8.99,
                    status_2: 5.99,
                    status_3: 2.99,
                    status_4: 0,
                    // ---
                    cash_1: true,
                    cash_2: 1.5,
                    // ---
                    booking_1: true,
                    booking_2: 1.5,
                    // ---
                    service_1: true,
                    service_2: '1,00 € (1.p.M.frei)',
                    service_3: 9,
                    service_4: true,
                    service_5: 30,
                    service_6: 80,
                    service_7: true,
                },
                {
                    title: 'DirektKonto',
                    status_1: 5.99,
                    status_2: 2.99,
                    status_3: 0,
                    status_4: 0,
                    // ---
                    cash_1: true,
                    cash_2: 2.5,
                    // ---
                    booking_1: '0,15 € (20p.M.frei)',
                    booking_2: 2.5,
                    // ---
                    service_1: true,
                    service_2: 2,
                    service_3: 9,
                    service_4: true,
                    service_5: 30,
                    service_6: 80,
                    service_7: true,
                },
                {
                    title: 'MeinKonto',
                    status_1: 0,
                    status_2: 0,
                    status_3: 0,
                    status_4: 0,
                    // ---
                    cash_1: true,
                    cash_2: true,
                    // ---
                    booking_1: true,
                    booking_2: true,
                    // ---
                    service_1: true,
                    service_2: true,
                    service_3: 'leipzig-special',
                    service_4: true,
                    service_5: true,
                    service_6: 80,
                    service_7: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['getAppModules']),
        service() {
            return (tariff, category) => {
                const content = this.services[tariff][category];

                if (typeof content === 'boolean') {
                    return content === true ? '✓' : 'x';
                }
                if (typeof content === 'number') {
                    return Helpers.formatNumberAsEuro(content);
                }
                if (content === 'leipzig-special') {
                    return `✓<sup>6)</sup>`;
                }
                if (typeof content === 'string') {
                    return content;
                }
            };
        },
    },
    methods: {
        closeOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: `${this.type}Overlay`,
                payload: false,
            });
        },
        keyListeners: function(e) {
            // Listen to ESC key
            if (e.keyCode === 27) {
                this.closeOverlay();
            }
        },
    },
    watch: {
        isActive(newValue) {
            if (newValue === true) {
                document.addEventListener('keyup', this.keyListeners);
            } else {
                document.removeEventListener('keyup', this.keyListeners);
            }
        },
    },
};
</script>
