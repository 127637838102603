<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie ist Ihre berufliche Situation?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="options" v-model="inputValue" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            options: {
                1: {
                    icon: '',
                    label: 'Angestellt',
                },
                2: {
                    icon: '',
                    label: 'Selbstständig',
                },
                3: {
                    icon: '',
                    label: 'In Berufsausbildung',
                },
                4: {
                    icon: '',
                    label: 'Verbeamtet',
                },
                5: {
                    icon: '',
                    label: 'Im Ruhestand',
                },
                6: {
                    icon: '',
                    label: 'Nicht berufstätig',
                },
                7: {
                    icon: '',
                    label: 'Schüler / Student',
                },
            },
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getPrefillableQuestions.job;
            },
            set(value) {
                this.isAnswered = value !== '';
                this.updateQuestionState('job', value);

                if (parseInt(value) === 5) {
                    this.$store.commit('UPDATE_PERSONAL_DATA', {
                        data: 'isRetired',
                        payload: true,
                    });
                } else {
                    this.$store.commit('UPDATE_PERSONAL_DATA', {
                        data: 'isRetired',
                        payload: false,
                    });
                }
            },
        },
    },
    mounted() {
        if (this.inputValue !== '') {
            this.isAnswered = true;
            this.updateQuestionStateOnly('job', this.inputValue);
        }
    },
};
</script>
