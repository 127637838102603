<template>
    <div class="questionnaire__content">
        <transition v-bind:name="transitionName">
            <Question :is="activeQuestion" :module="module" :question="activeQuestion" v-on="$listeners" />
        </transition>
    </div>
</template>

<script>
import * as Question from './liquiditaet/questions';
import { mapGetters } from 'vuex';

export default {
    components: {
        ...Question,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
        transitionName: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(['getActiveQuestion']),
        activeQuestion() {
            return this.getActiveQuestion(this.module.id);
        },
    },
};
</script>
