import { mapGetters } from 'vuex';

export const questionPropertiesMixin = {
    props: {
        module: {
            type: Object,
            required: true,
        },
        question: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['getQuestionAnsweredState', 'getQuestion', 'getPrefillableQuestions', 'getViewState']),
        isAnswered: {
            get() {
                return this.getQuestionAnsweredState(this.module.id, this.question);
            },
            set(value) {
                this.$store.commit('UPDATE_QUESTION_STATE', {
                    moduleId: this.module.id,
                    question: this.question,
                    field: 'isAnswered',
                    payload: value,
                });
            },
        },
    },
    methods: {
        getQuestionState(field, question = this.question) {
            return this.getQuestion(this.module.id, question, field);
        },
        updateQuestionState(field, payload, question = this.question) {
            this.$store.commit('UPDATE_QUESTION_STATE', {
                moduleId: this.module.id,
                question: question,
                field: field,
                payload: payload,
            });

            if (this.isAnswered) {
                this.$emit('questionWasAnswered', this.question);
            }

            this.$store.commit('UPDATE_SLIDE_TRANSITION_DIRECTION', false);
        },
        updateQuestionStateOnly(field, payload, question = this.question) {
            this.$store.commit('UPDATE_QUESTION_STATE', {
                moduleId: this.module.id,
                question: question,
                field: field,
                payload: payload,
            });
        },
    },
};
