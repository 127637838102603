<template>
    <button v-if="isVisible" @click="playSound" :class="classObject" class="product__sound">
        <app-icon icon="sound" size-px="18px" class="icon" />
    </button>
</template>

<script>
import AppIcon from '@/components/AppIcon';
import productAudioMapping from '@/data/productAudioMapping';
import { playE3 } from '@/audio';
import { eventBus } from '@/eventBus';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductSoundButton',
    components: { AppIcon },
    props: {
        productId: {
            type: String,
            required: true,
        },
        parentClass: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isPlaying: false,
        };
    },
    mounted() {
        eventBus.$on('global-audio-stop', () => {
            this.isPlaying = false;
        });
    },
    computed: {
        ...mapGetters(['getPersonalData']),
        classObject: function() {
            return {
                'is-active': this.isPlaying,
                ...this.parentClass,
            };
        },
        isVisible() {
            return productAudioMapping.hasOwnProperty(this.productId) && productAudioMapping[this.productId] >= 0;
        },
    },
    methods: {
        playSound() {
            eventBus.$emit('global-audio-stop');
            this.isPlaying = true;
            playE3(this.productId).then(() => {
                this.isPlaying = false;
            });
        },
    },
    beforeDestroy() {
        eventBus.$off('global-audio-stop');
    },
};
</script>
