<template>
    <div class="bankstatus">
        <div class="bankstatus__hint" v-if="showBankStatusHint">
            <router-link :to="{ name: 'clipboard' }">
                {{ $t('global.simulation.bankstatus-notice') }}
            </router-link>
        </div>
        <div class="bankstatus__inner wrapper wrapper--medium" :class="statusClass">
            <div class="inner--container">
                <div class="bankstatus-header bankstatus-header">
                    <bank-status-modal />
                    <div class="bankstatus-header__section">
                        <div class="bankstatus-header__status flex">
                            <bank-status-icon />
                            <div>
                                <h2 class="bankstatus-header__headline">
                                    {{ statusHeadline }}
                                </h2>
                                <span class="bankstatus-header__label">
                                    {{ $t('bankstatus.labels.status') }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="bankstatus-header__section" :class="{ 'is-dimmed': isDimmed }">
                        <div @click="openBankstatusOverlay" class="has-pointer">
                            <h2 class="bankstatus-header__headline">{{ statusBenefit }}</h2>
                            <span class="bankstatus-header__label">
                                {{ $t('bankstatus.labels.status-benefit') }}
                            </span>
                        </div>
                    </div>
                    <bank-status-sound-button />
                </div>
                <bank-status-progress />
            </div>
            <div class="bankstatus__footer" :class="{ 'is-hidden': nextStatus === '' }">
                <div
                    v-if="nextStatus !== ''"
                    class="bankstatus-progress-text"
                    :class="{ 'animate-pulse': showBounceAnimation }"
                >
                    {{
                        $t('bankstatus.labels.next-status', nextStatusCond, {
                            count: nextStatusCond,
                            nextStatus: $t(`bankstatus.levels.${nextStatus}.long_name`),
                            module: $tc('bankstatus.labels.module', nextStatusCond),
                        })
                    }}
                    <recommendations-modal :disabled="modalDisabled" class="is-inline" />
                </div>
                <div v-else class="fake-height" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers, { BANKSTATUS } from '@/helpers';
import dimmedBankStatus from '@/data/dimmedBankStatus.json';
import BankStatusProgress from '@/components/bankstatus/BankStatusProgress';
import BankStatusSoundButton from '@/components/bankstatus/BankStatusSoundButton';
import BankStatusIcon from '@/components/bankstatus/BankStatusIcon';
import RecommendationsModal from '@/components/RecommendationsModal';
import BankStatusModal from '@/components/bankstatus/BankStatusModal';
import { eventBus } from '@/eventBus';

export default {
    components: { BankStatusModal, RecommendationsModal, BankStatusIcon, BankStatusSoundButton, BankStatusProgress },
    data() {
        return {
            statusSequence: ['classic', 'comfort', 'silver', 'gold'],
            statusConditions: [],
            conditionToStatusMapping: [],
        };
    },
    computed: {
        ...mapGetters({
            bankstatusCount: 'bankstatusCount',
            isPrefilled: 'isPrefilled',
            getAppModules: 'getAppModules',
            getProductById: 'getProductById',
            getProductsWithBenefits: 'getProductsWithBenefits',
            getProductField: 'getProductField',
            getBankStatus: 'getBankStatus',
            getCloudData: 'getCloudData',
            getAnimationState: 'getAnimationState',
            getClipboardProducts: 'clipboard/getClipboardProducts',
        }),
        showBounceAnimation() {
            return this.getAnimationState('bounceBankStatusProgressText');
        },
        modalDisabled() {
            return this.getAnimationState('showIntroSimulation');
        },
        showBankStatusHint() {
            const { external, interest } = this.getClipboardProducts;
            return external.length + interest.length > 0 && !this.$store.state.animations.showIntroSimulation;
        },
        statusCount() {
            return this.bankstatusCount.filled.length;
        },
        status() {
            return this.getBankStatus;
        },
        nextStatus() {
            let nextStatus = '';
            let currentStatus = this.status;

            for (let i = 0; i < this.statusSequence.length; i++) {
                if (this.statusSequence[i] === currentStatus) {
                    if (this.statusSequence[i + 1] != null) {
                        nextStatus = this.statusSequence[i + 1];
                    }
                }
            }

            return nextStatus;
        },
        statusCond() {
            return this.getStatusCondition(this.status);
        },
        nextStatusCond() {
            let condition = this.getStatusCondition(this.nextStatus);
            return condition - this.statusCount;
        },
        statusClass() {
            return 'status-' + this.status;
        },
        statusHeadline() {
            return this.$t(`bankstatus.levels.${this.status}.short_name`);
        },
        hasSpecialBenefitConditions() {
            if (this.getCloudData !== null) {
                return this.getCloudData.Anzahl_VR_MeinKonto > 0 || this.getCloudData.Anzahl_Mitarbeiterkonto > 0;
            } else {
                return false;
            }
        },
        statusBenefit() {
            // INFO: hardcoded
            const Liquiditaet_Premium = this.getProductById('Liquiditaet_Premium');
            const Liquiditaet_Privat = this.getProductById('Liquiditaet_Privat');
            const Liquiditaet_Direkt = this.getProductById('Liquiditaet_Direkt');

            let specialBenefits = {
                classic: 0,
                comfort: 0,
                silver: 0,
                gold: 0,
            };

            if (
                Liquiditaet_Premium.options.BANK ||
                Liquiditaet_Premium.options.INTEREST ||
                this.getAnimationState('showIntroSimulation')
            ) {
                specialBenefits = {
                    classic: 0,
                    comfort: 3,
                    silver: 6,
                    gold: 9,
                };
            } else if (Liquiditaet_Privat.options.BANK || Liquiditaet_Privat.options.INTEREST) {
                specialBenefits = {
                    classic: 0,
                    comfort: 3,
                    silver: 6,
                    gold: 8.99,
                };
            } else if (Liquiditaet_Direkt.options.BANK || Liquiditaet_Direkt.options.INTEREST) {
                specialBenefits = {
                    classic: 0,
                    comfort: 3,
                    silver: 5.99,
                    gold: 5.99,
                };
            }
            // END: hardcoded

            return Helpers.formatNumberAsEuro(specialBenefits[this.status]);
        },
        isDimmed() {
            const exceptions = dimmedBankStatus.exclude;
            // INFO: Hardcoded key
            const productField = this.getProductField('Liquiditaet_Girokonto');
            const fullCountingAccounts = productField.products.filter((product) => {
                return !exceptions.includes(product.id);
            });
            let accountInteraction = 0;
            // return false if one of the accounts that
            // is *not* in the exclude list has 'BANK' or 'INTEREST'
            for (const product of productField.products) {
                if (product.options.BANK || product.options.INTEREST) {
                    accountInteraction++;
                }
            }
            if (accountInteraction === 0) {
                // if no account is selected it shouldn't be dimmed!
                return false;
            }

            let atLeastOneFullCountingAccount = false;
            for (const product of fullCountingAccounts) {
                if (product.options.BANK || product.options.INTEREST) {
                    atLeastOneFullCountingAccount = true;
                }
            }
            return !atLeastOneFullCountingAccount;
        },
    },
    methods: {
        getStatusCondition(status) {
            return BANKSTATUS[status.toUpperCase()].CONDITION;
        },
        getStatusConditions() {
            let conditions = {};

            this.statusSequence.forEach((status) => {
                conditions[parseInt(BANKSTATUS[status.toUpperCase()].CONDITION)] = 'status-' + status;
            });

            this.statusConditions = conditions;
        },
        openBankstatusOverlay() {
            eventBus.$emit('modal-bankstatus');
        },
    },
    beforeMount() {
        this.getStatusConditions();
    },
};
</script>
