export default {
    // travel_health_insurance.single_up_to_64 – C9
    // travel_health_insurance.single_over_65 – C10
    // travel_health_insurance.family – C11
    travel_health_insurance: {
        single_up_to_64: 10.8,
        single_over_65: 46.8,
        family: 22.8,
    },
    // travel_service_insurance.single – C13
    // travel_service_insurance.family – C14
    travel_service_insurance: {
        single: 52.0,
        family: 52.0,
    },
    // travel_cancellation_insurance.single – C16
    // travel_cancellation_insurance.family – C17
    travel_cancellation_insurance: {
        single: 0.0295,
        family: 0.032,
    },
    // international_coverletter_insurance.single – C19
    // international_coverletter_insurance.family – C20
    international_coverletter_insurance: {
        single: 42.9,
        family: 28.98,
    },
    // travel_booking_refund – C22
    travel_booking_refund: 0.07,
};
