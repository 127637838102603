<template>
    <div>
        <a class="toggle-recommendations-modal" :disabled="disabled" @click="openModal"
            ><span>mehr erfahren</span><app-icon size-px="16px" icon="clipboard"
        /></a>

        <modal-wrapper
            wide
            :open.sync="open"
            :title="$t('overlays.recommendations.title')"
            matomo-name="Bankempfehlungen"
        >
            <p>Was fehlt mir noch?</p>
            <div v-if="computedData.length === 0">
                <p>{{ $t('overlays.recommendations.no_recommendations') }}</p>
            </div>
            <div v-else-if="isPrefilled">
                <recommendations-product v-for="(product, index) in computedData" :key="index" :product="product" />
            </div>
            <div v-else>
                <recommendations-product-field
                    v-for="(productField, index) in computedData"
                    :key="index"
                    :product-field="productField"
                />
            </div>
            <!-- hide contact button from customer feedback
            <button @click="openContactForm" class="mt-2 w-100 btn btn&#45;&#45;cta btn&#45;&#45;rounded btn&#45;&#45;icon btn&#45;&#45;animate-arrow">
                {{ $t('clipboard.buttons.cta') }}
                <app-icon icon="arrow-right" custom-class="icon" size-px="18px" />
            </button>-->
        </modal-wrapper>
    </div>
</template>

<script>
import ModalWrapper from '@/components/modal/ModalWrapper';
import AppIcon from '@/components/AppIcon';
import RecommendationsProductField from '@/components/RecommendationsProductField';
import RecommendationsProduct from '@/components/RecommendationsProduct';
import { mapGetters } from 'vuex';

export default {
    name: 'RecommendationsModal',
    components: { RecommendationsProduct, RecommendationsProductField, AppIcon, ModalWrapper },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            open: false,
            statusSequence: ['classic', 'comfort', 'silver', 'gold'],
        };
    },
    methods: {
        openModal() {
            if (!this.disabled) {
                this.open = true;
            }
        },
        openContactForm() {
            this.$matomo && this.$matomo.trackEvent('Modals', 'open', 'Merkzettel absenden (Bankempfehlungen)');
            this.open = false;
            this.$store.commit('UPDATE_VIEW', {
                view: 'clipboardOverlay',
                payload: true,
            });
        },
    },
    computed: {
        ...mapGetters({
            isPrefilled: 'isPrefilled',
            getPromotedProductFields: 'recommendation/getPromotedProductFields',
            getFilteredPromotedProducts: 'recommendation/getFilteredPromotedProducts',
            getNeededPointsToNextLevel: 'recommendation/getNeededPointsToNextLevel',
        }),
        computedData() {
            if (this.isPrefilled) {
                // CAUTION: Here we have to manually slice to the limit in prefilled mode
                // CAUTION2: We don't check here if the products are in the same productField
                // ¯\_(ツ)_/¯
                return this.getFilteredPromotedProducts.slice(0, this.getNeededPointsToNextLevel);
            }
            return this.getPromotedProductFields;
        },
    },
};
</script>
