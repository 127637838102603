<template>
    <div class="content wrapper wrapper--medium">
        <Modules />
        <BankStatus />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modules from '@/components/Modules.vue';
import BankStatus from '@/components/bankstatus/BankStatus.vue';

export default {
    name: 'home',
    components: {
        Modules,
        BankStatus,
    },
    data() {
        return {
            timer: '',
        };
    },
    computed: {
        ...mapGetters({
            getAnimationState: 'getAnimationState',
            getClipboardProducts: 'clipboard/getClipboardProducts',
        }),
        clipboardCount() {
            return this.getClipboardProducts.interest.length;
        },
    },
    methods: {
        showClipboardReminder() {
            if (this.clipboardCount > 0) {
                this.$store.commit('UPDATE_VIEW', {
                    view: 'clipboardReminderTooltip',
                    payload: true,
                });
            }
        },
    },
    created() {
        this.timer = setInterval(this.showClipboardReminder, 30000);
    },
    mounted() {
        if (!this.getAnimationState('showIntroSimulation')) {
            // Wait until scroll animation is finished
            setTimeout(() => {
                this.$store.dispatch('animateModuleStatus');
            }, 300);
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
