<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie alt sind Sie?
            </h3>
        </div>
        <div class="question__content">
            <RangeSlider :minRange="0" :maxRange="100" label="Jahre" v-model="age" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    computed: {
        age: {
            get() {
                return this.getPrefillableQuestions.age;
            },
            set(value) {
                this.isAnswered = value > 0;
                this.updateQuestionState('age', value);
            },
        },
    },
    mounted() {
        if (this.age > 0) {
            this.isAnswered = true;
        }
    },
};
</script>
