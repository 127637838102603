<template>
    <div class="questionnaire-progress">
        <button
            @click.prevent="handlePrevStep()"
            class="btn btn--rounded questionnaire-progress__back-btn"
            :disabled="!isPrevButtonActive"
        >
            {{ $t('modules.questionnaires.buttons.back') }}
        </button>
        <div class="questionnaire-pagination">
            <span
                v-for="(question, index) in questions"
                :key="index"
                class="questionnaire-pagination__item"
                :class="{
                    'is-filled': isFilledQuestion(question),
                    'is-active': isActiveQuestion(question),
                    'is-skipped': isSkippedQuestion(question),
                }"
            ></span>
        </div>
        <button
            @click.prevent="handleNextStep()"
            class="btn btn--cta btn--rounded questionnaire-progress__next-btn"
            :disabled="!isNextButtonActive"
        >
            {{ nextButtonText }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'getQuestions',
            'getActiveQuestion',
            'getQuestionAnsweredState',
            'hideQuestionFromNavigation',
            'hideQuestionWhenPrefilled',
            'hideQuestionWhenStandalone',
            'getQuestionObject',
            'getHighestActiveQuestion',
            'getNextQuestionId',
            'getPrevQuestionId',
            'isPrefilled',
            'isStandalone',
            'getAllProductsObjects',
        ]),
        activeQuestion() {
            return this.getActiveQuestion(this.module.id);
        },
        questions() {
            let questions = Object.keys(this.getQuestions(this.module.id));

            // Filter out questions that are hidden from menu
            questions = questions.filter((q) => !this.hideQuestionFromNavigation(this.module.id, q));

            // Filter out questions that are hidden in prefilled mode
            if (this.isPrefilled) {
                questions = questions.filter((q) => !this.hideQuestionWhenPrefilled(this.module.id, q));
            }
            // Filter out questions that are hidden in standalone mode
            if (this.isStandalone) {
                questions = questions.filter((q) => !this.hideQuestionWhenStandalone(this.module.id, q));
            }

            return questions;
        },
        prevQuestion() {
            return this.getPrevQuestionId(this.module.id);
        },
        isPrevButtonActive() {
            return this.prevQuestion !== undefined;
        },
        nextQuestion() {
            return this.getNextQuestionId(this.module.id);
        },
        nextButtonText() {
            let state = this.nextQuestion !== undefined ? 'next' : 'done';
            return this.$t(`modules.questionnaires.buttons.${state}`);
        },
        isNextButtonActive() {
            let question = this.activeQuestion;
            return this.getQuestionState(question);
        },
        isQuestionnaireFinished() {
            return this.nextQuestion === undefined;
        },
        highestActiveQuestion() {
            return this.getHighestActiveQuestion(this.module.id);
        },
    },
    methods: {
        isActiveQuestion(question) {
            return question === this.activeQuestion;
        },
        isFilledQuestion(question) {
            return this.getQuestionState(question) && this.isAfterCurrentQuestion(question);
        },
        isAfterCurrentQuestion(question) {
            return this.questions.indexOf(question) <= this.questions.indexOf(this.highestActiveQuestion);
        },
        isSkippedQuestion(question) {
            // Get question condition or fall back to "true"
            let condition = this.getQuestionObject(this.module.id, question).condition || (() => true);

            // Check condition, passing in all questions of module
            let isQuestionConditionMet = condition(this.getQuestions(this.module.id), this.getAllProductsObjects);

            let isSkipped = !isQuestionConditionMet && this.isAfterCurrentQuestion(question);

            if (this.nextQuestion === undefined) {
                isSkipped = !isQuestionConditionMet;
            }

            return isSkipped;
        },
        getQuestionState(question) {
            return this.getQuestionAnsweredState(this.module.id, question);
        },
        handlePrevStep() {
            // Trigger Reset of subsequent questions
            this.$store.dispatch('resetSubsequentQuestions', {
                moduleId: this.module.id,
                question: this.activeQuestion,
            });

            let prevQuestion = this.prevQuestion;

            /* TODO: Does this belong to Leipzip too? */
            /*if (this.isStandalone && this.activeQuestion === "Question8") {
        if (this.getQuestions("liquiditaet").Question5.value === "no") {
          prevQuestion = "Question5";
        } else {
          prevQuestion = "Question6a";
        }
      }*/

            // Update current active question state to switch question
            this.$store.commit('UPDATE_ACTIVE_QUESTION', {
                moduleId: this.module.id,
                payload: prevQuestion,
            });

            // Decrease highest visited question
            this.$store.commit('UPDATE_HIGHEST_VISITED_QUESTION', {
                moduleId: this.module.id,
                payload: this.activeQuestion,
            });

            this.$store.commit('UPDATE_SLIDE_TRANSITION_DIRECTION', true);
        },
        handleNextStep() {
            if (!this.isQuestionnaireFinished) {
                // Trigger Reset of subsequent questions
                this.$store.dispatch('resetSubsequentQuestions', {
                    moduleId: this.module.id,
                    question: this.activeQuestion,
                    offset: 1,
                });

                let nextQuestion = this.nextQuestion;

                /* TODO: Does this belong to Leipzip too? */
                // Sonderlogik für Standalone Kontofinder (Frage 7 wird übersprungen!)
                /*if (
          this.isStandalone &&
          (this.activeQuestion === "Question6a" ||
            this.getQuestions("liquiditaet").Question5.value === "no")
        ) {
          nextQuestion = "Question8";
        }*/

                // Update current active question state to switch question
                this.$store.commit('UPDATE_ACTIVE_QUESTION', {
                    moduleId: this.module.id,
                    payload: nextQuestion,
                });

                this.$store.commit('UPDATE_SLIDE_TRANSITION_DIRECTION', false);
            } else {
                // End of Questionnaire – Tell questionnaire component to handle next steps
                this.$emit('questionnaireFinished');

                // Reset questions
                this.$store.dispatch('resetSubsequentQuestions', {
                    moduleId: this.module.id,
                    question: this.questions[0],
                });

                // Reset highestVisitedQuestion
                this.$store.commit('UPDATE_HIGHEST_VISITED_QUESTION', {
                    moduleId: this.module.id,
                    payload: this.questions[0],
                });

                // Reset active question
                this.$store.commit('UPDATE_ACTIVE_QUESTION', {
                    moduleId: this.module.id,
                    payload: this.questions[0],
                });
            }
        },
    },
    watch: {
        activeQuestion() {
            // Set highestActiveQuestion when active question is switched
            let currentPosition = this.questions.indexOf(this.activeQuestion);
            let highestPosition = this.questions.indexOf(this.getHighestActiveQuestion(this.module.id));
            if (highestPosition < currentPosition) {
                this.$store.commit('UPDATE_HIGHEST_VISITED_QUESTION', {
                    moduleId: this.module.id,
                    payload: this.activeQuestion,
                });
            }
        },
    },
};
</script>
