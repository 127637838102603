<template>
    <div class="content" :class="[moduleColorClass, productFieldStatusColor]">
        <div class="wrapper wrapper--narrow">
            <div class="module">
                <div class="columns is-vcentered is-mobile" v-show="showModule">
                    <div class="column">
                        <app-icon :icon="module.icon" custom-class="module__icon" size-px="64px" />
                    </div>
                    <div class="column has-text-centered">
                        <div class="module__headline__wrapper">
                            <h2 class="module__headline">
                                {{ $t(`global.module.${module.id}`) }}
                            </h2>
                        </div>
                        <div class="level">
                            <div class="level-item">
                                <progress-bars
                                    color-invert
                                    :filled="filledProductFieldCount"
                                    :dashed="dashedProductFieldCount"
                                    :retired="retiredProductFieldCount"
                                    :max="productFieldCount"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <module-sound-button class="pull-right" :module="module" />
                    </div>
                </div>
                <transition name="fade" mode="out-in">
                    <div>
                        <div class="module__header" v-show="showModule"></div>
                    </div>
                </transition>
                <transition name="fade" mode="out-in">
                    <div class="module__content" v-show="showModule">
                        <div class="productfields">
                            <ProductField
                                :module-id="module.id"
                                v-for="(productField, productFieldKey) in filteredProductFields"
                                :key="productFieldKey"
                                :productField="productField"
                                :showProductList="currentlyOpenProductlist === productFieldKey"
                                @productlist-toggled="handleProductlistVisibility(productFieldKey)"
                                :data-show="showProductField(productField)"
                            />
                        </div>
                        <div v-if="module.id === 'vorsorge'" class="module__input input-block align-center">
                            <Checkbox val="retirement" v-model="retirementState" checkbox-class="checkbox--radio">
                                <span>{{ $t('modules.buttons.retirement') }}</span>
                            </Checkbox>
                        </div>
                    </div>
                </transition>
                <div v-if="hideSpecificModules">
                    <transition name="fade" mode="out-in">
                        <div
                            class="module__footer"
                            v-show="showModule"
                            v-observe-visibility="ctaButtonVisibilityChanged"
                        >
                            <router-link
                                v-if="!module.isSpecialModule"
                                :to="{
                                    name: 'questionnaire',
                                    params: { module: module.id },
                                }"
                                class="btn btn--rounded btn--icon cta-button"
                                :class="ctaButtonClass"
                            >
                                {{ $t(`modules.questionnaires.${module.id}.btn.cta`) }}
                                <svg class="icon" width="16px" height="16px">
                                    <use xlink:href="#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </transition>
                    <div class="ctabar" :class="{ 'is-active': showCtabar }">
                        <router-link
                            v-if="!module.isSpecialModule"
                            :to="{
                                name: 'questionnaire',
                                params: { module: module.id },
                            }"
                            class="btn btn--rounded btn--icon"
                            :class="ctaButtonClass"
                        >
                            {{ $t(`modules.questionnaires.${module.id}.btn.cta`) }}
                            <svg class="icon" width="16px" height="16px">
                                <use xlink:href="#icon-arrow-right" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductField from '@/components/product/ProductField.vue';
import Checkbox from '@/components/Checkbox.vue';
import { modulePropertiesMixin } from '@/mixins/moduleProperties';
import ModuleSoundButton from '@/components/ModuleSoundButton';
import AppIcon from '@/components/AppIcon';
import ProgressBars from '@/components/ProgressBars';

export default {
    mixins: [modulePropertiesMixin],
    components: {
        ProgressBars,
        AppIcon,
        ModuleSoundButton,
        ProductField,
        Checkbox,
    },
    data() {
        return {
            module: {},
            currentlyOpenProductlist: '',
            showModule: false,
            showCtabar: false,
        };
    },
    computed: {
        ...mapGetters(['getModule', 'getPersonalData', 'isQuestionnaireCompleted']),
        moduleColorClass() {
            return 'bg-' + this.module.color;
        },
        ctaButtonClass() {
            if (!this.isSpecialModule) {
                return this.isQuestionnaireCompleted(this.module.id) ? 'btn--gray' : 'btn--cta btn--animate-arrow';
            } else {
                return undefined;
            }
        },
        retirementState: {
            get() {
                return this.getPersonalData.isRetired;
            },
            set(value) {
                this.$store.commit('UPDATE_PERSONAL_DATA', {
                    data: 'isRetired',
                    payload: value,
                });
            },
        },
        productFieldStatusColor() {
            let status;
            if (this.isSpecialModule) {
                status = 'status-gold';
            } else {
                switch (this.totalFilledProductFieldCount) {
                    case 0:
                        status = 'status-classic';
                        break;
                    case 1:
                        status = 'status-comfort';
                        break;
                    case 2:
                        status = 'status-silver';
                        break;
                    case 3:
                        status = 'status-gold';
                        break;
                    default:
                        status = 'status-classic';
                }
            }

            return status;
        },
        filteredProductFields() {
            let productFields = this.module.productFields;

            if (this.isSpecialModule) {
                productFields = productFields.filter((pf) => {
                    return this.showProductField(pf);
                });

                return productFields;
            }

            return productFields;
        },
        hideSpecificModules() {
            return !require('@/data/excludeModuleQuestionnaire.json').exclude.includes(this.module.id);
        },
    },
    methods: {
        ctaButtonVisibilityChanged(isVisible) {
            this.showCtabar = !isVisible;
            return this.showCtabar;
        },
        timeoutModule() {
            setTimeout(
                function() {
                    this.showModule = true;
                }.bind(this),
                100
            );
            return this.showModule;
        },
        showProductField(productField) {
            const products = productField.products;

            const isProductFilled = (product) => {
                const options = product.options;
                return Object.values(options).some((option) => option);
            };

            const filledProducts = products.filter((p) => {
                return isProductFilled(p);
            });

            return filledProducts.length > 0;
        },
        populateModule() {
            let module = this.$route.params.module;
            this.module = this.getModule(module);
        },
        handleProductlistVisibility(id) {
            this.currentlyOpenProductlist = id;
        },
    },
    beforeMount() {
        this.populateModule();
    },
    created() {
        this.timeoutModule();
    },
};
</script>
