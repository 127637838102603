<template>
    <div class="content">
        <div class="wrapper wrapper--narrow">
            <old-clipboard />

            <transition name="fade" mode="out-in">
                <div class="clipboard" v-show="showClipboard">
                    <div class="clipboard__header">
                        <h2 class="clipboard__headline">
                            {{ $t('clipboard.title') }}
                        </h2>
                    </div>
                    <div class="clipboard__content">
                        <div class="clipboard__section">
                            <h3 class="clipboard__subheadline">
                                {{ $t('clipboard.subheadline.interests') }}
                            </h3>
                            <ClipboardProduct
                                v-for="(product, index) in clipboardProductsInterest"
                                :key="index"
                                :product="product"
                                section="INTEREST"
                            />
                            <p
                                v-if="Object.keys(clipboardProducts('interest')).length === 0"
                                class="clipboard-text color-gray"
                            >
                                {{ $t('clipboard.emptystate') }}
                            </p>
                        </div>
                        <div class="clipboard__section">
                            <h3 class="clipboard__subheadline">
                                {{ $t('clipboard.subheadline.external') }}
                            </h3>
                            <ClipboardProduct
                                v-for="(product, index) in clipboardProductsExternal"
                                :key="index"
                                :product="product"
                                section="EXTERNAL"
                            />
                            <p
                                v-if="Object.keys(clipboardProducts('external')).length === 0"
                                class="clipboard-text color-gray"
                            >
                                {{ $t('clipboard.emptystate') }}
                            </p>
                        </div>
                        <div
                            class="clipboard__section"
                            v-if="
                                getPersonalData.isRetired ||
                                    getPersonalData.sufficientFunds ||
                                    getPersonalData.paidOffProperty ||
                                    getPersonalData.riskProfile !== ''
                            "
                        >
                            <h3 class="clipboard__subheadline">
                                {{ $t('clipboard.subheadline.other') }}
                            </h3>
                            <ul class="clipboard-text">
                                <li v-if="getPersonalData.isRetired">
                                    {{ $t('clipboard.text.retirement') }}
                                </li>
                                <li v-if="getPersonalData.sufficientFunds">
                                    {{ $t('clipboard.text.sufficient-funds') }}
                                </li>
                                <li v-if="getPersonalData.paidOffProperty">
                                    {{ $t('clipboard.text.paid-off-property') }}
                                </li>
                                <li v-if="getPersonalData.riskProfile !== ''">
                                    {{
                                        $t('clipboard.text.risk-profile', {
                                            profile: `"${riskProfile}"`,
                                        })
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="clipboard__footer">
                        <div class="align-center">
                            <button class="text-btn" @click="print">
                                {{ $t('clipboard.buttons.print-clipboard') }}
                                <svg class="icon" width="16px" height="16px">
                                    <use xlink:href="#icon-print" />
                                </svg>
                            </button>
                        </div>
                        <p class="small-text color-gray">
                            {{ $t('clipboard.description') }}
                        </p>
                    </div>
                    <div class="clipboard__cta" v-observe-visibility="ctaButtonVisibilityChanged">
                        <button
                            class="btn btn--cta btn--rounded btn--icon btn--animate-arrow cta-button"
                            :class="{ 'animate-shake': clipboardHasEntries }"
                            @click="openClipboardForm"
                        >
                            {{ $t('clipboard.buttons.cta') }}
                            <svg class="icon" width="18px" height="18px">
                                <use xlink:href="#icon-arrow-right" />
                            </svg>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
        <div class="ctabar" :class="{ 'is-active': showCtabar }">
            <button
                class="btn btn--cta btn--rounded btn--icon btn--animate-arrow"
                :class="{ 'animate-shake': clipboardHasEntries }"
                @click="openClipboardForm"
            >
                {{ $t('clipboard.buttons.cta') }}
                <svg class="icon" width="18px" height="18px">
                    <use xlink:href="#icon-arrow-right" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import ClipboardProduct from '@/components/clipboard/ClipboardProduct.vue';
import { mapGetters } from 'vuex';
import riskLevels from '@/data/riskLevels';
import OldClipboard from '@/components/clipboard/OldClipboard';

export default {
    components: {
        OldClipboard,
        ClipboardProduct,
    },
    data() {
        return {
            showClipboard: false,
            showCtabar: false,
        };
    },
    computed: {
        ...mapGetters({
            getClipboardProductSection: 'clipboard/getClipboardProductSection',
            getProductById: 'getProductById',
            getModule: 'getModule',
            getPersonalData: 'getPersonalData',
        }),
        riskProfile() {
            return this.getPersonalData.riskProfile !== '' ? riskLevels[this.getPersonalData.riskProfile].type : '';
        },
        clipboardProductsExternal() {
            return this.clipboardProducts('external').filter((product) => {
                // INFO Hardcoded exception for 'Vorsorge' and the 'Bereits im Ruhestand feature';
                return !(product.moduleId === 'vorsorge' && !product.excludeFromRetirement);
            });
        },
        clipboardProductsInterest() {
            return this.clipboardProducts('interest');
        },
        clipboardHasEntries() {
            return (
                Object.keys(this.clipboardProductsInterest).length !== 0 ||
                Object.keys(this.clipboardProductsExternal).length !== 0
            );
        },
    },
    methods: {
        ctaButtonVisibilityChanged(isVisible) {
            this.showCtabar = !isVisible;
            return this.showCtabar;
        },
        timeoutClipboard() {
            setTimeout(
                function() {
                    this.showClipboard = true;
                }.bind(this),
                100
            );
            return this.showModule;
        },
        clipboardProducts(section) {
            let products = [];
            const clipboardProducts = this.getClipboardProductSection(section);

            clipboardProducts.forEach((id, index) => {
                let product = this.getProductById(id);
                let module = this.getModule(product.moduleId);

                product['color'] = module.color;
                products[index] = product;
            });

            return products;
        },
        openClipboardForm() {
            this.$matomo && this.$matomo.trackEvent('Modals', 'open', 'Merkzettel absenden (Merkzettel)');
            this.$store.commit('UPDATE_VIEW', {
                view: 'clipboardOverlay',
                payload: true,
            });
        },
        print() {
            this.$matomo && this.$matomo.trackEvent('Click', 'Merkzettel drucken');
            window.print();
        },
    },
    created() {
        this.timeoutClipboard();
    },
};
</script>
