import { Howl, Howler } from 'howler';
import { PRODUCT_FIELD_STATUS } from '@/helpers';
import Helpers from '@/helpers';
import productAudioMapping from '@/data/productAudioMapping';
import audioMappingE2 from '@/data/audioMappingE2';
import audioMappingE1_2 from '@/data/audioMappingE1_2';
import audioMappingE1_1 from '@/data/audioMappingE1_1';
// INFO: Hardcoded path
const audioPath = '/assets/audio/Leipzig-';

let currentlyPlaying;
let sound;
let audioQueue = [];
let activeSound = '';

function playNextAudio() {
    let audioFile = audioQueue.shift();
    let fullAudioPath = `${audioPath}${audioFile}.mp3`;
    sound = new Howl({
        src: fullAudioPath,
    });

    currentlyPlaying = sound.play();

    return new Promise((resolve) => {
        sound.on('end', () => {
            if (audioQueue.length) {
                playNextAudio().then(() => {
                    activeSound = '';
                    resolve();
                });
            } else {
                activeSound = '';
                resolve();
            }
        });
        sound.on('loaderror', () => {
            activeSound = '';
            resolve();
            throw new Error('Audio not found: ' + fullAudioPath);
        });
    });
}

/**
 * MATOMO TRACKING:
 * this.$matomo does *NOT* work outside Vue context!
 * use `window._paq.push(['trackEvent', 'your_category', 'the_action', 'a_name', 'a_numeric_value']);'` instead!
 * (They both have the same parameters!
 */

/**
 * @param statusData
 * @param statusCount
 * @param {Boolean, String} productFieldRecommendation
 */
export function playE1(statusData, statusCount, productFieldRecommendation = false) {
    if (activeSound === statusData) {
        window._paq && window._paq.push(['trackEvent', 'Sounds', 'cancel', 'e1']);

        Howler.unload();
        activeSound = '';
        return new Promise((resolve) => resolve());
    } else {
        Howler.unload();
    }

    window._paq && window._paq.push(['trackEvent', 'Sounds', 'start', 'e1']);

    activeSound = statusData;

    const audioMapping1 = audioMappingE1_1.audio;
    const audioMapping2 = audioMappingE1_2.audio;

    let max = 0;

    for (const key in audioMapping1) {
        const currentKey = parseInt(key.replace('count-', ''));
        if (currentKey > max) {
            max = currentKey;
        }
    }

    if (statusCount > max) {
        statusCount = max;
    }

    const audio1 = audioMapping1[`count-${statusCount}`];
    let audio2 = null;

    if (productFieldRecommendation !== false) {
        audio2 = audioMapping2[productFieldRecommendation];
    }

    if (audio2) {
        audioQueue = [audio1, audio2];
    } else {
        audioQueue = [audio1];
    }

    return playNextAudio();
}

/**
 * @param activeModule
 * @param {Boolean} isRetired
 */
export function playE2(activeModule, isRetired = false) {
    if (activeSound === activeModule.id) {
        window._paq && window._paq.push(['trackEvent', 'Sounds', 'cancel', 'e2']);

        Howler.unload();
        activeSound = '';
        return new Promise((resolve) => resolve());
    } else {
        Howler.unload();
    }

    activeSound = activeModule.id;

    window._paq && window._paq.push(['trackEvent', 'Sounds', 'start', 'e2']);

    if (isRetired) {
        audioQueue = ['61'];
    } else {
        const productFields = activeModule.productFields;
        const activeModuleId = activeModule.id;
        const audioMapping = audioMappingE2.audio[activeModuleId];
        let audioMappingString = '';

        for (const productField of productFields) {
            const productFieldStatus = Helpers.getProductFieldStatus(productField);
            const mappingSegment = productFieldStatus !== PRODUCT_FIELD_STATUS.EMPTY ? '1' : '0';
            audioMappingString += mappingSegment;
        }

        audioQueue = [audioMapping[audioMappingString]];
    }
    return playNextAudio();
}

/**
 * @param {String} productId
 */
export function playE3(productId) {
    if (activeSound === productId) {
        window._paq && window._paq.push(['trackEvent', 'Sounds', 'cancel', productId]);

        Howler.unload();
        activeSound = '';
        return new Promise((resolve) => resolve());
    } else {
        Howler.unload();
    }

    window._paq && window._paq.push(['trackEvent', 'Sounds', 'start', productId]);

    activeSound = productId;
    if (!productAudioMapping.hasOwnProperty(productId)) {
        return;
    }
    const audioMapping = productAudioMapping[productId];

    audioQueue = [`${audioMapping}`];

    return playNextAudio();
}

export function stop() {
    return new Promise((resolve) => {
        if (sound && sound.playing(currentlyPlaying)) {
            Howler.unload();
            resolve();
        }
    });
}
