<template>
    <div class="bankstatus-header__icon" :class="{ 'bankstatus-header__shadow': getBankStatus !== 'classic' }">
        <fade-in-out>
            <app-icon
                v-if="getBankStatus !== 'classic'"
                custom-class="icon"
                :icon="`${getBankStatus}-trophy`"
                size-px="32px"
            />
        </fade-in-out>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FadeInOut from '@/components/transitions/FadeInOut';
import AppIcon from '@/components/AppIcon';

export default {
    name: 'BankStatusIcon',
    components: { AppIcon, FadeInOut },
    computed: {
        ...mapGetters(['getBankStatus']),
    },
};
</script>
