<template>
    <div class="checkbox-wrap toggle-switch" :class="productState">
        <div class="checkbox checkbox--radio">
            <input name="relevant" type="checkbox" :checked="!isActive" :id="id" @change="$emit('change')" />
            <label :for="id">
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        productState: {
            type: Object,
        },
    },
    data() {
        return {
            id: null,
        };
    },
    mounted() {
        this.id = `switch-${this._uid}`;
    },
};
</script>

<style lang="less" scoped>
.checkbox-wrap {
    padding: 0;
    &.is-filled {
        label {
            color: #fff !important;
        }
    }
}
.checkbox--radio {
    label {
        font-size: 12px;
        padding-top: 0;
        padding-left: 1.5em;
        &::before,
        &::after {
            transform: none;
        }
        &::before {
            left: 0;
            width: 12px;
            height: 12px;
        }
        &::after {
            top: 3px;
            left: 3px;
            width: 6px;
            height: 6px;
        }
    }
}
</style>
