<template>
    <transition name="fade">
        <div v-if="showBookmarkPopUp && android" class="popup-bookmark popup-bookmark-android">
            <div class="modal-arrow"></div>
            <button @click="hide" class="popup-bookmark__close-btn">
                <app-icon icon="x" size-px="12px" />
            </button>
            <p>
                {{ $t('bookmark.android.1') }}
                <app-icon icon="menu" custom-class="16px" />
                {{ $t('bookmark.android.2') }}
            </p>
        </div>

        <div v-else-if="showBookmarkPopUp && iphone" class="popup-bookmark popup-bookmark-iphone">
            <div class="modal-arrow"></div>
            <button @click="hide" class="popup-bookmark__close-btn">
                <app-icon icon="x" size-px="12px" />
            </button>
            <p>
                {{ $t('bookmark.ios.1') }}
                <app-icon icon="ios-menu" custom-class="16px" />
                {{ $t('bookmark.ios.2') }}
            </p>
        </div>

        <div v-else-if="showBookmarkPopUp && ipad" class="popup-bookmark popup-bookmark-ipad">
            <div class="modal-arrow"></div>
            <button @click="hide" class="popup-bookmark__close-btn">
                <app-icon icon="x" size-px="12px" />
            </button>
            <p>
                {{ $t('bookmark.ios.1') }}
                <app-icon icon="ios-menu" custom-class="16px" />
                {{ $t('bookmark.ios.2') }}
            </p>
        </div>

        <div v-else-if="showBookmarkPopUp" class="popup-bookmark popup-bookmark-fallback">
            <div class="modal-arrow"></div>
            <button @click="hide" class="popup-bookmark__close-btn">
                <app-icon icon="x" size-px="12px" />
            </button>
            <p>{{ $t('bookmark.fallback') }}</p>
        </div>
    </transition>
</template>

<script>
import AppIcon from '@/components/AppIcon';
import PlatformJs from 'platform';
import { mapGetters } from 'vuex';

export default {
    name: 'PopupBookmark',
    components: { AppIcon },
    data() {
        return {
            isHidden: false,
            android: false,
            iphone: false,
            ipad: false,
            completeAgent: '',
            osString: '',
            browserString: '',
        };
    },
    async created() {
        const agentData = await PlatformJs;
        this.completeAgent = JSON.stringify(agentData).toLowerCase();
        this.osString = JSON.stringify(agentData.os).toLowerCase();
        this.browserString = JSON.stringify(agentData.name).toLowerCase();

        if (
            !!agentData.product &&
            JSON.stringify(agentData.product)
                .toLowerCase()
                .includes('ipad')
        ) {
            this.ipad = true;
        } else if (this.osString.includes('iphone') && this.browserString.includes('safari')) {
            this.iphone = true;
        } else if (this.completeAgent.includes('android') && this.completeAgent.includes('chrome')) {
            this.android = true;
        }
    },
    methods: {
        hide() {
            this.$store.dispatch('hideBookmarkPopUp');
        },
    },
    computed: {
        ...mapGetters(['showBookmarkPopUp']),
    },
};
</script>
