<template>
    <div class="info-box" :class="'info-box--' + type">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'bg',
        },
    },
};
</script>
