<template>
    <div class="range-slider">
        <div class="slider-wrap" style="flex-basis: 70%">
            <input
                class="slider"
                type="range"
                :min="minRange"
                :max="maxRange"
                :step="step"
                ref="slider"
                v-model="rangeValueProxy"
            />
            <span class="range-slider__track">
                <span class="range-slider__progress" v-bind:style="{ width: progress + '%' }"></span>
            </span>
        </div>
        <div class="range-slider__valuewrap" style="flex-basis: 30%">
            <vue-numeric
                class="range-slider__value"
                v-model="rangeValueProxy"
                thousand-separator="."
                decimal-separator=","
                :precision="precision"
                :currency="getSuffix"
                currency-symbol-position="suffix"
                :read-only="readOnly"
                :disabled="disabled"
                @focus="inputHasFocus = true"
                @blur="fixStepValue"
                :min="minRange"
                :max="maxRange"
            />
            <span class="range-slider__value-label">{{ label }}</span>
        </div>
    </div>
</template>

<script>
import VueNumeric from 'vue-numeric';

export default {
    components: {
        VueNumeric,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        label: {
            type: String,
            required: true,
        },
        minRange: {
            type: Number,
            required: false,
            default: 0,
        },
        maxRange: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            required: false,
            default: 1,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        precision: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            rangeValueProxy: 0,
            inputHasFocus: false,
        };
    },
    computed: {
        progress() {
            const value = this.rangeValueProxy;
            const range = this.maxRange - this.minRange;
            let progress = ((value - this.minRange) / range) * 100;

            // Make sure progress doesn't exceed 100%
            if (progress > 100) {
                progress = 100;
            } else if (progress < 0) {
                progress = 0;
            }

            return progress;
        },
        getSuffix() {
            return this.rangeValueProxy === this.maxRange && !this.inputHasFocus ? '+' : '';
        },
    },
    methods: {
        fixStepValue() {
            this.inputHasFocus = false;
            // Value must be a valid step (e.g. 1000, 2000 … )
            let value = this.rangeValueProxy;
            if (value % this.step >= 1) {
                value = value - (value % this.step);
            }

            if (value < this.minRange) {
                value = this.minRange;
            }

            this.rangeValueProxy = value;
        },
        updateValue() {
            let value = this.checkValue(this.rangeValueProxy);
            this.rangeValueProxy = value; // Sync checked value back to proxy
            this.$emit('input', parseFloat(value));
        },
        checkValue(value) {
            // Value may not be higher than maxRange
            if (value > this.maxRange) {
                value = this.maxRange;
            }

            // Value may not be empty
            if (value === '') {
                value = this.minRange;
            }

            return value;
        },
    },
    mounted() {
        // Prefill proxy value with real value
        this.rangeValueProxy = this.value;
    },
    watch: {
        rangeValueProxy: {
            handler(value) {
                // Make sure range value never is < minRange or empty
                if (value < 0) {
                    this.rangeValueProxy = this.minRange;
                } else {
                    this.updateValue();
                }
            },
        },
        value: {
            handler(value) {
                this.rangeValueProxy = value;
            },
        },
    },
};
</script>
