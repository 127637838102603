<template>
    <transition name="component-fade">
        <div v-if="isActive" class="overlay-bg"></div>
    </transition>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;

            if (addRemoveClass) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
    },
    watch: {
        isActive(value) {
            if (value === true) {
                this.toggleBodyClass(true, 'overlay-is-open');
            } else {
                this.toggleBodyClass(false, 'overlay-is-open');
            }
        },
    },
};
</script>
