<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                {{ headline }}
            </h3>
        </div>
        <div class="question__content">
            <InfoBox type="border">
                <table class="table table--liquidity">
                    <tbody>
                        <tr>
                            <td>Ihre Liquidität:</td>
                            <td class="large-text align-right">
                                <b>{{ cashReserve }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Empfohlener Liquiditätspuffer:</td>
                            <td class="large-text align-right">
                                <b>{{ cashReserveRecommendation }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ cashReserveOrGapText }}
                            </td>
                            <td class="large-text align-right">
                                <b :class="[cashReserveIsPositive ? 'color-positive' : 'color-negative']">{{
                                    cashReserveGap
                                }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </InfoBox>
            <InfoBox>
                <p><b>Unser Tipp:</b> {{ recommendationText }}</p>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import Helpers from '@/helpers';

export default {
    mixins: [questionPropertiesMixin],
    computed: {
        cashReserve() {
            return Helpers.formatNumberAsEuro(this.getCashReserve());
        },
        cashReserveRecommendation() {
            return Helpers.formatNumberAsEuro(this.getcashReserveRecommendation());
        },
        cashReserveGap() {
            return (this.cashReserveIsPositive ? '+' : '') + Helpers.formatNumberAsEuro(this.calcCashReserveGap());
        },
        cashReserveIsPositive() {
            let isPositive = false;
            if (Math.sign(this.calcCashReserveGap()) >= 0) {
                isPositive = true;
            } else {
                isPositive = false;
            }
            this.updateQuestionState('cash_reserve_gap_is_positive', isPositive);
            return isPositive;
        },
        headline() {
            return this.cashReserveIsPositive
                ? 'Sie haben mehr als genug Liquidität!'
                : 'Sie sollten einen größeren Liquiditätspuffer aufbauen';
        },
        cashReserveOrGapText() {
            return this.cashReserveIsPositive
                ? 'Sie haben einen Liquiditätsüberschuss von:'
                : 'Sie haben eine Liquiditätslücke von:';
        },
        recommendationText() {
            return this.cashReserveIsPositive
                ? 'Sie sollten zumindest einen Teil des Überschusses renditeorientiert anlegen!'
                : 'Lassen Sie sich zu Ihrem Liquititätsbedarf beraten!';
        },
    },
    methods: {
        getCashReserve() {
            return this.getQuestionState('liquidity', 'Question2');
        },
        getcashReserveRecommendation() {
            return this.getQuestionState('liquidity_buffer', 'Question3');
        },
        calcCashReserveGap() {
            let cashReserveGap = this.getCashReserve() - this.getcashReserveRecommendation();
            this.updateQuestionState('cash_reserve_gap', cashReserveGap);
            return cashReserveGap;
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
};
</script>
