import { mapGetters } from 'vuex';

export const modulePropertiesMixin = {
    computed: {
        ...mapGetters(['getProductFieldCount', 'getFilledProductFieldCount']),
        productFields() {
            if (this.isSpecialModule) {
                return this.totalFilledProductFieldCount;
            } else {
                return this.module.productFields;
            }
        },
        modules() {
            return this.$route.name === 'module' ? 'appModules' : 'statusModules';
        },
        productFieldCount() {
            return this.getProductFieldCount(this.modules, this.module.id);
        },
        filledProductFieldCount() {
            return this.getFilledProductFieldCount(this.modules, this.module.id).filled;
        },
        dashedProductFieldCount() {
            return this.getFilledProductFieldCount(this.modules, this.module.id).dashed;
        },
        retiredProductFieldCount() {
            return this.getFilledProductFieldCount(this.modules, this.module.id).retired;
        },
        totalFilledProductFieldCount() {
            return this.filledProductFieldCount + this.dashedProductFieldCount;
        },
        dashedBarPercentage() {
            return (this.totalFilledProductFieldCount / this.productFieldCount) * 100 + '%';
        },
        filledBarPercentage() {
            return (this.filledProductFieldCount / this.productFieldCount) * 100 + '%';
        },
        moduleProgressLabel() {
            let label;
            const count = this.filledProductFieldCount;
            if (this.isSpecialModule) {
                let status = count > 0 ? 'available' : 'n-a';
                label = this.$t(`global.status-labels.${status}`);
            } else {
                let count = this.totalFilledProductFieldCount;
                label = this.$t(`global.status-labels.${count}`);
            }
            return label;
        },
        isSpecialModule() {
            return this.module.isSpecialModule;
        },
    },
};
