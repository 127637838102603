import { uniq } from 'lodash';
import defaultRecommendationJson from '@/data/defaultBankRecommendation.json';
import productPriorization from '@/data/productPriorization.js';
import { BANKSTATUS } from '@/helpers';
import InteractionInProductField from '@/utils/InteractionInProductField';
import api from '@/api';

const subtractTwoArrays = (arr1, arr2) => arr1.filter((el) => !arr2.includes(el));

const initialState = () => ({
    /** Products that are recommended by the bank */
    bankRecommendations: [],
    /** Products that are recommended by the questionnaire */
    questionnaireRecommendations: [],
    /** Products that are unrecommended by the questionnaire */
    questionnaireBans: [],
});

const state = initialState();

// Getter functions
const getters = {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * PRODUCT LEVEL
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    /**
     * Return ProductIds of Cloud or BankDefault recommendations
     * @return {string[]|*[]}
     */
    getBankRecommendations(state, getters, rootState, rootGetters) {
        // INFO: If the user runs threw a questionnaire and a bank recommendation is
        // not recommended by the questionnaire, the product should not be on the recommendations list
        const questionnaireBan = getters.getQuestionnaireBans;

        if (!rootGetters.isPrefilled) {
            // return default if *not* prefilled!
            return uniq(subtractTwoArrays(defaultRecommendationJson.recommendations, questionnaireBan));
        }
        return uniq(subtractTwoArrays(state.bankRecommendations, questionnaireBan));
    },
    /**
     * Return ProductIds of the recommendations from Questionnaires
     * @return {string[]|*[]}
     */
    getQuestionnaireRecommendations(state) {
        return uniq(state.questionnaireRecommendations);
    },
    /**
     * Return ProductIds of the "unrecommendations/bans" from Questionnaires
     * @return {string[]|*[]}
     */
    getQuestionnaireBans(state) {
        return uniq(state.questionnaireBans);
    },
    /**
     * This simple getter is the root of all calculations!
     *
     * Combine getBankRecommendations and getQuestionnaireRecommendations (remove duplicates)
     * @return {string[]|*[]}
     */
    getPromotedProducts(state, getters) {
        /**
         * Description:
         * Accounts should always be (if recommended) at the first place and the cards on the second place.
         * So we manipulate the order of them.
         */
        let productList = uniq([...getters.getBankRecommendations, ...getters.getQuestionnaireRecommendations]);

        productPriorization.forEach((product) => {
            if (productList.includes(product)) {
                const index = productList.indexOf(product);
                productList.splice(index, 1);
                productList.unshift(product);
            }
        });
        // INFO: Hardcoded exception for Leipzig "Versorgungslückenprinzip"
        if (productList.includes('Liquiditaet_GoldCard') && productList.includes('Liquiditaet_ClassicCard')) {
            // Do not recommend both Cards! GoldCard wins if both are recommended!
            productList = productList.filter((product) => product !== 'Liquiditaet_ClassicCard');
        }

        return productList;
    },
    /**
     * Return the ProductIds where doNotPromote is true
     * @return {string[]|*[]}
     */
    getHiddenPromotions(state, getters, rootState, rootGetters) {
        const hiddenPromotions = [];
        rootGetters.getAllProductsObjects.forEach((product) => {
            if (product.doNotPromote) {
                hiddenPromotions.push(product.id);
            }
        });

        return hiddenPromotions;
    },
    /**
     * Return ProductIds of products that the user marked in some way
     * @return {string[]|*[]}
     */
    getInteractedProducts(state, getters, rootState, rootGetters) {
        const interactedProducts = [];
        rootGetters.getAllProductsObjects.forEach((product) => {
            if (
                product.options.BANK ||
                product.options.EXTERNAL ||
                product.options.INTEREST ||
                product.options.RETIRED
            ) {
                interactedProducts.push(product.id);
            }
        });

        return interactedProducts;
    },
    /**
     * Return promoted (E3) Products, but also remove Products where an other Products
     * of the same ProductField was interacted (Interest, Bank ...)
     *
     * This should just be used as a helper for ProductFields
     *
     * @return {string[]|*[]}
     */
    getFilteredPromotedProducts(state, getters, rootState, rootGetters) {
        const promotedProducts = getters.getPromotedProducts;
        const filterOut = [];

        for (const promotedProduct of promotedProducts) {
            const productObject = rootGetters.getProductById(promotedProduct);
            const productFieldObject = rootGetters.getProductField(productObject.productFieldId);

            if (InteractionInProductField(productFieldObject)) {
                filterOut.push(promotedProduct);
            }
        }

        return subtractTwoArrays(promotedProducts, filterOut);
    },
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * PRODUCT FIELD LEVEL
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /**
     * Return promoted (E2) ProductFields
     * @return {string[]|*[]}
     */
    getPromotedProductFields(state, getters, rootState, rootGetters) {
        const noArrayIntersection = (arrA, arrB) => {
            return arrA.filter((x) => arrB.includes(x)).length === 0;
        };
        const interactedProducts = getters.getInteractedProducts;
        const missingPoints = getters.getNeededPointsToNextLevel;
        const promotedProductFields = [];

        if (missingPoints === 0) {
            // there are no recommendations
            return promotedProductFields;
        }

        /**
         * CAUTION: getFilteredPromotedProducts is used, not getPromotedProducts !!!
         * So we receive just productFields where no product inside was interacted with.
         */
        for (const promotedProduct of getters.getFilteredPromotedProducts) {
            const productObject = rootGetters.getProductById(promotedProduct);
            const productFieldObject = rootGetters.getProductField(productObject.productFieldId);
            const productsOfSameProductField = [];
            productFieldObject.products.forEach((product) => {
                // get productIds of all the products inside the current productField
                productsOfSameProductField.push(product.id);
            });
            if (
                !promotedProductFields.includes(productObject.productFieldId) &&
                noArrayIntersection(interactedProducts, productsOfSameProductField)
            ) {
                // 1) the productFieldId is not in the array of promotedProductFields &&
                // 2) the productsOfSameProductField should not be in the interactedProducts array!
                promotedProductFields.push(productObject.productFieldId);
            }
            if (promotedProductFields.length === missingPoints) {
                // if we reached the needed productFields that are needed to reach nextStatusLevel - stop
                return promotedProductFields;
            }
        }

        return promotedProductFields;
    },
    /**
     * Get ProductIds of products that the user marked in some way of givenProductFieldId
     */
    getInteractedProductsOfProductField: (state, getters, rootState, rootGetters) => (productFieldId) => {
        const interactedProducts = [];
        getters.getInteractedProducts.forEach((product) => {
            if (rootGetters.getProductById(product).productFieldId === productFieldId) {
                interactedProducts.push(product);
            }
        });

        return interactedProducts;
    },
    /**
     * CAUTION: This getter does * not * check if the user interacted!!!
     */
    getPromotedProductsOfProductField: (state, getters, rootState, rootGetters) => (productFieldId) => {
        const promotedProducts = [];
        let allRecommendations = [];

        if (rootGetters.isPrefilled) {
            allRecommendations = getters.getPromotedProducts;
        } else {
            allRecommendations = getters.getQuestionnaireRecommendations;
        }

        allRecommendations.forEach((product) => {
            if (rootGetters.getProductById(product).productFieldId === productFieldId) {
                promotedProducts.push(product);
            }
        });

        return promotedProducts;
    },
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * MODULE LEVEL
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /**
     * Return the promoted (E1) Module
     * @return {string} It returns the moduleId (e.g. 'absicherung') or empty string ''
     */
    getPromotedModule(state, getters, rootState, rootGetters) {
        let mostRecommendedModule = '';
        if (getters.getPromotedProductFields.length > 0) {
            // take the 'highest' productField recommendation and return the moduleId
            const mostRecommendedProductField = getters.getPromotedProductFields[0];
            const productFieldObject = rootGetters.getProductField(mostRecommendedProductField);
            mostRecommendedModule = productFieldObject.moduleId;
        }

        return mostRecommendedModule;
    },
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * OTHERS
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /**
     * Return amount of points that are missing to reach get statusLevel
     * @return {number}
     */
    getNeededPointsToNextLevel(state, getters, rootState, rootGetters) {
        const nextStatusName = rootGetters.getNextStatus;

        // reached last/best status
        if (!nextStatusName) {
            return 0;
        }

        const nextStatus = BANKSTATUS[nextStatusName.toUpperCase()];
        const currentPoints = rootGetters.bankstatusCount.filled.length;
        return nextStatus.CONDITION - currentPoints;
    },
};

// Actions
const actions = {
    /**
     * @param {ActionContext.state} state
     * @param {ActionContext.rootGetters} rootGetters
     * @param {Object} productApiResponse
     */
    initializeRecommendation({ commit, rootGetters }, productApiResponse) {
        if (!rootGetters.isPrefilled) {
            throw new Error('Do not use initializeRecommendation if *no* code is used!');
        }

        // INFO: hardcoded prefix
        const bankRecommendationPrefix = 'Finale_Empfehlung_';

        const bankRecommendations = [];

        for (const key in productApiResponse) {
            // check that there are recommendations, that are not null
            if (key.includes(bankRecommendationPrefix) && productApiResponse[key] !== null) {
                bankRecommendations.push(key);
            }
        }

        if (bankRecommendations.length === 0) {
            // stop if there are no recommendations!
            return;
        }

        const sortById = (a, b) => {
            const aArray = a.split('_');
            const aNumber = a[aArray.length - 1];
            const bArray = b.split('_');
            const bNumber = b[bArray.length - 1];

            if (aNumber > bNumber) {
                return 1;
            }
            if (aNumber < bNumber) {
                return -1;
            }

            return 0;
        };

        bankRecommendations.sort(sortById);

        const productRecommendations = [];
        bankRecommendations.forEach((key) => {
            // now where the order is save, we can collect the values/productIds
            productRecommendations.push(productApiResponse[key]);
        });

        commit('mutate', {
            property: 'bankRecommendations',
            value: productRecommendations,
        });
    },
    /**
     * INFO:
     * First delete the old productIds from this module in current QuestionnaireRecommendation
     * and than add the given ones!
     *
     * CAUTION: Just use this action after finishing a Questionnaire, not while initialization!
     *
     * @param {ActionContext.dispatch} dispatch
     * @param {ActionContext.commit} commit
     * @param {ActionContext.rootGetters} rootGetters
     * @param {string} moduleId
     * @param {string[]} recommendedProducts
     */
    addQuestionnaireProducts({ dispatch, commit, rootGetters }, { moduleId, recommendedProducts }) {
        const allProductsOfThisModule = rootGetters.getAllProductsObjects.filter(
            (product) => product.moduleId === moduleId.toLowerCase()
        );
        if (allProductsOfThisModule.length === 0) {
            throw new Error('No products found in' + moduleId);
        }

        const allProductIdsOfThisModule = [];
        allProductsOfThisModule.forEach((product) => allProductIdsOfThisModule.push(product.id));

        /**
         * Info: Cleanup "old" runs of the same Questionnaire
         */
        commit('removeFromQuestionnaireRecommendations', allProductIdsOfThisModule);
        commit('removeFromQuestionnaireBans', allProductIdsOfThisModule);

        const cloudState = {};

        /**
         * Disable "hide" on all products of this module
         * => it is needed to reset the hide state after an questionnaire is done
         */
        allProductIdsOfThisModule.forEach((productId) => {
            dispatch(
                'updateProductStatus',
                {
                    productId: productId,
                    option: 'HIDE',
                    payload: false,
                },
                { root: true }
            );
            cloudState[`CheckStrecke_${productId}`] = 0;
        });

        const unrecommendations = subtractTwoArrays(allProductIdsOfThisModule, recommendedProducts);

        recommendedProducts.forEach((productId) => {
            commit('addValue', {
                property: 'questionnaireRecommendations',
                value: productId,
            });
            cloudState[`CheckStrecke_${productId}`] = 1;
        });

        if (rootGetters.isPrefilled) {
            api.updateMultipleKeysInCloud(cloudState);
        }

        unrecommendations.forEach((productId) => {
            commit('addValue', {
                property: 'questionnaireBans',
                value: productId,
            });
        });
    },
};

// Mutations
const mutations = {
    /**
     * Generic mutation
     *
     * commit('mutate', {
     *   property: <propertyNameHere>,
     *   value: <valueGoesHere>
     * });
     *
     * @param {String} state
     * @param payload
     */
    mutate(state, payload) {
        if (!payload.hasOwnProperty('property') || !payload.hasOwnProperty('value')) {
            throw new Error("Invalid mutation! Payload need to have following keys 'property', 'value'");
        }
        state[payload.property] = payload.value;
    },
    /**
     * Generic mutation for push an item to an array
     *
     * commit('addValue', {
     *   property: <propertyNameHere>,
     *   value: <valueGoesHere>
     * });
     *
     * @param {String} state
     * @param payload
     */
    addValue(state, payload) {
        if (!payload.hasOwnProperty('property') || !payload.hasOwnProperty('value')) {
            throw new Error("Invalid mutation! Payload need to have following keys 'property', 'value'");
        }
        state[payload.property].push(payload.value);
    },
    /**
     * @param {String} state
     * @param {string[]|*[]} productIds
     */
    removeFromQuestionnaireRecommendations(state, productIds) {
        if (productIds.length === 0) {
            // stop if there are no ids
            return;
        }
        productIds.forEach((productId) => {
            if (state.questionnaireRecommendations.includes(productId)) {
                // if questionnaireRecommendations contains productId - remove it from array
                state.questionnaireRecommendations.splice(state.questionnaireRecommendations.indexOf(productId), 1);
            }
        });
    },
    /**
     * @param {String} state
     * @param {string[]|*[]} productIds
     */
    removeFromQuestionnaireBans(state, productIds) {
        if (productIds.length === 0) {
            // stop if there are no ids
            return;
        }
        productIds.forEach((productId) => {
            if (state.questionnaireBans.includes(productId)) {
                // if questionnaireBans contains productId - remove it from array
                state.questionnaireBans.splice(state.questionnaireRecommendations.indexOf(productId), 1);
            }
        });
    },
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
