import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Module from '@/views/Module.vue';
import Questionnaire from '@/views/Questionnaire.vue';
import Clipboard from '@/views/Clipboard.vue';
import store from '@/store';
import VueScrollTo from 'vue-scrollto';

Vue.use(VueRouter);
Vue.use(VueScrollTo, {
    duration: 400,
    cancelable: false,
    offset: -96, // Header
    force: false,
});

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { bodyClass: 'page-home' },
    },
    {
        path: '/merkzettel',
        name: 'clipboard',
        component: Clipboard,
        meta: { bodyClass: 'page-clipboard' },
    },
    {
        path: '/bedarfsfeld/:module',
        name: 'module',
        component: Module,
        meta: { bodyClass: 'page-module' },
    },
    {
        path: '/bedarfsfeld/:module/checkstrecke',
        name: 'questionnaire',
        component: Questionnaire,
        meta: { bodyClass: 'page-questionnaire' },
    },
    {
        // fallback route
        path: '*',
        redirect: '/',
    },
];

let router;

// Use different router modes for local/live env
if (process.env.VUE_APP_ENABLE_ABSTRACT_MODE === 'true') {
    router = new VueRouter({
        mode: 'abstract',
        routes,
    });

    // Push initial url for router abstract mode to work properly
    router.replace('/');
} else {
    router = new VueRouter({
        mode: 'history',
        routes,
    });
}

// Route Guards
router.beforeEach((to, from, next) => {
    if (from !== null) {
        // Close overlays & overlayBG, if open
        for (let [view, value] of Object.entries(store.getters.getViewState)) {
            if (value) {
                store.commit('UPDATE_VIEW', {
                    view: view,
                    payload: false,
                });
            }
        }

        // Disable shakeModule animation when leaving home
        if (store.getters.getAnimationState('shakeModule') && from.name === 'home' && to.name !== 'home') {
            store.commit('UPDATE_ANIMATION_STATE', {
                animation: 'shakeModule',
                payload: false,
            });
        }
    }

    // Jump to top of page when changing routes
    if (document.documentElement.scrollTop > 0) {
        const top = document.getElementById('site-header');
        VueScrollTo.scrollTo(top);
        next();
    } else {
        next();
    }
});

export default router;
