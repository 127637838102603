<template>
    <div class="site-header" id="site-header">
        <div class="site-header__top">
            <div class="site-header__inner wrapper">
                <div class="logo">
                    <img src="../../../public/assets/img/logo.svg" alt="Leipziger Volksbank" width="140" height="35" />
                </div>
                <transition name="fadepage" mode="out-in">
                    <ul class="site-header__meta menu" v-if="!$store.state.splashscreen">
                        <li class="menu__item">
                            <button class="icon-btn icon-btn--primary" @click="openContactForm">
                                <svg class="icon" width="28px" height="24px">
                                    <use xlink:href="#icon-email" />
                                </svg>
                                <span class="icon-btn__text">{{ $t('global.buttons.contact') }}</span>
                            </button>
                        </li>
                        <li class="menu__item menu__item--clipboard">
                            <router-link class="icon-btn icon-btn--primary" :to="{ name: 'clipboard' }">
                                <div
                                    class="clipboard-btn"
                                    :data-count="clipboardCount"
                                    :class="{ 'animate-bounce': showClipboardBounceAnimation }"
                                >
                                    <svg class="icon" width="24px" height="24px">
                                        <use xlink:href="#icon-clipboard" />
                                    </svg>
                                </div>
                                <span class="icon-btn__text">{{ $t('global.buttons.clipboard') }}</span>
                            </router-link>
                            <transition name="fade">
                                <div class="tooltip tooltip--clipboard" v-if="showClipboardTooltip">
                                    <button class="icon-btn close-btn" @click="closeClipboardTooltip">
                                        <svg width="12" height="12" class="icon">
                                            <use xlink:href="#icon-x"></use>
                                            &gt;
                                        </svg>
                                    </button>
                                    <div class="tooltip__inner">
                                        <p
                                            v-html="
                                                $t('clipboard.cta-popup.text', {
                                                    clipboard: $t('clipboard.name'),
                                                })
                                            "
                                        ></p>
                                        <router-link
                                            class="btn btn--cta btn--small btn--rounded"
                                            :to="{ name: 'clipboard' }"
                                            >{{ $t('clipboard.cta-popup.cta-btn') }}</router-link
                                        >
                                    </div>
                                </div>
                                <div class="tooltip tooltip--clipboard-reminder" v-if="showClipboardReminderTooltip">
                                    <div class="tooltip__inner">
                                        <p
                                            v-html="
                                                $t('clipboard.cta-popup.reminder-text', {
                                                    clipboard: $t('clipboard.name'),
                                                })
                                            "
                                        ></p>
                                        <router-link
                                            class="btn btn--cta btn--small btn--rounded"
                                            :to="{ name: 'clipboard' }"
                                            >{{ $t('clipboard.cta-popup.cta-btn') }}</router-link
                                        >
                                    </div>
                                </div>
                            </transition>
                            <transition name="fade">
                                <div
                                    v-if="showClipboardTooltip"
                                    @click="closeClipboardTooltip"
                                    class="tooltip-overlay"
                                />
                            </transition>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>
        <transition name="fadepage" mode="out-in">
            <div class="site-header__bottom" v-if="!$store.state.splashscreen">
                <div class="site-header__inner wrapper">
                    <div class="back-btn-wrap">
                        <BackButton
                            v-show="$route.name === 'clipboard'"
                            @click="$router.go(-1)"
                            :moduleName="module"
                            :type="type"
                        />
                    </div>
                    <transition name="fade">
                        <div
                            class="tooltip tooltip--simulation"
                            @click="closeSimulationTooltip"
                            v-if="showSimulationTooltip"
                        >
                            <div class="tooltip__inner">
                                <svg width="24px" height="24px" class="icon">
                                    <g fill="none" fill-rule="evenodd">
                                        <circle stroke="#D8D8D8" stroke-width="2" opacity=".9" cx="12" cy="12" r="11" />
                                        <g transform="translate(4 10)">
                                            <rect id="simulation-pill-3" x="12" width="4" height="4" rx="2" />
                                            <rect id="simulation-pill-2" x="6" width="4" height="4" rx="2" />
                                            <rect id="simulation-pill-1" x="0" width="4" height="4" rx="2" />
                                        </g>
                                    </g>
                                </svg>
                                <p>
                                    {{ $t('global.simulation.cta-popup') }}
                                </p>
                            </div>
                        </div>
                    </transition>
                    <span class="app-title">Kontofinder</span>
                    <div class="reset-btn-wrap" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default {
    components: { BackButton },
    props: {
        showScrolledModuleState: {
            type: Boolean,
        },
        isScrolled: {
            type: Boolean,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            getClipboardProducts: 'clipboard/getClipboardProducts',
            getModule: 'getModule',
            getViewState: 'getViewState',
            getAnimationState: 'getAnimationState',
        }),
        clipboardCount() {
            return this.getClipboardProducts.interest.length;
        },
        module() {
            let routeName = this.$route.name;
            let moduleName = '';

            if (routeName == 'module' || routeName == 'questionnaire') {
                let module = this.$route.params.module;
                moduleName = this.getModule(module).name;
            }

            return moduleName;
        },
        type() {
            let routeName = this.$route.name;
            let module = routeName.split('.');

            return module[0];
        },
        showClipboardBounceAnimation() {
            return this.getAnimationState('bounceClipboardIcon');
        },
        showClipboardTooltip() {
            return this.$store.state.views.clipboardTooltip;
        },
        showClipboardReminderTooltip() {
            return this.$store.state.views.clipboardReminderTooltip;
        },
        showSimulationTooltip() {
            return this.$store.state.views.simulationTooltip;
        },
    },
    watch: {
        $route(to, from) {
            if (from.name === 'clipboard' && to.name === 'home' && this.clipboardCount > 0) {
                this.$store.commit('UPDATE_VIEW', {
                    view: 'clipboardReminderTooltip',
                    payload: true,
                });
            }
        },
    },
    methods: {
        openContactForm() {
            // Todo (add tracking?): this.$matomo && this.$matomo.trackEvent('Modals', 'open', 'Kontaktformular');
            this.$store.commit('UPDATE_VIEW', {
                view: 'contactOverlay',
                payload: true,
            });
        },
        closeClipboardTooltip() {
            // Todo (add tracking?): this.$matomo && this.$matomo.trackEvent('Modals', 'close', 'clipboard-tooltip');
            this.$store.commit('UPDATE_VIEW', {
                view: 'clipboardTooltip',
                payload: false,
            });
        },
        closeSimulationTooltip() {
            // Todo (add tracking?): this.$matomo && this.$matomo.trackEvent('Modals', 'close', 'simulation-tooltip');
            this.$router.push({ name: 'home' });
        },
        resetApplicationState() {
            const self = this;
            const options = {
                animation: 'fade',
                html: true,
                okText: this.$t('global.reset-app-popup.reset-btn'),
                cancelText: this.$t('global.reset-app-popup.cancel-btn'),
            };

            let headline = `<h3 class='overlay__headline mb-1'>${this.$t(
                'global.reset-app-popup.headline'
            )}</h3><p>${this.$t('global.reset-app-popup.text')}</p>`;

            // Show confirmation dialog
            this.$dialog
                .confirm(headline, options)
                .then(function() {
                    // Reset application state
                    self.$store.dispatch('resetStoreState');
                    this.$matomo && this.$matomo.trackEvent('Click', 'Zuruecksetzten');
                })
                .catch(function() {
                    // Abort, do nothing :)
                });
        },
    },
};
</script>
