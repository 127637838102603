<template>
    <div class="checkbox-wrap">
        <div class="checkbox" :class="checkboxClass">
            <input type="checkbox" :id="id" v-model="checked" :value="val" :disabled="disabled" @change="onChange" />
            <label :for="id">
                <slot></slot>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        checkboxClass: {
            type: String,
        },
        value: {
            type: Boolean,
            required: true,
        },
        val: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            id: `checkbox-${this._uid}`,
            checkedProxy: false,
        };
    },
    computed: {
        checked: {
            get() {
                return this.value;
            },
            set(val) {
                this.checkedProxy = val;
            },
        },
    },
    methods: {
        onChange: function() {
            this.$emit('input', this.checkedProxy);
        },
    },
};
</script>
