<template>
    <transition name="component-fade">
        <section v-if="isActive" class="overlay questionnaire-results-overlay">
            <div class="overlay__inner">
                <header class="overlay__header">
                    <h3 class="overlay__headline">
                        <svg class="icon" width="28px" height="28px">
                            <use xlink:href="#icon-checkmark" />
                        </svg>
                        <span v-html="overlayHeadline"></span>
                    </h3>
                    <button class="icon-btn close-btn" @click="closeOverlay()">
                        <svg class="icon" width="16px" height="16px">
                            <use xlink:href="#icon-x" />
                            >
                        </svg>
                    </button>
                </header>
                <div class="overlay__content_not_responsive">
                    <p>{{ $t(`overlays.${type}.text`) }}</p>
                </div>
                <div class="overlay__footer">
                    <button class="btn btn--cta btn--rounded btn--icon" @click="closeOverlay()">
                        {{ $t(`overlays.${type}.cta-btn`) }}
                    </button>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'contact',
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        overlayHeadline() {
            return this.$t(`overlays.${this.type}.headline`, {
                questionnaire: this.$t(`modules.questionnaires.${this.questionnaire}.headline`),
            });
        },
        questionnaire() {
            return this.$route.params.module;
        },
    },
    methods: {
        closeOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'questionnaireResultsOverlay',
                payload: false,
            });
        },
        keyListeners: function(e) {
            // Listen to ESC key
            if (e.keyCode === 27) {
                this.closeOverlay();
            }
        },
    },
    watch: {
        isActive(newValue) {
            if (newValue === true) {
                document.addEventListener('keyup', this.keyListeners);
            } else {
                document.removeEventListener('keyup', this.keyListeners);
            }
        },
    },
};
</script>
