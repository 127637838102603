<template>
    <div class="product-field-icon" :class="computedClass">
        <div class="product-field-icon-wrapper">
            <app-icon :icon="`${module}-small`" :class="mode" v-if="mode !== ''" />
        </div>
    </div>
</template>

<script>
import AppIcon from '@/components/AppIcon';

export default {
    name: 'ProductFieldIcon',
    components: { AppIcon },
    props: {
        module: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
    },
    computed: {
        getIcon() {
            return `${module}-small`;
        },
        computedClass() {
            return `${this.module} ${this.mode === '' ? 'empty' : this.mode}`;
        },
    },
};
</script>
