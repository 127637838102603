// CAUTION!

/**
 * The order is important!
 * The first id will be placed to the top.
 */

const priorization = [
    'Liquiditaet_Premium',
    'Liquiditaet_Privat',
    'Liquiditaet_Direkt',
    'Liquiditaet_Jugendkonto',
    'Liquiditaet_BasicCard',
    'Liquiditaet_ClassicCard',
    'Liquiditaet_GoldCard',
];

// No mistake! .reverse is needed!
export default priorization.reverse();
