<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie viel kurzfristig verfügbares Geld haben Sie aktuell?
            </h3>
        </div>
        <div class="question__content">
            <custom-range-slider v-model="inputValue" :values="values" label="€" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import CustomRangeSlider from '@/components/inputs/CustomRangeSlider';

export default {
    components: { CustomRangeSlider },
    mixins: [questionPropertiesMixin],
    data() {
        return {
            // 0:                   0
            // 10:    1.000 -  10.000
            // 20:   10.000 -  50.000
            // 30:   50.000 - 100.000
            // 40:  100.000 - 250.000
            values: [
                0,
                // 0 to 10.000 [(10.000-0) / 10 = 1.000]
                1000,
                2000,
                3000,
                4000,
                5000,
                6000,
                7000,
                8000,
                9000,
                10000,
                // 10.000 to 50.000 [(50.000-10.000) / 10 = 4.000]
                14000,
                18000,
                22000,
                26000,
                30000,
                34000,
                38000,
                42000,
                46000,
                50000,
                // 50.000 to 100.000 [(100.000-50.000) / 10 = 5.000]
                55000,
                60000,
                65000,
                70000,
                75000,
                80000,
                85000,
                90000,
                95000,
                100000,
                // 100.000 to 250.000 [(250.000-100.000) / 10 = 15.000]
                115000,
                130000,
                145000,
                160000,
                175000,
                190000,
                205000,
                220000,
                235000,
                250000,
            ],
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('liquidity');
            },
            set(value) {
                this.updateQuestionState('liquidity', value);
            },
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
};
</script>
