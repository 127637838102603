<template>
    <transition name="component-fade">
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'FadeInOut',
};
</script>
