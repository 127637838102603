<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Möchten Sie diese renovieren oder eine weitere Immobilie finanzieren?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="optionsRenovation" v-model="inputValueRenovation" />
        </div>

        <div v-if="inputValueRenovation === 'yes'" class="question__header">
            <h3 class="question__headline">
                Wann möchten Sie Ihre Immobilie renovieren oder eine weitere Immobilie finanzieren?
            </h3>
        </div>
        <div v-if="inputValueRenovation === 'yes'" class="question__content">
            <RadioGroup :options="optionsRenovationTiming" v-model="inputValueRenovationTiming" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            optionsRenovation: {
                yes: {
                    icon: '',
                    label: 'Ja',
                },
                no: {
                    icon: '',
                    label: 'Nein',
                },
            },
            optionsRenovationTiming: {
                next_year: {
                    icon: '',
                    label: 'Im nächsten Jahr',
                },
                not_next_year: {
                    icon: '',
                    label: 'Nicht im nächsten Jahr',
                },
            },
        };
    },
    computed: {
        inputValueRenovation: {
            get() {
                return this.getQuestionState('renovation');
            },
            set(value) {
                this.isAnswered = value === 'no' || (value === 'yes' && this.inputValueRenovationTiming !== '');
                this.updateQuestionState('renovation', value);
            },
        },
        inputValueRenovationTiming: {
            get() {
                return this.getQuestionState('renovation_timing', 'Question3b');
            },
            set(value) {
                this.isAnswered = this.inputValueRenovation === 'yes' && value !== '';
                this.updateQuestionState('renovation_timing', value, 'Question3b');
            },
        },
    },
};
</script>
