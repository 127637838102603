<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie führen Sie Ihren Zahlungsverkehr (z.B. Überweisungen) aus?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="optionsPaymentType" v-model="inputValuePaymentType" />
        </div>

        <div v-if="inputValuePaymentType === 'online'" class="question__header">
            <h3 class="question__headline">
                Wie viele Buchungsvorgänge haben Sie pro Monat ungefähr auf Ihrem Kontoauszug?
            </h3>
        </div>
        <div v-if="inputValuePaymentType === 'online'" class="question__content">
            <RadioGroup :options="optionsPaymentAmount" v-model="inputValuePaymentAmount" />
        </div>
        <div class="align-center mq-smalldesk-up">
            <button class="text-btn align-center" @click="openTariffsOverlay">
                Preise und Leistungen
            </button>
        </div>
        <TariffsOverlay type="tariffs" :is-active="getViewState.tariffsOverlay" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import TariffsOverlay from '@/components/TariffsOverlay.vue';

export default {
    mixins: [questionPropertiesMixin],
    components: {
        TariffsOverlay,
    },
    data() {
        return {
            optionsPaymentType: {
                online: {
                    icon: '',
                    label: 'Überwiegend online',
                },
                local: {
                    icon: '',
                    label: 'Überwiegend in der Filiale',
                },
            },
            optionsPaymentAmount: {
                less_than_30: {
                    icon: '',
                    label: 'Weniger als 30',
                },
                more_than_30: {
                    icon: '',
                    label: '30 oder mehr',
                },
            },
        };
    },
    computed: {
        ...mapGetters(['getViewState']),
        inputValuePaymentType: {
            get() {
                return this.getQuestionState('value');
            },
            set(value) {
                if (value === 'local') {
                    this.inputValuePaymentAmount = '';
                }
                this.isAnswered = value === 'local';
                this.updateQuestionState('value', value);
            },
        },
        inputValuePaymentAmount: {
            get() {
                return this.getQuestionState('value', 'Question2b');
            },
            set(value) {
                this.isAnswered = value.length > 0;
                this.updateQuestionState('value', value, 'Question2b');
            },
        },
    },
    methods: {
        openTariffsOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'tariffsOverlay',
                payload: true,
            });
        },
    },
};
</script>
