/**
 * @param {Object} productFieldObject
 *
 * Return true if one of the products in this PF were marked in some way
 *
 * @return {boolean}
 */
export default function InteractionInProductField(productFieldObject) {
    if (typeof productFieldObject !== 'object') {
        throw new Error('Invalid Parameter');
    }

    for (const product of productFieldObject.products) {
        if (product.options.BANK || product.options.EXTERNAL || product.options.INTEREST || product.doNotPromote) {
            return true;
        }
    }

    return false;
}
