import axios from 'axios';

const cloudUrl = '/api/cloud.php';
const formUrl = '/api/form.php';

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        if (config.method.toLowerCase() !== 'get' && JSON.parse(window.sessionStorage.hausbankfinder).consultantMode) {
            throw new axios.Cancel(
                `${config.method} ${config.baseURL}${config.url} - canceled by the consultant mode.`
            );
        } else {
            return config;
        }
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export default {
    updateProductOptionInCloud(productId, option, payload) {
        // INFO: hardcoded
        const availableOptions = {
            BANK: '',
            INTEREST: 'Interesse_',
            EXTERNAL: 'Fremd_',
            HIDE: 'Irrelevant_',
            RECOMMEND: 'CheckStrecke_',
        };

        if (!Object.keys(availableOptions).includes(option)) {
            throw new Error(`Unknown option in updateProductOptionInCloud: "${option.toString()}"`);
        }

        return axiosInstance.post(cloudUrl, {
            data: { [availableOptions[option] + productId]: payload ? 1 : 0 },
            code: JSON.parse(window.sessionStorage.hausbankfinder).code,
        });
        // END: hardcoded
    },

    resetProducts(products) {
        // INFO: hardcoded
        const resetKeys = {
            INTEREST: 'Interesse_',
            EXTERNAL: 'Fremd_',
            HIDE: 'Irrelevant_',
            RECOMMEND: 'CheckStrecke_',
        };

        let data = {
            Alter: 0,
            Schon_Im_Ruhestand: 0,
            Abbezahlte_Immobilie: 0,
            Risikoprofil: 0,
            Genug_Erspartes: 0,
            Job: 0,
            Eigentum: 0,
        };
        // END: hardcoded

        for (let product of products) {
            for (let prefix in resetKeys) {
                data[resetKeys[prefix] + product.id] = 0;
            }
        }

        return axiosInstance.post(cloudUrl, {
            data,
            code: JSON.parse(window.sessionStorage.hausbankfinder).code,
        });
    },

    updateKeyInCloud(productId, payload) {
        return axiosInstance.post(cloudUrl, {
            data: { [productId]: payload ? 1 : 0 },
            code: JSON.parse(window.sessionStorage.hausbankfinder).code,
        });
    },

    updateMultipleKeysInCloud(payload) {
        return axiosInstance.post(cloudUrl, {
            data: payload,
            code: JSON.parse(window.sessionStorage.hausbankfinder).code,
        });
    },

    getCloudData(code) {
        return axiosInstance.get(cloudUrl, {
            params: {
                code,
            },
        });
    },

    sendContactForm(data) {
        return axiosInstance.post(formUrl, data);
    },
};
