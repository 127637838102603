<template>
    <transition name="component-fade">
        <section v-if="isActive" class="overlay contact-overlay">
            <div class="overlay__inner">
                <header class="overlay__header">
                    <h3 class="overlay__headline">
                        {{ $t(`overlays.${type}.headline`) }}
                    </h3>
                    <button class="icon-btn close-btn" @click="closeOverlay()">
                        <svg class="icon" width="16px" height="16px">
                            <use xlink:href="#icon-x" />
                        </svg>
                    </button>
                </header>
                <div class="overlay__content">
                    <p
                        v-if="type === 'clipboard' && oldProductInterests.length > 0 && !oldProductNotReseted"
                        class="overlay__callout"
                    >
                        {{ $t('overlays.clipboard.old-product-interests') }}
                    </p>
                    <p>{{ $t(`overlays.${type}.text`) }}</p>
                    <ValidationObserver
                        v-if="!formSuccess"
                        tag="form"
                        @submit.prevent="validateForm"
                        ref="observer"
                        class="form contact-form"
                    >
                        <div class="input-block">
                            <label class="label is-mandatory" for="input-firstname">{{
                                $t('form.labels.firstname')
                            }}</label>
                            <ValidationProvider rules="required" v-slot="{ classes }">
                                <input
                                    type="text"
                                    id="input-firstname"
                                    name="firstname"
                                    v-model="form.firstname"
                                    :class="classes"
                                />
                            </ValidationProvider>
                        </div>
                        <div class="input-block">
                            <label class="label is-mandatory" for="input-lastname">
                                {{ $t('form.labels.lastname') }}</label
                            >
                            <ValidationProvider rules="required" v-slot="{ classes }">
                                <input
                                    type="text"
                                    id="input-lastname"
                                    name="lastname"
                                    v-model="form.lastname"
                                    :class="classes"
                                />
                            </ValidationProvider>
                        </div>
                        <div class="input-block">
                            <label class="label" for="input-customer_id">{{ $t('form.labels.customer_id') }}</label>
                            <input type="text" id="input-customer_id" name="customer_id" v-model="form.customer_id" />
                        </div>
                        <div class="input-block">
                            <label class="label is-mandatory" for="input-contact">{{
                                $t('form.labels.contact-option')
                            }}</label>
                            <div class="flex-grid grid-pad grid-pad--narrow">
                                <div class="flex-grid__col c-6">
                                    <div class="select">
                                        <span class="select__box">
                                            <select id="input-contact" v-model="contactMethod" name="contact">
                                                <option value="phone">{{ $t('form.labels.phone') }}</option>
                                                <option value="email">{{ $t('form.labels.email') }}</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex-grid__col c-6">
                                    <ValidationProvider
                                        :rules="contactMethod == 'phone' ? 'required' : ''"
                                        v-slot="{ classes }"
                                    >
                                        <input
                                            v-if="contactMethod == 'phone'"
                                            type="text"
                                            inputmode="tel"
                                            id="input-phone"
                                            v-model="form.phone"
                                            :class="classes"
                                            :placeholder="$t('form.placeholders.phone')"
                                        />
                                    </ValidationProvider>
                                    <ValidationProvider
                                        :rules="contactMethod == 'email' ? 'required|email' : ''"
                                        v-slot="{ classes }"
                                    >
                                        <input
                                            v-if="contactMethod == 'email'"
                                            type="email"
                                            id="input-email"
                                            v-model="form.email"
                                            :class="classes"
                                            :placeholder="$t('form.placeholders.email')"
                                        />
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="input-block">
                            <Checkbox
                                val="appointment"
                                v-model="form.appointment"
                                checkbox-class="checkbox--radio checkbox--inline"
                            >
                                <span>{{ $t('form.labels.appointment') }}</span>
                            </Checkbox>

                            <div class="small-text mt-1 mb-1">
                                <span class="is-required color-red">* </span>
                                <span class="color-gray">{{ $t('form.required-text') }}</span>
                            </div>
                        </div>
                        <button :disabled="isDisabled" class="btn btn--cta btn--rounded btn--icon">
                            {{ $t(`overlays.${type}.submit-btn`) }}
                            <svg class="icon" width="16px" height="16px">
                                <use xlink:href="#icon-arrow-right" />
                            </svg>
                        </button>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import Checkbox from '@/components/Checkbox';
import { extend } from 'vee-validate';
import api from '@/api';
import { mapGetters } from 'vuex';
import riskLevels from '@/data/riskLevels';

extend('clientid', {
    validate(value, { min, max }) {
        return value.length >= min && value.length <= max;
    },
    params: ['min', 'max'],
    message: 'Die Kundennummer muss zwischen {min} und {max} Ziffern enthalten.',
});

function initialFormState() {
    return {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        customer_id: '',
        appointment: false,
    };
}

export default {
    components: {
        Checkbox,
    },
    props: {
        type: {
            type: String,
            default: 'contact',
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isDisabled: false,
            contactMethod: 'phone',
            form: initialFormState(),
        };
    },
    computed: {
        ...mapGetters({
            getViewState: 'getViewState',
            getProductById: 'getProductById',
            getClipboardProductSection: 'clipboard/getClipboardProductSection',
            getPersonalData: 'getPersonalData',
            getModule: 'getModule',
            oldProductInterests: 'clipboard/getOldClipboardProducts',
            oldProductNotReseted: 'clipboard/oldClipboardCloudReset',
            getFilteredPromotedProducts: 'recommendation/getFilteredPromotedProducts',
            getHiddenPromotions: 'recommendation/getHiddenPromotions',
            getAllProductsObjects: 'getAllProductsObjects',
            isPrefilled: 'isPrefilled',
            getCode: 'getCode',
        }),
        formSuccess() {
            return this.getViewState.formSuccess;
        },
        riskProfile() {
            return this.getPersonalData.riskProfile !== '' ? riskLevels[this.getPersonalData.riskProfile].type : '';
        },
    },
    methods: {
        closeOverlay() {
            this.$matomo && this.$matomo.trackEvent('Modals', 'close', 'Kontaktformular');
            this.$store.commit('UPDATE_VIEW', {
                view: `${this.type}Overlay`,
                payload: false,
            });
        },
        showSuccessMessage() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'formSuccessOverlay',
                payload: true,
            });
        },
        showErrorMessage() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'formErrorOverlay',
                payload: true,
            });
        },
        keyListeners: function(e) {
            // Listen to ESC key
            if (e.keyCode === 27) {
                this.closeOverlay();
            }
        },
        resetFormData() {
            Object.assign(this.form, initialFormState());
        },
        async validateForm() {
            const isValid = await this.$refs.observer.validate();

            if (isValid) {
                this.submitForm();
            }
        },
        submitForm() {
            const productNamesBank = [];
            const productNamesInterest = [];
            const productNamesExternal = [];
            const productNamesMissing = [];
            const productNamesHidden = [];

            const allProductsObjects = this.getAllProductsObjects;
            const interest = this.getClipboardProductSection('interest');
            const externalTmp = this.getClipboardProductSection('external');
            const missing = this.getFilteredPromotedProducts;
            const hidden = this.getHiddenPromotions;

            allProductsObjects.forEach((product) => {
                if (product.options.BANK) {
                    productNamesBank.push(product.id);
                }
            });

            // INFO Hardcoded exception for 'Vorsorge' and the 'Bereits im Ruhestand feature';
            const externalProducts = [];
            externalTmp.forEach((product) => {
                externalProducts.push(this.getProductById(product));
            });
            const external = externalProducts.filter((product) => {
                // this line is copied from Clipboard.vue => 'clipboardProductsExternal()';
                return !(product.moduleId === 'vorsorge' && !product.excludeFromRetirement);
            });
            // END Hardcoded

            const getProductName = (p, arr) => {
                const product = this.getProductById(p);
                const module = this.getModule(product.moduleId);
                arr.push(module.name + ' – ' + product.description[0]);
            };

            const getProductNameExternal = (p, arr) => {
                const module = this.getModule(p.moduleId);
                arr.push(module.name + ' – ' + p.description[0]);
            };

            interest.map((p) => getProductName(p, productNamesInterest));
            external.map((p) => getProductNameExternal(p, productNamesExternal));
            missing.map((p) => getProductName(p, productNamesMissing));
            hidden.map((p) => getProductName(p, productNamesHidden));

            let personalData = [];
            if (this.getPersonalData.isRetired) {
                personalData.push(this.$t('clipboard.text.retirement'));
            }
            if (this.getPersonalData.sufficientFunds) {
                personalData.push(this.$t('clipboard.text.sufficient-funds'));
            }
            if (this.getPersonalData.paidOffProperty) {
                personalData.push(this.$t('clipboard.text.paid-off-property'));
            }
            if (this.getPersonalData.riskProfile !== '') {
                personalData.push(
                    this.$t('clipboard.text.risk-profile', {
                        profile: `"${this.riskProfile}"`,
                    })
                );
            }

            let data = {
                code: this.isPrefilled ? this.getCode : null,
                contactData: {
                    email: this.contactMethod === 'email' ? this.form.email : '',
                    phone: this.contactMethod === 'phone' ? this.form.phone : '',
                    firstname: this.form.firstname,
                    lastname: this.form.lastname,
                    customerId: this.form.customer_id,
                    appointment: this.form.appointment,
                },
                clipboardData: {
                    bank: productNamesBank,
                    interest: productNamesInterest,
                    external: productNamesExternal,
                    missing: productNamesMissing,
                    hidden: productNamesHidden,
                    other: personalData,
                },
            };

            this.isDisabled = true;
            api.sendContactForm(data)
                .then(() => {
                    this.$matomo && this.$matomo.trackEvent('Click', 'Kontaktformular absenden');
                    this.closeOverlay();
                    this.showSuccessMessage();
                    this.resetFormData();
                })
                .catch(() => {
                    this.closeOverlay();
                    this.showErrorMessage();
                })
                .finally(() => {
                    this.isDisabled = false;
                });
        },
    },
    watch: {
        isActive(newValue) {
            if (newValue === true) {
                document.addEventListener('keyup', this.keyListeners);
            } else {
                document.removeEventListener('keyup', this.keyListeners);
            }
        },
    },
};
</script>
