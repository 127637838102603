<template>
    <div class="content" :class="moduleColorClass">
        <div class="wrapper wrapper--narrow">
            <div class="questionnaire">
                <div class="questionnaire__header">
                    <h2 class="questionnaire__headline">
                        {{ $t(`modules.questionnaires.${module.id}.headline`) }}
                    </h2>
                </div>
                <QuestionContainer
                    :module="module"
                    :is="module.id"
                    :transitionName="transitionName"
                    @questionWasAnswered="onAnsweredQuestion"
                />

                <div
                    class="questionnaire__footer"
                    v-observe-visibility="{
                        callback: ctaButtonVisibilityChanged,
                        intersection: {
                            rootMargin: '-88px',
                        },
                    }"
                >
                    <QuestionnaireProgress
                        ref="progress"
                        :module="module"
                        @questionnaireFinished="handleQuestionnaireCompletion"
                    />
                </div>
            </div>
        </div>
        <div class="ctabar" :class="{ 'is-active': showCtabar }">
            <div class="wrapper wrapper--narrow">
                <QuestionnaireProgress :module="module" @questionnaireFinished="handleQuestionnaireCompletion" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QuestionnaireProgress from '@/components/QuestionnaireProgress';
import * as QuestionContainer from '@/components/questionnaire/questionContainers';

export default {
    components: {
        QuestionnaireProgress,
        ...QuestionContainer,
    },
    data() {
        return {
            module: {},
            isQuestionnaireFinished: false,
            showCtabar: false,
        };
    },
    created() {
        if (require('@/data/excludeModuleQuestionnaire.json').exclude.includes(this.$route.params.module)) {
            this.$router.push({ name: 'home' });
        }
    },
    computed: {
        ...mapGetters(['getModule', 'getQuestionObject', 'getNextQuestionId']),
        moduleColorClass() {
            return 'bg-' + this.module.color;
        },
        transitionName() {
            let transitionName = 'component-slide-horizontal';
            if (this.$store.getters.getSlideTransitionState) {
                transitionName = 'component-slide-horizontal-reverse';
            }
            return transitionName;
        },
    },
    methods: {
        ctaButtonVisibilityChanged(isVisible) {
            this.showCtabar = !isVisible;
            return this.showCtabar;
        },
        /**
         * This function triggers redirect to next question IF this question has autoRedirect enabled
         * @param questionId
         */
        onAnsweredQuestion(questionId) {
            const question = this.getQuestionObject(this.module.id, questionId);
            let nextQuestion = this.getNextQuestionId(this.module.id);
            if (question.autoRedirect === true && nextQuestion !== undefined) {
                this.$refs.progress.handleNextStep();
            }
        },
        populateModule() {
            let module = this.$route.params.module;
            this.module = this.getModule(module);
        },
        handleQuestionnaireCompletion() {
            this.$matomo && this.$matomo.trackEvent('Questionnaires', 'finished', this.module.id);

            this.isQuestionnaireFinished = true;

            // Trigger Questionnaire validation process
            this.$store.dispatch('validateQuestionnaire', this.module.id);

            // Jump back to module
            this.$router.push({
                name: 'module',
                params: {
                    module: this.module.id,
                },
            });

            // Mark questionnaire as completed
            this.$store.commit('MARK_QUESTIONNAIRE_AS_COMPLETED', {
                moduleId: this.module.id,
                payload: true,
            });

            // Show questionnaire results overlay
            this.$store.commit('UPDATE_VIEW', {
                view: 'questionnaireResultsOverlay',
                payload: true,
            });
        },
    },
    beforeMount() {
        this.populateModule();
    },
    beforeRouteLeave(to, from, next) {
        const questionnaireName = this.$t(`modules.questionnaires.${this.module.id}.headline`);
        const prompt = this.$t('modules.questionnaires.leave-questionnaire-popup.prompt', {
            questionnaire: questionnaireName,
        });

        const options = {
            animation: 'fade',
            customClass: 'dialog-route-leave',
            okText: this.$t('modules.questionnaires.leave-questionnaire-popup.delete-btn', {
                questionnaire: questionnaireName,
            }),
            cancelText: this.$t('modules.questionnaires.leave-questionnaire-popup.cancel-btn', {
                questionnaire: questionnaireName,
            }),
        };

        // Show confirmation dialog before leaving questionnaire except for when questionnaire has just been finished
        if (
            this.isQuestionnaireFinished ||
            !this.$store.getters.areRouteGuardsEnabled ||
            require('@/data/excludeModuleQuestionnaire.json').exclude.includes(this.$route.params.module)
        ) {
            // Ignore and continue to requested route if questionnaire has been finished
            next();
        } else {
            // Show route leave confirmation dialog
            this.$dialog
                .confirm(prompt, options)
                .then(() => {
                    this.$matomo && this.$matomo.trackEvent('Questionnaires', 'cancel', this.module.id);
                    // Leave route
                    next();
                })
                .catch(() => {
                    // Abort, do nothing :)
                    next(false);
                });
        }
    },
};
</script>
