<template>
    <div class="range-slider risk-slider">
        <div class="range-slider__labels">
            <span>Kein Risiko &<br />Keine Rendite</span>
            <span>Hohes Risiko &<br />Hohe Rendite</span>
        </div>
        <div class="slider-wrap">
            <input class="slider" type="range" :min="minRange" :max="maxRange" ref="slider" v-model="rangeValueProxy" />
            <div class="range-slider__dots">
                <span class="range-slider__dot" v-for="n in 6" :key="n"></span>
            </div>
            <span class="range-slider__track">
                <span class="range-slider__progress" v-bind:style="{ width: progress + '%' }"></span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            rangeValueProxy: 0,
            minRange: 0,
            maxRange: 5,
        };
    },
    computed: {
        progress() {
            const value = this.rangeValueProxy;
            const range = this.maxRange - this.minRange;
            let progress = ((value - this.minRange) / range) * 100;

            // Make sure progress doesn't exceed 100%
            if (progress > 100) {
                progress = 100;
            }

            return progress;
        },
    },
    methods: {
        updateValue() {
            this.$emit('input', parseInt(this.rangeValueProxy));
        },
    },
    mounted() {
        // Prefill proxy value with real value
        this.rangeValueProxy = this.value;
    },
    watch: {
        rangeValueProxy: {
            handler() {
                this.updateValue();
            },
        },
    },
};
</script>
