<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Mit wie vielen Personen verreisen Sie?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="optionsTravelPersons" v-model="inputValueTravelPersons" />
        </div>
        <div v-if="inputValueTravelPersons" class="question__header">
            <h3 class="question__headline">
                Wie hoch ist Ihr jährliches Reisebudget?
            </h3>
        </div>
        <div v-if="inputValueTravelPersons" class="question__content">
            <CustomRangeSlider v-model="inputValueTravelBudget" :values="optionsTravelBudget" label="€" />

            <InfoBox>
                <p>
                    Mit der Kreditkarte GoldCard sparen Sie
                    <b class="color-cta">{{ goldCardBenefits }}</b> im Jahr für Ihre Reisen! <br />
                </p>
                <p>
                    Mit der GoldCard profitieren Sie von zahlreichen Reisevorteilen wie einem Rabatt auf Reisen,
                    Versicherungsleistungen, kostenfreiem Auslandseinsatz und 12 kostenlosen Auslandsabhebungen pro
                    Jahr.
                </p>
            </InfoBox>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import goldCardBenefitsData from '@/data/cardBenefitData';
import Helpers from '@/helpers';
import CustomRangeSlider from '@/components/inputs/CustomRangeSlider';

export default {
    components: { CustomRangeSlider },
    mixins: [questionPropertiesMixin],
    data() {
        return {
            optionsTravelPersons: {
                single: {
                    icon: '',
                    label: 'Allein',
                },
                family: {
                    icon: '',
                    label: 'Mit Partner/Familie',
                },
            },
            optionsTravelBudget: [
                0,
                // 0 - 1.000 (100)
                100,
                200,
                300,
                400,
                500,
                600,
                700,
                800,
                900,
                1000,
                // 1.000 - 4.000 (300)
                1300,
                1600,
                1900,
                2200,
                2500,
                2800,
                3100,
                3400,
                3700,
                4000,
                // 4.000 - 10.000 (600)
                4000,
                4600,
                5200,
                5800,
                6400,
                7000,
                7600,
                8200,
                8800,
                9400,
                10000,
            ],
        };
    },
    computed: {
        inputValueTravelPersons: {
            get() {
                return this.getQuestionState('value');
            },
            set(value) {
                this.isAnswered = this.inputValueTravelBudget !== '' && value !== '';
                this.updateQuestionState('value', value);
            },
        },
        inputValueTravelBudget: {
            get() {
                return this.getQuestionState('value', 'Question4b');
            },
            set(value) {
                this.isAnswered = this.inputValueTravelPersons !== '' && value !== '';
                this.updateQuestionState('value', value, 'Question4b');
            },
        },
        goldCardBenefits() {
            let totalBenefit = 0;
            let travelBudget = Math.round(this.inputValueTravelBudget / 100) * 100;
            // single traveller
            if (this.inputValueTravelPersons === 'single') {
                let benefit_budget_single =
                    travelBudget * goldCardBenefitsData.travel_cancellation_insurance.single +
                    travelBudget * goldCardBenefitsData.travel_booking_refund;

                let benefit_single =
                    goldCardBenefitsData.international_coverletter_insurance.single +
                    goldCardBenefitsData.travel_service_insurance.single;

                let benefit_single_up_to_64 =
                    benefit_single + goldCardBenefitsData.travel_health_insurance.single_up_to_64;

                let benefit_single_over_65 =
                    benefit_single + goldCardBenefitsData.travel_health_insurance.single_over_65;

                // if no travel budget
                if (this.inputValueTravelBudget === 0) {
                    if (this.getPrefillableQuestions.age < 65) {
                        totalBenefit = benefit_single_up_to_64;
                    } else {
                        totalBenefit = benefit_single_over_65;
                    }

                    // if travel budget
                } else if (this.inputValueTravelBudget > 0) {
                    if (this.getPrefillableQuestions.age < 65) {
                        totalBenefit = benefit_single_up_to_64 + benefit_budget_single;
                    } else {
                        totalBenefit = benefit_single_over_65 + benefit_budget_single;
                    }
                }

                // family traveller
            } else if (this.inputValueTravelPersons === 'family') {
                let benefit_budget_family =
                    travelBudget * goldCardBenefitsData.travel_cancellation_insurance.family +
                    travelBudget * goldCardBenefitsData.travel_booking_refund;

                let benefit_family =
                    goldCardBenefitsData.international_coverletter_insurance.family +
                    goldCardBenefitsData.travel_service_insurance.family +
                    goldCardBenefitsData.travel_health_insurance.family;

                // if no travel budget
                if (this.inputValueTravelBudget === 0) {
                    totalBenefit = benefit_family;
                    // if travel budget
                } else if (this.inputValueTravelBudget > 0) {
                    totalBenefit = benefit_family + benefit_budget_family;
                }
            }

            return Helpers.formatNumberAsEuro(totalBenefit);
        },
    },
};
</script>
