<template>
    <div class="range-slider">
        <div class="slider-wrap" style="flex-basis: 70%">
            <input
                class="slider"
                type="range"
                min="0"
                :max="values.length - 1"
                step="1"
                ref="slider"
                v-model="localStep"
            />
            <span class="range-slider__track">
                <span class="range-slider__progress" v-bind:style="{ width: progress + '%' }"></span>
            </span>
        </div>
        <div class="range-slider__valuewrap" style="flex-basis: 30%">
            <vue-numeric
                class="range-slider__value"
                v-model="localInput"
                thousand-separator="."
                :currency="getSuffix"
                currency-symbol-position="suffix"
                :read-only="readOnly"
                :disabled="disabled"
                @focus="inputHasFocus = true"
                @blur="blurInput"
            />
            <span class="range-slider__value-label">{{ label }}</span>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import VueNumeric from 'vue-numeric';

/**
 * INFO:
 * The given values are mapped! The given values are not "really" used in the input-slider.
 * This is the reason why :max="values.length-1", because the INDEX of the value is tracked in the slider!
 */

export default {
    components: {
        VueNumeric,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localStep: 0,
            localInput: 0,
            correctValue: 0,
            inputHasFocus: false,
        };
    },
    watch: {
        value: {
            handler(value) {
                this.correctValue = value;
                this.localInput = value;
                this.localStep = this.values.indexOf(value);
            },
            immediate: true,
        },
        localStep(currentStep) {
            // take the correct value from array and set it
            const mappedValue = this.values[currentStep];
            this.correctValue = mappedValue;
            this.localInput = mappedValue;
        },
        localInput(currentInput) {
            if (this.values.includes(parseFloat(currentInput))) {
                this.localStep = this.values.indexOf(parseFloat(currentInput));
            }
        },
        correctValue(currentValue) {
            this.$emit('input', currentValue);
        },
    },
    methods: {
        getNearestValue(needle) {
            const numbers = cloneDeep(this.values);

            numbers.sort((a, b) => {
                return Math.abs(needle - a) - Math.abs(needle - b);
            });

            return numbers[0];
        },
        blurInput() {
            this.inputHasFocus = false;
            if (!this.values.includes(parseFloat(this.localInput))) {
                this.localInput = this.getNearestValue(parseFloat(this.localInput));
            }
        },
    },
    computed: {
        progress() {
            return (this.localStep * 100) / (this.values.length - 1);
        },
        getSuffix() {
            return this.correctValue === this.values[this.values.length - 1] && !this.inputHasFocus ? '+' : '';
        },
    },
};
</script>
