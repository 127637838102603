/**
 * Automatically imports all the storeModules and exports as a single module object
 */
const requireModule = require.context('.', false, /\.store\.js$/);
const storeModules = {};

requireModule.keys().forEach((filename) => {
    // create the module name from fileName
    // remove the store.js extension and capitalize
    const moduleName = filename.replace(/(\.\/|\.store\.js)/g, '').replace(/^\w/, (c) => c.toLowerCase());

    storeModules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default storeModules;
