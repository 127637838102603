import doesNotCountFull from '@/data/doesNotCountFull.json';

// We are using constants to decouple the app logic from the actual values.
// Hardcoding specific values would increase the number of places where you would need to adjust the specific value in case you would need to rename it

export const PRODUCT_FIELD_STATUS = {
    EMPTY: '',
    DASHED: 'dashed',
    FILLED: 'filled',
    RETIRED: 'retired',
};

export const PRODUCT_FIELD_SUPPLY_LEVEL = {
    EMPTY: '',
    BAD: 'bad',
    IMPROVABLE: 'improvable',
    GOOD: 'good',
};

export const BANKSTATUS = {
    CLASSIC: {
        NAME: 'classic',
        CONDITION: 1,
    },
    COMFORT: {
        NAME: 'comfort',
        CONDITION: 6,
    },
    SILVER: {
        NAME: 'silver',
        CONDITION: 9,
    },
    GOLD: {
        NAME: 'gold',
        CONDITION: 12,
    },
};

export default {
    getProductFieldById(modules, productFieldId) {
        for (const module of modules) {
            for (const productField of module.productFields) {
                if (productField.id === productFieldId) {
                    return productField;
                }
            }
        }
        return null;
    },
    getProductFieldStatus(productField) {
        const interestOrBank = productField.products.some(
            (product) => product.options.INTEREST || product.options.BANK
        );

        if (interestOrBank) {
            // check that not just exceptions are marked!
            const exceptions = doesNotCountFull.exclude;

            if (exceptions[productField.id]) {
                const interestedProducts = productField.products.filter(
                    (product) => product.options.BANK || product.options.INTEREST
                );

                for (const product of interestedProducts) {
                    /**
                     * Info: if one product that was was marked is not in the exception list,
                     * the productField will count "full/filled", else it count "half/dashed"
                     */
                    if (!exceptions[productField.id].includes(product.id)) {
                        return PRODUCT_FIELD_STATUS.FILLED;
                    }
                }

                return PRODUCT_FIELD_STATUS.DASHED;
            }

            return PRODUCT_FIELD_STATUS.FILLED;
        }

        if (productField.products.some((product) => product.options.EXTERNAL)) {
            return PRODUCT_FIELD_STATUS.DASHED;
        }

        if (productField.products.some((product) => product.options.RETIRED)) {
            return PRODUCT_FIELD_STATUS.RETIRED;
        }

        return PRODUCT_FIELD_STATUS.EMPTY;
    },
    getProductById(modules, productId) {
        for (const module of modules) {
            for (const productField of module.productFields) {
                for (const product of productField.products) {
                    if (product.id === productId) {
                        return product;
                    }
                }
            }
        }
        throw new Error(`No product found for ID ${productId}`);
    },

    getBreakpoint() {
        const app = document.getElementById('app');
        const breakpoint = window.getComputedStyle(app, ':before').content.replace(/"/g, '');

        return breakpoint;
    },

    formatNumberAsEuro(number, cents = true) {
        let options = {
            style: 'currency',
            currency: 'EUR',
        };
        if (cents === false) {
            options['minimumFractionDigits'] = 0;
            options['maximumFractionDigits'] = 0;
        }
        const formatter = new Intl.NumberFormat('de-De', options);
        return formatter.format(number);
    },

    // 4.8 => 0.048
    formatNumberAsPercent(number, decimals = 3) {
        return parseFloat(parseFloat(number / 100).toFixed(decimals));
    },

    // 0.048 => 4.8
    formatPercentAsNumber(percent, decimals = 1) {
        return parseFloat(parseFloat(percent * 100).toFixed(decimals));
    },
};

export class Recommender {
    constructor(productRecommendationConditions) {
        this.productRecommendationConditions = productRecommendationConditions;
    }

    answerMatchesExpectation(answer, expectation, allAnswers) {
        let expectedValue = expectation.value;

        // If expectation is not a function, check for equality
        const comparator = expectedValue instanceof Function ? expectedValue : (v) => v === expectedValue;

        return comparator(answer[expectation.key], allAnswers);
    }

    shouldRecommendProduct(answeredQuestions, product) {
        const conditions = this.productRecommendationConditions[product.id] || [];

        return conditions.some((expectedAnswers) => {
            return Object.entries(expectedAnswers).every(([key, value]) => {
                return this.answerMatchesExpectation(answeredQuestions[key], value, answeredQuestions);
            });
        });
    }

    getRecommendedProducts({ answers, products }) {
        return products.filter((p) => this.shouldRecommendProduct(answers, p)).map((p) => p.id);
    }
}
