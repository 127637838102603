<template>
    <div class="productfield" :id="productField.id" v-if="products.length > 0" :class="productFieldStatusClass">
        <span v-if="isPromoted" class="promoted-label">{{ $t('productfields.recommendation-label') }}</span>
        <div class="productfield__header" @click="toggleProductList()">
            <product-field-icon :module="productField.moduleId" :mode="getProductFieldStatus(productField.id)" />
            <div class="productfield__headlines">
                <h3 class="productfield__headline">
                    {{ productField.headline }}
                </h3>
                <h4 class="productfield__subline">{{ productField.subline }}</h4>
            </div>
            <span class="productfield__toggle-btn productfield-toggle-btn">
                <svg class="icon" width="10px" height="7px">
                    <use xlink:href="#icon-arrow-down" />
                </svg>
            </span>
            <span
                v-if="productFieldSupplyLevel.length > 0"
                class="supply-level"
                :class="`supply-level--${productFieldSupplyLevel}`"
            >
                <app-icon :icon="supplyLevelIcon" size-px="14px" custom-class="icon" />
                {{ $t(`productfields.supply-level.${productFieldSupplyLevel}`) }}</span
            >
        </div>
        <slide-up-down :active="isOpen" :duration="slideToggleDuration" @close-end="productFieldProducts" hidden="true">
            <div class="productfield__content">
                <Product v-for="(product, index) in products" :help="index" :product="product" :key="index" />
                <slide-up-down :active="innerOpen" :duration="slideToggleDuration" hidden="true">
                    <Product
                        v-for="(product, index) in collapseProducts"
                        :help="index"
                        :product="product"
                        :key="index"
                    />
                </slide-up-down>
                <button
                    v-if="collapseProducts.length > 0"
                    @click="innerOpen = !innerOpen"
                    class="btn"
                    :class="moduleId"
                >
                    {{ innerOpen ? 'Verbergen' : 'Alle Anzeigen' }}
                </button>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import SlideUpDown from 'vue-slide-up-down';
import VisibilityDependency from '@/data/visibilityDependency.json';
import ExcludeStandalone from '@/data/excludeProductStandalone.json';
import ExcludePrefilled from '@/data/excludeProductPrefilled.json';
import ExcludeOpen from '@/data/excludeProductOpen.json';
import Product from '@/components/product/Product.vue';
import { PRODUCT_FIELD_STATUS, PRODUCT_FIELD_SUPPLY_LEVEL } from '@/helpers';
import ProductFieldIcon from '@/components/product/ProductFieldIcon';
import AppIcon from '@/components/AppIcon';

export default {
    components: {
        AppIcon,
        ProductFieldIcon,
        SlideUpDown,
        Product,
    },
    props: {
        productField: {
            type: Object,
            required: true,
        },
        showProductList: {
            type: Boolean,
            required: true,
        },
        moduleId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
            innerOpen: false,
            products: [],
            collapseProducts: [],
        };
    },
    created() {
        this.productFieldProducts();
    },
    computed: {
        ...mapGetters({
            isPrefilled: 'isPrefilled',
            isStandalone: 'isStandalone',
            isQuestionnaireCompleted: 'isQuestionnaireCompleted',
            getProductById: 'getProductById',
            getProductFieldStatus: 'getProductFieldStatus',
            getCloudData: 'getCloudData',
            promotedProductFields: 'recommendation/getPromotedProductFields',
            alreadyInteractedProducts: 'recommendation/getInteractedProducts',
            getInteractedProductsOfProductField: 'recommendation/getInteractedProductsOfProductField',
            getPromotedProductsOfProductField: 'recommendation/getPromotedProductsOfProductField',
            getHiddenPromotions: 'recommendation/getHiddenPromotions',
            getPromotedProducts: 'recommendation/getPromotedProducts',
        }),
        isPromoted() {
            return this.promotedProductFields.includes(this.productField.id);
        },
        /**
         * @typedef {"bad" | "improvable" | "good" | ""} SupplyLevels
         *
         * @return {SupplyLevels}
         */
        productFieldSupplyLevel() {
            if (!this.isQuestionnaireCompleted(this.productField.moduleId)) {
                // Do not indicate, if questionnaire is not completed!
                return '';
            }

            // the store action does * not * check for interaction or "hide"
            const promotedProducts = this.getPromotedProductsOfProductField(this.productField.id);
            if (promotedProducts.length === 0) {
                // There are no products to promote for this productField
                return '';
            }

            const removeFromArray = (baseArr, removeArray) => {
                let newArray = cloneDeep(baseArr);
                removeArray.forEach((entry) => {
                    if (newArray.includes(entry)) {
                        const index = newArray.indexOf(entry);
                        newArray.splice(index, 1);
                    }
                });
                return newArray;
            };

            const interactedProducts = this.getInteractedProductsOfProductField(this.productField.id);
            const hiddenPromotions = this.getHiddenPromotions;

            // first: remove all interactedProducts
            const clean1 = removeFromArray(promotedProducts, interactedProducts);
            // second: remove all "hiddenPromotions"
            const clean2 = removeFromArray(clean1, hiddenPromotions);

            if (clean2.length === 0) {
                return 'good';
            }

            if (interactedProducts.length > 0) {
                return 'improvable';
            }

            return 'bad';
        },
        productFieldStatusClass() {
            return {
                'is-dashed': this.getProductFieldStatus(this.productField.id) === PRODUCT_FIELD_STATUS.DASHED,
                'is-filled': this.getProductFieldStatus(this.productField.id) === PRODUCT_FIELD_STATUS.FILLED,
                'is-open': this.isOpen,
                'is-promoted': this.isPromoted,
            };
        },
        supplyLevelIcon() {
            const icons = {
                BAD: 'na',
                IMPROVABLE: 'alert',
                GOOD: 'checkmark',
            };

            // Find supply-level lookup key by current supply-level value
            const supplyLevelKey = Object.keys(PRODUCT_FIELD_SUPPLY_LEVEL)[
                Object.values(PRODUCT_FIELD_SUPPLY_LEVEL).indexOf(this.productFieldSupplyLevel)
            ];

            return icons[supplyLevelKey];
        },
        slideToggleDuration() {
            const productCount = this.productFieldProducts.length;
            const durationBase = 400;
            let duration = 500;

            if (productCount > 3) {
                duration = durationBase * (productCount / 2);
            }

            if (!this.isOpen) {
                duration = 500;
            }

            return duration;
        },
    },
    methods: {
        toggleProductList() {
            this.isOpen = !this.isOpen;
            this.$emit('productlist-toggled');
        },
        isProductRecommended(product) {
            return this.getPromotedProducts.includes(product.id);
        },
        productFieldProducts() {
            const specialCases = VisibilityDependency.exclude;

            let products = this.productField.products.filter((product) => {
                if (!specialCases[product.id]) {
                    return true;
                }

                let showProduct = false;
                specialCases[product.id].forEach((check) => {
                    const checkProduct = this.getProductById(check.product);
                    if (checkProduct.options[check.key]) {
                        // check that at least one of the keys is true
                        showProduct = true;
                    }
                });

                if (!showProduct) {
                    // if the product will be hidden, set all values to false!
                    this.$store.dispatch('updateProductStatus', {
                        productId: product.id,
                        option: 'INTEREST',
                        payload: false,
                    });
                    this.$store.dispatch('updateProductStatus', {
                        productId: product.id,
                        option: 'BANK',
                        payload: false,
                    });
                    this.$store.dispatch('updateProductStatus', {
                        productId: product.id,
                        option: 'EXTERNAL',
                        payload: false,
                    });
                    this.$store.dispatch('updateProductStatus', {
                        productId: product.id,
                        option: 'HIDE',
                        payload: false,
                    });
                }

                return showProduct;
            });

            const promotedProducts = this.getPromotedProductsOfProductField(this.productField.id);

            const orderPromoted = (a, b) => {
                if (promotedProducts.includes(a.id) && promotedProducts.includes(b.id)) {
                    return 0;
                }
                if (!promotedProducts.includes(a.id) && !promotedProducts.includes(b.id)) {
                    return 0;
                }
                if (promotedProducts.includes(a.id) && !promotedProducts.includes(b.id)) {
                    return -1;
                }
                if (!promotedProducts.includes(a.id) && promotedProducts.includes(b.id)) {
                    return 1;
                }
            };

            products.sort(orderPromoted);

            if (this.isStandalone) {
                return products.filter((product) => !ExcludeStandalone.exclude.includes(product.id));
            }
            if (this.isPrefilled) {
                const checkPrefilled = (product) => {
                    return product.options.BANK || product.options.EXTERNAL || product.options.INTEREST;
                };

                products = products.filter((product) => {
                    return checkPrefilled(product) || !ExcludePrefilled.exclude.includes(product.id);
                });

                const check = (product) => {
                    return product.options.BANK || product.options.EXTERNAL || product.options.INTEREST;
                };

                const orderProducts = (a, b) => {
                    if (check(a) && check(b)) {
                        return 0;
                    }
                    if (!check(a) && !check(b)) {
                        return 0;
                    }
                    if (check(a) && !check(b)) {
                        return -1;
                    }
                    if (!check(a) && check(b)) {
                        return 1;
                    }
                };

                products.sort(orderProducts);

                // INFO: hardcoded
                // if the user has "Privates_Girokonto" the other bank accounts should be hidden
                // ("Privates_Girokonto" is not "a real" product)
                const fake_account = 'Liquiditaet_Girokonto';
                const filter_out = ['Liquiditaet_Premium', 'Liquiditaet_Privat', 'Liquiditaet_Direkt'];
                let hide_standard_accounts = false;
                products.forEach((product) => {
                    if (product.id === fake_account && product.options.BANK) {
                        hide_standard_accounts = true;
                    }
                });
                if (hide_standard_accounts) {
                    products = products.filter((product) => {
                        return !filter_out.includes(product.id);
                    });
                }
                // END: hardcoded
            } else {
                products = products.filter((product) => !ExcludeOpen.exclude.includes(product.id));
                products.forEach((product, i) => {
                    if (this.isProductRecommended(product) && !this.getHiddenPromotions.includes(product.id)) {
                        products.splice(i, 1);
                        products.unshift(product);
                    }
                });
            }

            this.collapseProducts = products.slice(3, 100);
            this.products = products.slice(0, 3);
        },
    },
    watch: {
        showProductList(newValue) {
            this.isOpen = newValue;
        },
    },
    mounted() {
        if (this.productField.id === this.$store.getters.getcurrentlyOpenProductlist) {
            setTimeout(() => {
                this.toggleProductList();
                this.$scrollTo(`#${this.productField.id}`, {
                    offset: -100,
                });
                this.$store.commit('UPDATE_CURRENTLY_OPEN_PRODUCTLIST', '');
            }, 200);
        }
    },
};
</script>
