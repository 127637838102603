<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie ist Ihr Familienstand?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="options" v-model="inputValue" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            options: {
                single: {
                    icon: '',
                    label: 'Single',
                },
                couple: {
                    icon: '',
                    label: 'Paar',
                },
            },
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('family_status');
            },
            set(value) {
                this.isAnswered = value !== '';
                this.updateQuestionState('family_status', value);
            },
        },
    },
};
</script>
