import { get as _get } from 'lodash';
import json from '@/data/modules.json';

function get(key) {
    return _get(json, key);
}

function getProductField(data, moduleId) {
    return {
        id: data.id,
        headline: data.headline,
        subline: data.subline,
        moduleId: moduleId,
        products: data.products ? Object.values(data.products).map((p) => getProduct(p, moduleId, data.id)) : [],
    };
}

function getProduct(data, moduleId, productFieldId) {
    return {
        /**
         * data:
         * - description
         * - external_link
         * - gallery
         * - id
         * - progress_blocks
         * - title
         */
        ...data,
        statusBenefits: {
            classic: data.status_benefit_classic || 0,
            comfort: data.status_benefit_comfort || 0,
            silver: data.status_benefit_silver || 0,
            gold: data.status_benefit_gold || 0,
        },
        headline: data.title,
        excludeFromRetirement: false,
        doNotPromote: false,
        options: {
            INTEREST: false,
            BANK: false,
            EXTERNAL: false,
            RETIRED: false,
        },
        moduleId,
        productFieldId,
    };
}

export const modules = [
    {
        id: 'liquiditaet',
        cloudApiKeyFragment: 'Liquidität',
        color: 'blue',
        icon: 'liquiditaet',
        name: get('modules.Liquidität.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Liquidität.productFields')).map((pf) =>
            getProductField(pf, 'liquiditaet')
        ),
    },
    {
        id: 'absicherung',
        cloudApiKeyFragment: 'Absicherung',
        color: 'orange',
        icon: 'absicherung',
        name: get('modules.Absicherung.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Absicherung.productFields')).map((pf) =>
            getProductField(pf, 'absicherung')
        ),
    },
    {
        id: 'vermoegen',
        cloudApiKeyFragment: 'Vermögen',
        color: 'green',
        icon: 'vermoegen',
        name: get('modules.Vermögen.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Vermögen.productFields')).map((pf) =>
            getProductField(pf, 'vermoegen')
        ),
    },
    {
        id: 'vorsorge',
        cloudApiKeyFragment: 'Vorsorge',
        color: 'pink',
        icon: 'vorsorge',
        name: get('modules.Vorsorge.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Vorsorge.productFields')).map((pf) =>
            getProductField(pf, 'vorsorge')
        ),
    },
    {
        id: 'immobilie',
        cloudApiKeyFragment: 'immobilie',
        color: 'purple',
        icon: 'immobilie',
        name: get('modules.Immobilie.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Immobilie.productFields')).map((pf) =>
            getProductField(pf, 'immobilie')
        ),
    },
    {
        id: 'extra',
        cloudApiKeyFragment: 'Extra',
        color: 'yellow',
        icon: 'extra',
        name: get('modules.Extra.real_name'),
        isSpecialModule: false,
        productFields: Object.values(get('modules.Extra.productFields')).map((pf) => getProductField(pf, 'extra')),
    },
];
