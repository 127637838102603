<template>
    <svg class="app__icon" :class="customClass" :height="sizePx" :width="sizePx">
        <use v-bind:xlink:href="svgIcon" />
    </svg>
</template>

<script>
export default {
    props: {
        sizePx: {
            type: String,
            required: false,
            default: '16px',
            validator: (value) => {
                return value.includes('px');
            },
        },
        customClass: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: true,
            validator: (value) => {
                return !value.includes('#icon');
            },
        },
    },
    name: 'AppIcon',
    computed: {
        svgIcon() {
            return `#icon-${this.icon}`;
        },
    },
};
</script>
