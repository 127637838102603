import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import VueScrollTo from 'vue-scrollto';
import { cloneDeep, isEmpty } from 'lodash';
import Helpers from '@/helpers';
import api from '@/api';
import { stop as StopAudio } from '@/audio';
import productRecommendationConditions from '@/data/productRecommendationConditions';
import router from '@/router';
import storeModules from './modules';
import { BANKSTATUS } from '@/helpers';
import { PRODUCT_FIELD_STATUS } from '@/helpers';
import { Recommender } from '@/helpers';
import { modules as appModules } from '@/data';
import questionnaires from '@/data/questionnaires';
import questions from '@/data/questions';
import { eventBus } from '@/eventBus';

Vue.use(VueScrollTo);
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    // Don't rename the key! It is used in axios!
    key: 'hausbankfinder',
});

async function delay(ms) {
    return await new Promise((resolve) => setTimeout(resolve, ms));
}

const scrollToTop = () => {
    VueScrollTo.scrollTo('#app', 500, {
        container: 'body',
        force: true,
        cancelable: true,
        x: false,
        y: true,
    });
};

const initialStoreState = () => {
    return {
        apiIsLoading: false,
        consultantMode: false, // Berater Modus
        splashscreen: true,
        updateDate: null,
        showBookmarkPopUp: false,
        bookmarkAlreadySkipped: false,
        isPrefilled: false,
        code: '',
        standalone: false,
        cloudData: null,
        routeGuardsEnabled: true,
        currentlyOpenProductlist: '',
        loadingCloudData: false, // to avoid uploading data, while prefilling
        isSlideTransitionReversed: false,
        appModules: cloneDeep(appModules),
        statusModules: cloneDeep(appModules),
        questionnaires: cloneDeep(questionnaires),
        questions: cloneDeep(questions),
        animations: {
            shakeModule: false,
            showIntroSimulation: true,
            bounceClipboardIcon: false,
            showClipboardTooltip: true,
            bounceBankStatusProgressText: false,
            audioTooltip: false,
        },
        prefillableQuestions: {
            age: 0,
            property: '',
            job: '',
        },
        personalData: {
            isRetired: false,
            sufficientFunds: false,
            paidOffProperty: false,
            riskProfile: '',
        },
        views: {
            overlayBG: false,
            contactOverlay: false,
            clipboardOverlay: false,
            bankstatusOverlay: false,
            tariffsOverlay: false,
            questionnaireResultsOverlay: false,
            formSuccessOverlay: false,
            formErrorOverlay: false,
            overlay: false,
            clipboardTooltip: false,
            clipboardReminderTooltip: false,
            simulationTooltip: false,
        },
    };
};

const store = new Vuex.Store({
    modules: storeModules,
    state: initialStoreState(),
    mutations: {
        SET_API_IS_LOADING(state, payload) {
            state.apiIsLoading = payload;
        },
        UPDATE_CONSULTANT_MODE(state, payload) {
            state.consultantMode = payload;
        },
        RESET_STORE_STATE(state) {
            // Save states to re-apply later
            let animations = state.animations;
            let splashscreen = state.splashscreen;
            let code = state.code;
            let isPrefilled = state.isPrefilled;
            let consultantMode = state.consultantMode;
            let bookmarkSkipped = state.bookmarkAlreadySkipped;
            let showBookmarkPopUp = state.showBookmarkPopUp;
            // Reset store state
            Object.assign(state, initialStoreState());
            // Re-apply states
            Object.assign(state.animations, cloneDeep(animations));
            state.splashscreen = splashscreen;
            state.bookmarkAlreadySkipped = bookmarkSkipped;
            state.showBookmarkPopUp = showBookmarkPopUp;
            state.consultantMode = consultantMode;

            if (code !== '' && isPrefilled) {
                state.code = code;
                state.isPrefilled = isPrefilled;
            }
        },
        UPDATE_BOOKMARK_VISIBILITY(state, { isVisible }) {
            // if bookmarkAlreadySkipped the popup is never shown!
            if (state.bookmarkAlreadySkipped) {
                state.showBookmarkPopUp = false;
            } else {
                state.showBookmarkPopUp = isVisible;
                if (isVisible) {
                    setTimeout(() => {
                        state.showBookmarkPopUp = false;
                        state.bookmarkAlreadySkipped = true;
                    }, 10000);
                }
            }
        },
        UPDATE_BOOKMARK_ALREADY_SKIPPED(state) {
            state.bookmarkAlreadySkipped = true;
        },
        TOGGLE_ROUTE_GUARDS(state, payload) {
            state.routeGuardsEnabled = payload;
        },
        UPDATE_PREFILLED_MODE(state, payload) {
            state.isPrefilled = payload;
        },
        UPDATE_CODE(state, code) {
            state.code = code;
        },
        INITIALIZE_CLOUD_STATE(state, data) {
            state.cloudData = data;
        },
        UPDATE_CLOUD_UPDATEDATE(state, data) {
            state.updateDate = data;
        },
        UPDATE_ANIMATION_STATE(state, { animation, payload }) {
            state.animations[animation] = payload;
        },
        UPDATE_QUESTION_STATE(state, { moduleId, question, field, payload }) {
            state.questions[moduleId][question][field] = payload;
        },
        RESET_QUESTION_STATE(state, { moduleId, question }) {
            state.questions[moduleId][question] = Object.assign(
                {},
                state.questions[moduleId][question],
                questions[moduleId][question]
            );
        },
        UPDATE_PRODUCT_STATUS(state, { productId, option, payload }) {
            if (!['INTEREST', 'BANK', 'EXTERNAL', 'HIDE', 'RETIRED'].includes(option)) {
                throw new Error(`Given option "${option}" is not allowed!`);
            }
            const product = Helpers.getProductById(state.appModules, productId);

            if (option === 'HIDE') {
                product.doNotPromote = payload;
                // return and finish update
                return;
            }

            product.options[option] = payload;

            // Make interest / bank fields exclusive
            if (option === 'INTEREST' && payload === true) {
                product.options['BANK'] = false;
            } else if (option === 'BANK' && payload === true) {
                product.options['INTEREST'] = false;
            }
        },
        UPDATE_STATUS_MODULES(state) {
            // Object.assign(state.statusModules, state.appModules);
            state.statusModules = cloneDeep(state.appModules);
        },
        UPDATE_STATUS_MODULE(state, { productFieldId, newValue }) {
            let productField = Helpers.getProductFieldById(state.statusModules, productFieldId);

            Object.assign(productField, cloneDeep(newValue));
        },
        UPDATE_EXCLUDE_FROM_RETIREMENT_STATE(state, { productId, payload }) {
            const product = Helpers.getProductById(state.appModules, productId);
            product.excludeFromRetirement = payload;
        },
        UPDATE_CURRENTLY_OPEN_PRODUCTLIST(state, productlistId) {
            this.state.currentlyOpenProductlist = productlistId;
        },
        UPDATE_VIEW(state, { view, payload }) {
            state.views[view] = payload;
        },
        UPDATE_PERSONAL_DATA(state, { data, payload }) {
            state.personalData[data] = payload;
        },
        UPDATE_PREFILLABLE_QUESTIONS(state, { data, payload }) {
            state.prefillableQuestions[data] = payload;
        },
        UPDATE_ACTIVE_QUESTION(state, { moduleId, payload }) {
            state.questionnaires[moduleId].activeQuestion = payload;
        },
        UPDATE_HIGHEST_VISITED_QUESTION(state, { moduleId, payload }) {
            state.questionnaires[moduleId].highestVisitedQuestion = payload;
        },
        UPDATE_NEXT_QUESTION(state, { moduleId, payload }) {
            state.questionnaires[moduleId].nextQuestion = payload;
        },
        MARK_QUESTIONNAIRE_AS_COMPLETED(state, { moduleId, payload }) {
            state.questionnaires[moduleId].isCompleted = payload;
        },
        UPDATE_SLIDE_TRANSITION_DIRECTION(state, payload) {
            state.isSlideTransitionReversed = payload;
        },
        UPDATE_SPLASHSCREEN(state, payload) {
            state.splashscreen = payload;
        },
        UPDATE_LOADING_CLOUD_DATA(state, payload) {
            state.loadingCloudData = payload;
        },
    },
    actions: {
        consultantMode(context) {
            context.commit('UPDATE_CONSULTANT_MODE', true);
        },
        hideBookmarkPopUp(context) {
            context.commit('UPDATE_BOOKMARK_VISIBILITY', {
                isVisible: false,
            });
            context.commit('UPDATE_BOOKMARK_ALREADY_SKIPPED');
            // if prefilled, the user show not get the popup again
            if (context.state.isPrefilled) {
                api.updateKeyInCloud('Lesezeichen_Hinweis', true, store.state.code);
            }
        },
        activateStandaloneMode(context) {
            context.state.standalone = true;
        },
        resetStoreState({ commit, state, dispatch }) {
            const enableRouteGuards = () => commit('TOGGLE_ROUTE_GUARDS', true);
            Object.keys(storeModules).forEach((moduleName) => {
                commit(`${moduleName}/RESET`);
            });
            commit('TOGGLE_ROUTE_GUARDS', false);
            router.push({ name: 'home' }, enableRouteGuards, enableRouteGuards);
            StopAudio();

            if (state.consultantMode) {
                dispatch('activatePrefilledMode', state.code);
            } else if (state.code !== '' && state.isPrefilled) {
                dispatch('resetCloudState');
            }

            commit('RESET_STORE_STATE');
        },
        showIntroSimulation(context) {
            // Make a copy of state.appModules and enable all products in it
            const modulesProxy = cloneDeep(context.state.appModules);

            // these product fields will be skipped and not initially animated!
            const { exclude } = require('@/data/excludeInitialAnimation.json');

            for (const module of modulesProxy) {
                for (const productField of module.productFields) {
                    if (!exclude.includes(productField.id)) {
                        productField.products[0].options.BANK = true;
                    }
                }
            }
            scrollToTop();
            // Start simulation animation
            context.dispatch('animateModuleStatus', modulesProxy).then(
                () => {
                    // Reset store state to default when done
                    setTimeout(() => {
                        context.commit('RESET_STORE_STATE');
                        context.commit('UPDATE_ANIMATION_STATE', {
                            animation: 'showIntroSimulation',
                            payload: false,
                        });
                    }, 800);

                    // Shake first module after simulation is done
                    setTimeout(() => {
                        store.commit('UPDATE_ANIMATION_STATE', {
                            animation: 'shakeModule',
                            payload: true,
                        });

                        // Show Audio Tooltip
                        setTimeout(() => {
                            store.commit('UPDATE_ANIMATION_STATE', {
                                animation: 'audioTooltip',
                                payload: true,
                            });
                        }, 1000);
                        scrollToTop();
                    }, 1200);
                },
                () => {
                    // Animation aborted => reset state immediately
                    context.commit('RESET_STORE_STATE');
                    context.commit('UPDATE_ANIMATION_STATE', {
                        animation: 'showIntroSimulation',
                        payload: false,
                    });
                }
            );
        },
        resetCloudState(context) {
            let products = [];

            for (const module of context.state.appModules) {
                for (const productField of module.productFields) {
                    for (const product of productField.products) {
                        if (product.options.INTEREST || product.options.EXTERNAL) {
                            products.push(product);
                        }
                    }
                }
            }

            api.resetProducts(products).then(() => {
                context.commit('UPDATE_PREFILLED_MODE', false);
                context.dispatch('activatePrefilledMode', context.state.code);
            });
        },
        activatePrefilledMode(context, code) {
            context.commit('SET_API_IS_LOADING', true);

            return new Promise((resolve, reject) => {
                if (typeof code !== 'string' || code.length === 0) {
                    return reject();
                }

                // Get Data from cloud
                api.getCloudData(code)
                    .then((response) => {
                        if (response.data !== null && !isEmpty(response.data.products)) {
                            // Reset store data before updating it from cloud
                            context.commit('RESET_STORE_STATE');

                            // Activate prefilled mode and set code
                            context.commit('UPDATE_CODE', code);
                            context.commit('UPDATE_PREFILLED_MODE', true);

                            // Save cloudData to store for later reference
                            context.commit('INITIALIZE_CLOUD_STATE', response.data.products);

                            context.dispatch('recommendation/initializeRecommendation', response.data.products, {
                                root: true,
                            });

                            context.commit('UPDATE_CLOUD_UPDATEDATE', response.data.updateDate);

                            // Set states for prefilled products
                            context.dispatch('updateProductsFromCloud', response.data.products);

                            // Start simulation animation if there's no splashscreen
                            if (!context.state.splashscreen) {
                                store.dispatch('animateModuleStatus', cloneDeep(context.state.appModules)).then(
                                    () => {
                                        store.commit('UPDATE_ANIMATION_STATE', {
                                            animation: 'showIntroSimulation',
                                            payload: false,
                                        });
                                        setTimeout(() => {
                                            store.commit('UPDATE_ANIMATION_STATE', {
                                                animation: 'shakeModule',
                                                payload: true,
                                            });
                                        }, 1000);
                                    },
                                    () => {
                                        store.commit('UPDATE_ANIMATION_STATE', {
                                            animation: 'showIntroSimulation',
                                            payload: false,
                                        });
                                    }
                                );
                            }
                        } else {
                            // Handle case with wrong code?
                            // Just do nothing for now
                        }

                        context.commit('SET_API_IS_LOADING', false);
                        return resolve();
                    })
                    .catch(() => {
                        context.commit('SET_API_IS_LOADING', false);
                        context.commit('RESET_STORE_STATE');
                        return reject();
                    });
            });
        },
        updateProductsFromCloud(context, data) {
            context.state.loadingCloudData = true;
            const cloudData = data;

            // Update products
            for (const module of context.state.appModules) {
                for (const productField of module.productFields) {
                    for (const product of productField.products) {
                        // Update product option "BANK" if value is prefilled
                        if (cloudData[product.id] === 1) {
                            context.commit('UPDATE_PRODUCT_STATUS', {
                                productId: product.id,
                                option: 'BANK',
                                payload: true,
                            });
                        }

                        // Update product option "EXTERNAL" if value is prefilled
                        if (cloudData[`Fremd_${product.id}`] === 1) {
                            context.commit('UPDATE_PRODUCT_STATUS', {
                                productId: product.id,
                                option: 'EXTERNAL',
                                payload: true,
                            });
                        }

                        // Update product option "INTEREST" if value is prefilled
                        if (cloudData[`Interesse_${product.id}`] === 1) {
                            store.commit('clipboard/addValue', {
                                property: 'oldClipboardProducts',
                                value: product.id,
                            });
                        }

                        // Update product option "HIDE" if value is prefilled
                        if (cloudData[`Irrelevant_${product.id}`] === 1) {
                            context.commit('UPDATE_PRODUCT_STATUS', {
                                productId: product.id,
                                option: 'HIDE',
                                payload: true,
                            });
                        }

                        // Add Product Recommendations
                        if (cloudData[`CheckStrecke_${product.id}`] === 1) {
                            /**
                             * INFO: If there is a recommendation,
                             *       that means that the Questionnaire of this module was completed.
                             */
                            context.commit(
                                'recommendation/addValue',
                                {
                                    property: 'questionnaireRecommendations',
                                    value: product.id,
                                },
                                { root: true }
                            );

                            context.commit('MARK_QUESTIONNAIRE_AS_COMPLETED', {
                                moduleId: product.moduleId,
                                payload: true,
                            });
                        }
                    }
                }
            }

            // personal
            if (cloudData['Schon_Im_Ruhestand']) {
                context.commit('UPDATE_PERSONAL_DATA', {
                    data: 'isRetired',
                    payload: cloudData['Schon_Im_Ruhestand'] === 1,
                });
            }
            if (cloudData['Abbezahlte_Immobilie']) {
                context.commit('UPDATE_PERSONAL_DATA', {
                    data: 'paidOffProperty',
                    payload: cloudData['Abbezahlte_Immobilie'] === 1,
                });
            }
            if (cloudData['Risikoprofil']) {
                if (parseInt(cloudData['Risikoprofil']) < 0 || parseInt(cloudData['Risikoprofil']) > 5) {
                    cloudData['Risikoprofil'] = 0;
                }
                context.commit('UPDATE_PERSONAL_DATA', {
                    data: 'riskProfile',
                    payload: parseInt(cloudData['Risikoprofil']),
                });
            }
            if (cloudData['Genug_Erspartes']) {
                context.commit('UPDATE_PERSONAL_DATA', {
                    data: 'sufficientFunds',
                    payload: cloudData['Genug_Erspartes'] === 1,
                });
            }
            // prefillable
            if (cloudData['Alter']) {
                context.commit('UPDATE_PREFILLABLE_QUESTIONS', {
                    data: 'age',
                    payload: parseInt(cloudData['Alter']),
                });
            }
            if (cloudData['Job']) {
                context.commit('UPDATE_PREFILLABLE_QUESTIONS', {
                    data: 'job',
                    payload: cloudData['Job'].toString(),
                });
            }
            if (cloudData['Eigentum']) {
                context.commit('UPDATE_PREFILLABLE_QUESTIONS', {
                    data: 'property',
                    payload: cloudData['Eigentum'] === 1,
                });
            }
            // bookmark
            if (cloudData['Lesezeichen_Hinweis'] && cloudData['Lesezeichen_Hinweis'] === 1) {
                context.commit('UPDATE_BOOKMARK_ALREADY_SKIPPED');
            }

            // INFO: hardcoded
            // if the user has "Privates_Girokonto" the other bank accounts should be hidden
            // ("Privates_Girokonto" is not "a real" product)
            const fake_account = 'Liquiditaet_Girokonto';
            const check_for = ['Liquiditaet_Premium', 'Liquiditaet_Privat', 'Liquiditaet_Direkt'];
            let has_standard_account = false;
            check_for.forEach((product) => {
                if (cloudData[product] === 1) {
                    has_standard_account = true;
                }
            });
            if (has_standard_account) {
                context.commit('UPDATE_PRODUCT_STATUS', {
                    productId: fake_account,
                    option: 'BANK',
                    payload: true,
                });
            }
            // END: hardcoded

            context.state.loadingCloudData = false;
        },
        animateModuleStatus(context, modules = context.state.appModules) {
            const animationDelay = 500;

            return new Promise((resolve) => {
                let delta = [];

                // Check for differences in productField status
                for (const module of modules) {
                    for (const productField of module.productFields) {
                        let statusModuleProductField = Helpers.getProductFieldById(
                            context.state.statusModules,
                            productField.id
                        );
                        if (JSON.stringify(productField) !== JSON.stringify(statusModuleProductField)) {
                            delta.push(productField);
                        }
                    }
                }

                const animateBankStatusProgressText = () => {
                    setTimeout(() => {
                        context.commit('UPDATE_ANIMATION_STATE', {
                            animation: 'bounceBankStatusProgressText',
                            payload: true,
                        });
                    }, animationDelay);
                };

                const updateStatusModule = (i) => {
                    context.commit('UPDATE_STATUS_MODULE', {
                        productFieldId: delta[i].id,
                        newValue: delta[i],
                    });
                };

                const breakpoint = Helpers.getBreakpoint();
                let isScrolling = false;
                let scrollTop = document.documentElement.scrollTop;

                const scrollToModule = (i) => {
                    return new Promise((resolve) => {
                        if (breakpoint === 'mobile' || breakpoint === 'narrow' || breakpoint == 'medium') {
                            const module = document.getElementById(`module-${delta[i].moduleId}`);
                            const moduleOffset = module.offsetTop;

                            if (!isScrolling && scrollTop !== moduleOffset) {
                                VueScrollTo.scrollTo(module, {
                                    easing: 'ease-out',
                                    duration: 650,
                                    offset: -116,
                                    force: true,
                                    onStart: () => {
                                        isScrolling = true;
                                    },
                                    onDone: () => {
                                        resolve(i);
                                        isScrolling = false;
                                    },
                                    onCancel: () => {
                                        resolve(i);
                                        isScrolling = false;
                                    },
                                });
                                scrollTop = moduleOffset;
                            } else {
                                resolve(i);
                            }
                        } else {
                            resolve(i);
                        }
                    });
                };

                const runAnimation = async () => {
                    for (let i = 0; i < delta.length; i++) {
                        if (context.state.route.name !== 'home') {
                            // Cancel animationSequence and update values immediately
                            context.commit('UPDATE_STATUS_MODULES');
                            context.commit('UPDATE_ANIMATION_STATE', {
                                animation: 'showIntroSimulation',
                                payload: false,
                            });
                            return;
                        }

                        await scrollToModule(i).then((i) => {
                            updateStatusModule(i);
                        });

                        // After sequence is done
                        if (i === delta.length - 1) {
                            animateBankStatusProgressText();
                            resolve();
                        }

                        // delay animation iteration
                        await delay(animationDelay);
                    }
                };

                if (!context.state.bookmarkAlreadySkipped) {
                    store.commit('UPDATE_BOOKMARK_VISIBILITY', {
                        isVisible: true,
                    });
                }

                // Update productField values consecutively with delay
                if (delta.length > 0) {
                    runAnimation();
                } else {
                    resolve();
                }
            });
        },
        updateProductStatus(context, { productId, option, payload }) {
            if (!['INTEREST', 'BANK', 'EXTERNAL', 'HIDE', 'RETIRED'].includes(option)) {
                throw new Error(`Given option "${option}" is not allowed!`);
            }

            const product = Helpers.getProductById(context.state.appModules, productId);
            const productField = Helpers.getProductFieldById(context.state.appModules, product.productFieldId);
            const currentProductFieldStatus = Helpers.getProductFieldStatus(productField);

            context.commit('UPDATE_PRODUCT_STATUS', {
                productId,
                option,
                payload,
            });

            // Sync product state back to cloud, if in prefilled mode
            if (context.state.isPrefilled && context.state.code !== '' && option !== 'BANK' && option !== 'RETIRED') {
                // wenn produkte im "alten" merkzettel und der nutzer ein produkt als "INTEREST" markiert,
                // werden die produkte in der Cloud zurückgesetzt (INTEREST_<produkt_id> = 0)
                if (
                    option === 'INTEREST' &&
                    context.rootGetters['clipboard/getOldClipboardProducts'].length > 0 && // Es gibt alte Produkte
                    !context.rootGetters['clipboard/oldClipboardApplied'] && // Produkte nicht übernommen
                    !context.rootGetters['clipboard/oldClipboardCloudReset'] && // Noch nicht in der Cloud aktualisiert
                    !context.rootGetters['consultantMode'] // Nicht im "BeraterModus"
                ) {
                    const products = {};
                    context.rootGetters['clipboard/getOldClipboardProducts'].forEach((productId) => {
                        products[`Interesse_${productId}`] = 0;
                    });
                    api.updateMultipleKeysInCloud(products);

                    store.dispatch('clipboard/oldClipboardCloudReset'); // es "erledigt" markieren
                }

                api.updateProductOptionInCloud(productId, option, payload);
            }

            if (
                currentProductFieldStatus !== Helpers.getProductFieldStatus(productField) &&
                context.state.route.name !== 'home'
            ) {
                // Show simulationTooltip when productFieldStatus has changed, but only if clipboardTooltip isn't active
                if (!context.state.views.clipboardTooltip) {
                    context.commit('UPDATE_VIEW', {
                        view: 'simulationTooltip',
                        payload: true,
                    });
                }
            }
        },
        validateQuestionnaire(context, moduleId) {
            const products = context.getters
                .getModule(moduleId)
                .productFields.map((pf) => pf.products)
                .flat(1);
            const questions = context.getters.getQuestions(moduleId);
            const recommendationConditions = productRecommendationConditions[moduleId] || {};
            const recommender = new Recommender(recommendationConditions);

            const recommendedProducts = recommender.getRecommendedProducts({
                answers: questions,
                products: products,
            });

            context.dispatch(
                'recommendation/addQuestionnaireProducts',
                {
                    moduleId,
                    recommendedProducts,
                },
                {
                    root: true,
                }
            );
        },
        resetSubsequentQuestions(context, { moduleId, question, offset = 0 }) {
            const questions = Object.keys(context.getters.getQuestions(moduleId));
            const currentQuestionIndex = questions.indexOf(question);
            let followingQuestions = questions.slice(currentQuestionIndex + offset);

            const removeSecondQuestions = () => {
                const question = followingQuestions[0];
                if (question.includes('b') || question.includes('c')) {
                    followingQuestions.shift();
                    removeSecondQuestions();
                }
            };
            removeSecondQuestions();

            followingQuestions.forEach((question) => {
                context.commit('RESET_QUESTION_STATE', {
                    moduleId: moduleId,
                    question: question,
                });
            });
        },
    },
    getters: {
        getApiIsLoading(state) {
            return state.apiIsLoading;
        },
        consultantMode(state) {
            return state.consultantMode;
        },
        showBookmarkPopUp: (state) => {
            return state.showBookmarkPopUp;
        },
        getAppModules: (state) => {
            return state.appModules;
        },
        isStandalone: (state) => {
            return state.standalone;
        },
        getModule: (state) => (moduleId) => {
            let result = state.appModules.filter((m) => {
                return m.id === moduleId;
            });
            return result[0];
        },
        getProductById: (state) => (productId) => {
            return Helpers.getProductById(state.appModules, productId);
        },
        getProductsWithBenefits: (state) => {
            let products = [];
            let modules = state.appModules;

            if (store.getters.getAnimationState('showIntroSimulation')) {
                modules = state.statusModules;
            }

            for (const module of modules) {
                for (const productField of module.productFields) {
                    for (const product of productField.products) {
                        // Check if any of the benefit values are non-zero
                        if (Object.values(product.statusBenefits).some((bonus) => bonus)) {
                            products.push(product);
                        }
                    }
                }
            }
            return products;
        },
        getProductField: (state) => (productFieldId) => {
            return Helpers.getProductFieldById(state.appModules, productFieldId);
        },
        getProductFieldStatus: (state) => (productFieldId) => {
            const productField = Helpers.getProductFieldById(state.appModules, productFieldId);

            return Helpers.getProductFieldStatus(productField);
        },
        /**
         * Return the "MAXIMAL" points for a module
         */
        getProductFieldCount: (state) => (appModules, moduleId) => {
            let result = state[appModules].filter((module) => {
                return module.id === moduleId;
            });
            let count = 0;

            if (moduleId === 'extra') {
                for (const productField of result[0].productFields) {
                    for (const product of productField.products) {
                        if (product.options.BANK === true || product.id === 'Extra_digitale_girocard') {
                            count += product.progress_blocks;
                        }
                    }
                }
            } else {
                count = result[0].productFields.length;
            }
            return count;
        },
        /**
         * Count filled and dashed PER MODULE
         */
        getFilledProductFieldCount: (state) => (appModules, moduleId) => {
            let filledProductFieldCount = {
                filled: 0,
                dashed: 0,
                retired: 0,
            };
            for (const module of state[appModules]) {
                if (module.id === moduleId) {
                    if (moduleId === 'extra') {
                        for (const productField of module.productFields) {
                            for (const product of productField.products) {
                                if (product.options.BANK || product.options.INTEREST) {
                                    filledProductFieldCount.filled += product.progress_blocks;
                                } else if (product.options.EXTERNAL) {
                                    filledProductFieldCount.filled += product.progress_blocks;
                                }
                            }
                        }
                    } else {
                        for (const productField of module.productFields) {
                            const productFieldStatus = Helpers.getProductFieldStatus(productField);
                            if (productFieldStatus === PRODUCT_FIELD_STATUS.FILLED) {
                                filledProductFieldCount.filled++;
                            }
                            if (productFieldStatus === PRODUCT_FIELD_STATUS.DASHED) {
                                filledProductFieldCount.dashed++;
                            }
                            if (productFieldStatus === PRODUCT_FIELD_STATUS.RETIRED) {
                                filledProductFieldCount.retired++;
                            }
                        }
                    }
                }
            }

            return filledProductFieldCount;
        },
        /**
         * Return filled and dashed over all modules
         */
        bankstatusCount: (state) => {
            const filledArr = [];
            const dashedArr = [];
            for (const module of state.statusModules) {
                if (module.id === 'extra') {
                    for (const productField of module.productFields) {
                        for (const product of productField.products) {
                            if (product.options.BANK || product.options.INTEREST) {
                                for (let i = 0; i < product.progress_blocks; i++) {
                                    filledArr.push(productField.moduleId);
                                }
                            } else if (product.options.EXTERNAL) {
                                for (let i = 0; i < product.progress_blocks; i++) {
                                    dashedArr.push(productField.moduleId);
                                }
                            }
                        }
                    }
                } else {
                    for (const productField of module.productFields) {
                        const productFieldStatus = Helpers.getProductFieldStatus(productField);
                        if (productFieldStatus === PRODUCT_FIELD_STATUS.FILLED) {
                            filledArr.push(productField.moduleId);
                        } else if (productFieldStatus === PRODUCT_FIELD_STATUS.DASHED) {
                            dashedArr.push(productField.moduleId);
                        }
                    }
                }
            }

            return {
                filled: filledArr,
                dashed: dashedArr,
            };
        },
        getViewState: (state) => {
            return state.views;
        },
        getPersonalData: (state) => {
            return state.personalData;
        },
        getPrefillableQuestions: (state) => {
            return state.prefillableQuestions;
        },
        getQuestion: (state) => (moduleId, question, field) => {
            return state.questions[moduleId][question][field];
        },
        getQuestionObject: (state) => (moduleId, question) => {
            return state.questions[moduleId][question];
        },
        getQuestions: (state) => (moduleId) => {
            return state.questions[moduleId];
        },
        getQuestionAnsweredState: (state) => (moduleId, question) => {
            return state.questions[moduleId][question].isAnswered;
        },
        hideQuestionFromNavigation: (state) => (moduleId, question) => {
            let hideFromNavigation = state.questions[moduleId][question].hideFromNavigation;
            if (hideFromNavigation === undefined) {
                hideFromNavigation = false;
            }
            return hideFromNavigation;
        },
        hideQuestionWhenPrefilled: (state) => (moduleId, question) => {
            let hideWhenPrefilled = state.questions[moduleId][question].hideWhenPrefilled;
            if (hideWhenPrefilled === undefined) {
                hideWhenPrefilled = false;
            }
            return hideWhenPrefilled;
        },
        hideQuestionWhenStandalone: (state) => (moduleId, question) => {
            let hideWhenStandalone = state.questions[moduleId][question].hideWhenStandalone;
            if (hideWhenStandalone === undefined) {
                hideWhenStandalone = false;
            }
            return hideWhenStandalone;
        },
        getActiveQuestion: (state) => (moduleId) => {
            return state.questionnaires[moduleId].activeQuestion;
        },
        getNextQuestionId: (state, getters) => (moduleId) => {
            return getters.getSubsequentQuestionId(moduleId, +1);
        },
        getPrevQuestionId: (state, getters) => (moduleId) => {
            return getters.getSubsequentQuestionId(moduleId, -1);
        },
        getSubsequentQuestionId: (state, getters) => (moduleId, direction) => {
            const activeQuestion = getters.getActiveQuestion(moduleId);
            const questions = getters.getQuestions(moduleId);

            const getQuestionByIndex = (i) => Object.values(questions)[i];

            let currentIndex = Object.keys(questions).indexOf(activeQuestion) + direction;
            let currentQuestion;
            while ((currentQuestion = getQuestionByIndex(currentIndex))) {
                let currentQuestionId = Object.keys(questions)[currentIndex];
                currentIndex += direction;

                if (currentQuestion.hideFromNavigation) {
                    continue;
                }

                if (currentQuestion.hideWhenPrefilled && getters.isPrefilled) {
                    continue;
                }

                let condition = currentQuestion.condition || (() => true);
                let isNextQuestionConditionMet = condition(questions, getters.getAllProductsObjects);

                if (isNextQuestionConditionMet) {
                    return currentQuestionId;
                }
            }
        },
        getHighestActiveQuestion: (state) => (moduleId) => {
            return state.questionnaires[moduleId].highestVisitedQuestion;
        },
        isQuestionnaireCompleted: (state) => (moduleId) => {
            return state.questionnaires[moduleId].isCompleted;
        },
        getSlideTransitionState: (state) => {
            return state.isSlideTransitionReversed;
        },
        getCode: (state) => {
            return state.code;
        },
        getAnimationState: (state) => (animation) => {
            return state.animations[animation];
        },
        isPrefilled: (state) => {
            return state.isPrefilled;
        },
        areRouteGuardsEnabled: (state) => {
            return state.routeGuardsEnabled;
        },
        getBankStatus: (state, getters) => {
            let status = 'classic';
            const filledCount = getters.bankstatusCount.filled.length;

            for (let bankstatus in BANKSTATUS) {
                if (filledCount >= BANKSTATUS[bankstatus].CONDITION) {
                    status = BANKSTATUS[bankstatus].NAME;
                }
            }
            return status;
        },
        getCloudData: (state) => {
            return state.cloudData;
        },
        getcurrentlyOpenProductlist: (state) => {
            return state.currentlyOpenProductlist;
        },
        getUpdateDate: (state) => {
            return state.updateDate;
        },
        getNextStatus: (state, getters) => {
            let nextStatus = '';
            let currentStatus = getters.getBankStatus;
            const statusSequence = ['classic', 'comfort', 'silver', 'gold'];

            for (let i = 0; i < statusSequence.length; i++) {
                if (statusSequence[i] === currentStatus) {
                    if (statusSequence[i + 1] != null) {
                        nextStatus = statusSequence[i + 1];
                    }
                }
            }

            return nextStatus;
        },
        getAllProductsObjects(state) {
            const productArray = [];
            state.appModules.forEach((module) => {
                module.productFields.forEach((productField) => {
                    productField.products.forEach((product) => {
                        productArray.push(product);
                    });
                });
            });
            return productArray;
        },
        getSplashscreen: (state) => {
            return state.splashscreen;
        },
        /**
         * @return {string}
         */
        getAgePrefix: (state) => {
            // info: 0 is store default, than return 'middle';
            const age = parseInt(state.prefillableQuestions.age);
            if (age > 0 && age < 30) {
                return 'young';
            }
            if (age >= 30 && age <= 60) {
                return 'middle';
            }
            if (age > 60) {
                return 'old';
            }

            return 'middle';
        },
    },
    plugins:
        process.env.VUE_APP_ENABLE_LOCALSTORAGE !== 'false' || process.env.NODE_ENV !== 'development'
            ? [vuexLocal.plugin]
            : [],
});

// Show/hide overlayBG anytime an overlay opens/closes
store.subscribe((mutation) => {
    if (
        mutation.type === 'UPDATE_VIEW' &&
        mutation.payload.view !== 'overlayBG' &&
        mutation.payload.view.toLowerCase().includes('overlay')
    ) {
        const showOrHide = mutation.payload.payload;
        store.commit('UPDATE_VIEW', {
            view: 'overlayBG',
            payload: showOrHide,
        });
    }
});

// Update prefillable questions state whenever a related question updated
store.subscribe((mutation) => {
    if (mutation.type === 'UPDATE_QUESTION_STATE') {
        if (
            mutation.payload.field === 'age' ||
            mutation.payload.field === 'job' ||
            mutation.payload.field === 'property'
        ) {
            store.commit('UPDATE_PREFILLABLE_QUESTIONS', {
                data: mutation.payload.field,
                payload: mutation.payload.payload,
            });
        }
    }
});

// Update clipboard whenever a product option is updated
store.subscribe((mutation) => {
    if (mutation.type === 'UPDATE_PRODUCT_STATUS') {
        const productId = mutation.payload.productId;
        const updateClipboardStatus = (action, section) => {
            store.dispatch(action, {
                productId: productId,
                clipboardSection: section,
            });
        };

        if (mutation.payload.option === 'INTEREST') {
            // Update clipboard status of product in "interest" clipboard section
            if (mutation.payload.payload === true) {
                updateClipboardStatus('clipboard/addToClipboard', 'interest');
            } else {
                updateClipboardStatus('clipboard/removeFromClipboard', 'interest');
            }
        } else if (mutation.payload.option === 'BANK') {
            // Remove product from clipboards "interest" section if "BANK" option is selected and product is currently in clipboard
            const clipboardProducts = store.getters['clipboard/getClipboardProducts'];
            if (mutation.payload.payload === true && clipboardProducts['interest'].indexOf(productId) !== -1) {
                updateClipboardStatus('clipboard/removeFromClipboard', 'interest');
            }
        } else if (mutation.payload.option === 'EXTERNAL') {
            // Update clipboard status of product in "external" clipboard section
            if (mutation.payload.payload === true) {
                updateClipboardStatus('clipboard/addToClipboard', 'external');
            } else {
                updateClipboardStatus('clipboard/removeFromClipboard', 'external');
            }
        }

        // Stop currently playing audio
        StopAudio().then(() => {
            eventBus.$emit('global-audio-stop');
        });
    }
});

// Handle isRetired state
store.subscribe((mutation, state) => {
    // upload data to cloud
    if (mutation.type === 'MARK_QUESTIONNAIRE_AS_COMPLETED' && !state.loadingCloudData) {
        const personalData = {
            Schon_Im_Ruhestand: state.personalData['isRetired'] ? 1 : 0,
            Genug_Erspartes: state.personalData['sufficientFunds'] ? 1 : 0,
            Abbezahlte_Immobilie: state.personalData['paidOffProperty'] ? 1 : 0,
            Risikoprofil: parseInt(state.personalData['riskProfile']),
            Alter: parseInt(state.prefillableQuestions['age']),
            Eigentum: state.prefillableQuestions['property'] ? 1 : 0,
            Job: parseInt(state.prefillableQuestions['job']),
        };

        if (store.state.isPrefilled && store.state.code !== '') {
            api.updateMultipleKeysInCloud(personalData);
        }
    }
    if (mutation.type === 'UPDATE_PERSONAL_DATA' && mutation.payload.data === 'isRetired') {
        const vorsorgeModule = store.getters.getModule('vorsorge');
        // INFO: Hardcoded Products that not get marked as EXTERNAL
        const ignoredProducts = ['Vorsorge_Ausreichend_Vermoegen'];
        const isRetired = mutation.payload.payload;

        // Sync retirement state back to cloud
        if (store.state.isPrefilled && store.state.code !== '') {
            api.updateKeyInCloud('Schon_Im_Ruhestand', isRetired);
        }

        let sideEffectProducts = [];
        // Retirement state has been activated
        for (const productField of vorsorgeModule.productFields) {
            for (const product of productField.products) {
                if (!ignoredProducts.includes(product.id)) {
                    sideEffectProducts.push(product);
                }
            }
        }

        sideEffectProducts.forEach((product) => {
            store.dispatch('updateProductStatus', {
                productId: product.id,
                option: 'RETIRED',
                payload: isRetired,
            });
        });
    }
});

// Hide simulation tooltip automatically after a few seconds
store.watch(
    (state) => state.views.simulationTooltip,
    (newValue) => {
        if (newValue === true) {
            setTimeout(() => {
                store.commit('UPDATE_VIEW', {
                    view: 'simulationTooltip',
                    payload: false,
                });
            }, 3000);
        }
    }
);

// Hide clipboard reminder tooltip automatically after a few seconds
store.watch(
    (state) => state.views.clipboardReminderTooltip,
    (newValue) => {
        if (newValue === true) {
            setTimeout(() => {
                store.commit('UPDATE_VIEW', {
                    view: 'clipboardReminderTooltip',
                    payload: false,
                });
            }, 5000);
        }
    }
);

// Disable bounceBankStatusProgressText animation automatically after a few seconds
store.watch(
    (state) => state.animations.bounceBankStatusProgressText,
    (newValue) => {
        if (newValue === true) {
            setTimeout(() => {
                store.commit('UPDATE_ANIMATION_STATE', {
                    animation: 'bounceBankStatusProgressText',
                    payload: false,
                });
            }, 1000);
        }
    }
);

// Automatically disable bounceClipboardIcon after 1 second after it has been set
store.watch(
    (state) => state.animations.bounceClipboardIcon,
    (newValue) => {
        if (newValue === true) {
            setTimeout(() => {
                store.commit('UPDATE_ANIMATION_STATE', {
                    animation: 'bounceClipboardIcon',
                    payload: false,
                });
            }, 1000);
        }
    }
);

// Automatically disable audioTooltip after a few seconds after it has been set
store.watch(
    (state) => state.animations.audioTooltip,
    (newValue) => {
        if (newValue === true) {
            setTimeout(() => {
                store.commit('UPDATE_ANIMATION_STATE', {
                    animation: 'audioTooltip',
                    payload: false,
                });
            }, 4600);
        }
    }
);

// Start simulation after App is entered
store.watch(
    (state) => state.splashscreen,
    (newValue) => {
        async function startIntro() {
            if (store.getters.isPrefilled) {
                // Handle Prefilled State
                await delay(600);
                store.dispatch('animateModuleStatus').then(
                    () => {
                        store.commit('UPDATE_ANIMATION_STATE', {
                            animation: 'showIntroSimulation',
                            payload: false,
                        });
                        setTimeout(() => {
                            store.commit('UPDATE_ANIMATION_STATE', {
                                animation: 'shakeModule',
                                payload: true,
                            });

                            // Show Audio Tooltip
                            setTimeout(() => {
                                store.commit('UPDATE_ANIMATION_STATE', {
                                    animation: 'audioTooltip',
                                    payload: true,
                                });
                            }, 1000);
                        }, 1000);
                    },
                    () => {
                        store.commit('UPDATE_ANIMATION_STATE', {
                            animation: 'showIntroSimulation',
                            payload: false,
                        });
                    }
                );
            } else {
                // Handle regular state
                await delay(600);
                store.dispatch('showIntroSimulation');
            }
        }

        if (newValue === false) {
            startIntro();
        }
    }
);

export default store;
