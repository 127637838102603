<template>
    <modal-wrapper-inline :open.sync="open" :title="$t('overlays.cookies.title')">
        <p>{{ $t('overlays.cookies.text') }}</p>
        <checkbox val="cookie" v-model="consent" checkbox-class="checkbox--inline">
            <span>{{ $t('overlays.cookies.allow-tracking') }}</span>
        </checkbox>
        <button @click="toggle(false)" class="btn btn--primary btn--rounded">
            {{ $t('overlays.cookies.save') }}
        </button>
    </modal-wrapper-inline>
</template>

<script>
import { eventBus } from '@/eventBus';
import Checkbox from '@/components/Checkbox';
import ModalWrapperInline from '@/components/modal/ModalWrapperInline';

export default {
    name: 'CookieSettingsModal',
    components: { ModalWrapperInline, Checkbox },
    data() {
        return {
            open: false,
            consent: false,
        };
    },
    methods: {
        toggle(value) {
            this.open = value;
        },
    },
    watch: {
        consent(newValue) {
            if (newValue) {
                this.$matomo && this.$matomo.setConsentGiven();
                this.$matomo && this.$matomo.rememberConsentGiven(24 * 90); // 24h * 90d
            } else {
                this.$matomo && this.$matomo.forgetConsentGiven();
            }
        },
        open() {
            if (this.$matomo && this.$matomo.hasConsent()) {
                this.consent = true;
            }
        },
    },
    mounted() {
        eventBus.$on('cookie-settings-modal', this.toggle);
    },
    beforeDestroy() {
        eventBus.$off('cookie-settings-modal');
    },
};
</script>
