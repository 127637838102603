<template>
    <div>
        <button class="bankstatus__button bankstatus__button--info" @click="openModal">
            <app-icon icon="info" class="icon" size-px="24px" />
        </button>
        <modal-wrapper :open.sync="open" :title="$t('overlays.bankstatus.headline')" matomo-name="Bankstatus" wide>
            <div class="bankstatus-table">
                <table>
                    <thead>
                        <tr>
                            <th class="service-header">{{ $t('overlays.bankstatus.text.table-headline') }}</th>
                            <th
                                class="service-header"
                                v-for="level in bankstatus"
                                :key="level.NAME"
                                :class="'status-' + level.NAME"
                            >
                                <span class="current-state-label" v-if="isCurrentTarriff(level.NAME)">{{
                                    $t('overlays.bankstatus.text.current-state')
                                }}</span>
                                <app-icon custom-class="icon" :icon="`${level.NAME}-trophy`" size-px="64px" />
                                <span>{{ $t('bankstatus.levels.' + level.NAME + '.short_name') }} </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(advantage, index) in advantages"
                            :key="`advantage-${index}`"
                            :class="{ 'first-of-category': advantage.solid_top }"
                        >
                            <th class="left-column">
                                <div class="icon-wrapper">
                                    <app-icon custom-class="small-icon" size-px="24px" :icon="advantage.icon" />
                                </div>
                                <p v-html="$t(advantage.text)" />
                            </th>
                            <td
                                v-for="(level, innerIndex) in ['classic', 'comfort', 'silver', 'gold']"
                                :key="`${index}-${innerIndex}`"
                                :class="{
                                    'no-benefit': advantage[level] === 'no-benefits',
                                }"
                            >
                                {{ text(advantage[level]) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="small-text">
                {{ $t('overlays.bankstatus.text.info') }}
            </p>
            <p class="small-text mt-1 color-gray">
                {{ $t('overlays.bankstatus.text.footnote-1') }}
            </p>
            <p class="small-text mt-1 color-gray" v-html="$t('overlays.bankstatus.text.footnote-2')" />
            <p class="small-text mt-1 color-gray" v-html="$t('overlays.bankstatus.text.footnote-3')" />
        </modal-wrapper>
    </div>
</template>

<script>
import ModalWrapper from '@/components/modal/ModalWrapper';
import AppIcon from '@/components/AppIcon';
import { BANKSTATUS } from '@/helpers';
import { eventBus } from '@/eventBus';

export default {
    name: 'BankStatusModal',
    components: { AppIcon, ModalWrapper },
    data() {
        return {
            open: false,
            advantages: [
                {
                    icon: 'status-hand',
                    text: 'overlays.bankstatus.text.modules-requirements',
                    classic: 'ab 1',
                    comfort: 'ab 6',
                    silver: 'ab 9',
                    gold: 'ab 12',
                },
                {
                    solid_top: true,
                    icon: 'status-euro',
                    text: 'overlays.bankstatus.text.account-benefit',
                    classic: '0,00 €',
                    comfort: '3,00 €',
                    silver: '6,00 €',
                    gold: '9,00 €',
                },
                {
                    icon: 'status-money',
                    text: 'overlays.bankstatus.text.custody-fee',
                    classic: '25.000 €',
                    comfort: '75.000 €',
                    silver: '125.000 €',
                    gold: '225.000 €',
                },
                {
                    icon: 'status-percent',
                    text: 'overlays.bankstatus.text.discount',
                    classic: '',
                    comfort: '',
                    silver: '3 %',
                    gold: '3 %',
                },
                {
                    icon: 'status-airplane',
                    text: 'overlays.bankstatus.text.coupon',
                    classic: '',
                    comfort: '',
                    silver: '',
                    gold: '50 €',
                },
            ],
        };
    },
    computed: {
        bankstatus() {
            return BANKSTATUS;
        },
    },
    mounted() {
        eventBus.$on('modal-bankstatus', this.openModal);
    },
    beforeDestroy() {
        eventBus.$off('modal-bankstatus');
    },
    methods: {
        openModal() {
            this.open = true;
        },
        text(value) {
            switch (value) {
                case 'no-benefits':
                    return this.$t('overlays.bankstatus.text.no-benefits');
                case 'check':
                    return '✓';
                default:
                    return value;
            }
        },
        tariffClass(tariff) {
            let cssclass = 'status-' + tariff;
            if (this.isCurrentTarriff(tariff)) {
                cssclass += ' is-active';
            }
            return cssclass;
        },
        isCurrentTarriff(tariff) {
            return tariff === this.$store.getters.getBankStatus;
        },
    },
};
</script>
