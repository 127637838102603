export default {
    0: {
        type: 'Risikoavers',
        goal: 'Überhaupt kein Risiko gewünscht, Wertverlust durch Inflation wird in Kauf genommen',
    },
    1: {
        type: 'Sicherheitsorientiert',
        goal: 'Stetige Wertentwicklung zum Inflationsausgleich, ein geringes Risiko wird in Kauf genommen',
    },
    2: {
        type: 'Konservativ',
        goal: 'Höhere Erträge, mögliche Kursgewinne, aber auch etwas mehr Risiko',
    },
    3: {
        type: 'Gewinnorientiert',
        goal: 'Langfristiger Kapitalzuwachs, dabei mittleres Risiko',
    },
    4: {
        type: 'Chancenorientiert',
        goal: 'Überdurchschnittlich hohe Ertragserwartungen, aber ein höheres Risiko',
    },
    5: {
        type: 'Risikofreudig',
        goal: 'Maximal mögliche Rendite, aber mit hohem Risiko',
    },
};
