var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize:debounce.50",value:(_vm.updateScrollableState),expression:"updateScrollableState",arg:"debounce",modifiers:{"50":true}}],ref:"appcontainer",staticClass:"pagewrap",class:[
        {
            'is-scrollable': _vm.isScrollable && !_vm.isScrolledToEnd,
            'is-scrolled': _vm.isScrolled,
            'is-scrolled-to-end': _vm.isScrolledToEnd,
            'show-scrolled-module-state': _vm.isScrolledModuleShown,
            'is-prefilled': _vm.isPrefilled,
        },
        _vm.$route.meta.appClass ],attrs:{"id":"app"}},[(_vm.isStandalone)?_c('HeaderStandalone',{attrs:{"showScrolledModuleState":_vm.isScrolledModuleShown,"isScrolled":_vm.isScrolled}}):_c('Header',{attrs:{"showScrolledModuleState":_vm.isScrolledModuleShown,"isScrolled":_vm.isScrolled}}),_c('transition',{attrs:{"name":"fadepage","mode":"out-in"}},[(_vm.$store.state.splashscreen)?_c('Splashscreen'):_c('router-view')],1),_c('Footer'),_c('OverlayBG',{attrs:{"is-active":_vm.getViewState.overlayBG}}),_c('ContactOverlay',{attrs:{"type":"contact","is-active":_vm.getViewState.contactOverlay}}),_c('ContactOverlay',{attrs:{"type":"clipboard","is-active":_vm.getViewState.clipboardOverlay}}),_c('QuestionnaireResultOverlay',{attrs:{"type":"questionnaire-results","is-active":_vm.getViewState.questionnaireResultsOverlay}}),_c('Overlay',{attrs:{"headline":_vm.$t('overlays.form-success.headline'),"is-active":this.getViewState.formSuccessOverlay,"view-name":"formSuccessOverlay"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('overlays.form-success.message'))}})]),_c('Overlay',{attrs:{"headline":_vm.$t('overlays.form-error.headline'),"is-active":this.getViewState.formErrorOverlay,"view-name":"formErrorOverlay"}},[_c('div',{staticClass:"flex"},[_c('app-icon',{staticClass:"mr-1",attrs:{"icon":"alert","size-px":"64px"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('overlays.form-error.message'))}})],1)]),_c('popup-bookmark'),_c('modal-background'),_c('cookie-settings-modal'),_c('portal-target',{attrs:{"name":"root"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }