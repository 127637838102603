<template>
    <div class="clipboard clipboard-old" v-if="products.length > 0">
        <div class="clipboard__header">
            <h2 class="clipboard__headline">
                {{ $t('clipboard.old-clipboard-title') }}
            </h2>
        </div>
        <div class="clipboard__content">
            <div class="clipboard__section">
                <div class="clipboard-text color-gray">
                    <ul class="clipboard-old__productlist">
                        <li
                            class="clipboard-old-product"
                            v-for="(product, index) in products"
                            :key="`old-interest-${index}`"
                        >
                            <div class="clipboard-old-product__icon">
                                <app-icon :icon="productIcon(product)" size-px="24px" />
                            </div>
                            <div class="clipboard-old-product__headline">
                                {{ getProductById(product).headline }}
                            </div>
                        </li>
                    </ul>

                    <button
                        @click="applyOldInterests"
                        :disabled="applied"
                        class="btn btn--icon btn--rounded btn--outline"
                    >
                        {{ $t('clipboard.old-clipboard-apply') }}
                        <app-icon icon="arrow-right" size-px="18px" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';
import IconMapping from '@/data/productIconMapping.json';

export default {
    name: 'OldClipboard',
    components: { AppIcon },
    computed: {
        ...mapGetters({
            products: 'clipboard/getOldClipboardProducts',
            applied: 'clipboard/oldClipboardApplied',
            cloudReset: 'clipboard/oldClipboardCloudReset',
            getProductById: 'getProductById',
        }),
    },
    methods: {
        productIcon(productId) {
            return IconMapping[productId];
        },
        // sollte nur einmal anklickbar sein,also "disabled" machen danach, dafür brauchen wir noch einen flag im store!
        applyOldInterests() {
            this.products.forEach((productId) => {
                this.$store.dispatch('updateProductStatus', {
                    productId: productId,
                    option: 'INTEREST',
                    payload: true,
                });
            });
            this.$store.dispatch('clipboard/oldClipboardApplied');
        },
    },
};
</script>
