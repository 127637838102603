<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Verreisen Sie regelmäßig oder sind Sie regelmäßig im Ausland?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="options" v-model="inputValue" />
            <InfoBox v-if="inputValue === 'yes'">
                Mit der GoldCard sind Barverfügungen im Ausland kostenlos und Sie zahlen kein Auslandseinsatzentgelt
            </InfoBox>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            options: {
                yes: {
                    icon: '',
                    label: 'Ja',
                },
                no: {
                    icon: '',
                    label: 'Nein',
                },
            },
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('value');
            },
            set(value) {
                this.isAnswered = value !== '';
                this.updateQuestionState('value', value);
            },
        },
    },
    mounted() {
        if (this.inputValue !== '') {
            this.isAnswered = true;
        }
    },
};
</script>
