<template>
    <div class="product__options">
        <Checkbox
            v-if="visibilityState.INTEREST"
            val="interest"
            v-model="interestOptionState"
            checkbox-class="checkbox--radio"
            @input="onInput('INTEREST')"
        >
            <span>{{ $t('products.options.interest') }}</span>
        </Checkbox>

        <Checkbox
            v-if="visibilityState.BANK"
            val="bank"
            v-model="bankOptionState"
            checkbox-class="checkbox--radio"
            :disabled="isDisabled"
            @input="onInput('BANK')"
        >
            <span>{{ $t('products.options.bank') }}</span>
        </Checkbox>

        <Checkbox
            v-if="visibilityState.EXTERNAL"
            val="external"
            v-model="externalOptionState"
            checkbox-class="checkbox--radio"
            @input="onInput('EXTERNAL')"
        >
            <span>{{ externalOptionLabel }}</span>
        </Checkbox>

        <Checkbox
            v-if="visibilityState.RETIRED"
            val="external"
            v-model="retiredOptionState"
            checkbox-class="checkbox--radio"
            @input="onInput('RETIRED')"
        >
            <span>{{ externalOptionLabel }}</span>
        </Checkbox>
    </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        Checkbox,
    },
    props: {
        product: {
            type: Object,
        },
        isPromoted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isPrefilled', 'getPersonalData']),
        isRetired() {
            return this.getPersonalData.isRetired;
        },
        interestOptionState: {
            get() {
                return this.product.options.INTEREST;
            },
            set(value) {
                this.$store.dispatch('updateProductStatus', {
                    productId: this.product.id,
                    option: 'INTEREST',
                    payload: value,
                });
            },
        },
        bankOptionState: {
            get() {
                return this.product.options.BANK;
            },
            set(value) {
                this.$store.dispatch('updateProductStatus', {
                    productId: this.product.id,
                    option: 'BANK',
                    payload: value,
                });
            },
        },
        externalOptionState: {
            get() {
                return this.product.options.EXTERNAL;
            },
            set(value) {
                this.$store.dispatch('updateProductStatus', {
                    productId: this.product.id,
                    option: 'EXTERNAL',
                    payload: value,
                });
            },
        },
        retiredOptionState: {
            get() {
                return this.product.options.RETIRED;
            },
            set(value) {
                this.$store.dispatch('updateProductStatus', {
                    productId: this.product.id,
                    option: 'RETIRED',
                    payload: value,
                });
            },
        },
        visibilityState() {
            // Handle product exceptions
            let isVisible = {
                INTEREST: true,
                BANK: true,
                EXTERNAL: true,
                RETIRED: false,
            };

            // Just show option "External"
            const onlyShowExternal = ['Immobilie_Abgezahlte_Immobilie', 'Vorsorge_Ausreichend_Vermoegen'];

            const dontShowExternal = [
                'Liquiditaet_Premium',
                'Liquiditaet_Privat',
                'Liquiditaet_Direkt',
                'Liquiditaet_Girokonto',
                'Liquiditaet_Jugendkonto',
                'Liquiditaet_Geschaeftskonto',
                'Liquiditaet_Mitarbeiterkonto',
            ];

            // Exception for prefilled mode
            if (this.isPrefilled) {
                if (this.bankOptionState !== true) {
                    isVisible.INTEREST = true;
                    isVisible.BANK = false;
                    isVisible.EXTERNAL = true;
                } else {
                    isVisible.INTEREST = false;
                    isVisible.BANK = true;
                    isVisible.EXTERNAL = false;
                }
            }

            // Exceptions for Products in Extra field
            if (this.product.moduleId === 'extra') {
                isVisible.INTEREST = false;
                isVisible.EXTERNAL = false;
                isVisible.BANK = true;
            }

            // INFO: Hardcoded special case for EXTRA
            if (this.product.id === 'Extra_digitale_girocard') {
                if (this.isPrefilled) {
                    if (this.bankOptionState) {
                        isVisible.INTEREST = false;
                        isVisible.EXTERNAL = false;
                        isVisible.BANK = true;
                    } else {
                        isVisible.INTEREST = true;
                        isVisible.EXTERNAL = false;
                        isVisible.BANK = false;
                    }
                } else {
                    isVisible.INTEREST = true;
                    isVisible.EXTERNAL = false;
                    isVisible.BANK = true;
                }
            }

            // Exception for "Abgezahlte Immobilie" & "Ausreichend Vermögen"
            if (onlyShowExternal.indexOf(this.product.id) !== -1) {
                isVisible.INTEREST = false;
                isVisible.BANK = false;
                isVisible.EXTERNAL = false;
                isVisible.RETIRED = true;
            }

            // Exceptions for checking account products
            if (dontShowExternal.indexOf(this.product.id) !== -1) {
                isVisible.EXTERNAL = false;
            }

            return isVisible;
        },
        isDisabled() {
            return this.isPrefilled;
            /*if (
        this.product.moduleId === "extra" &&
        this.isPrefilled &&
        this.product.id !== "Extra_Elektronischer_Kontoauszug"
      ) {
        return true;
      } else {
        return false;
      }*/
        },
        externalOptionLabel() {
            let label = this.$t('products.options.external');
            const exceptions = ['Immobilie_Abgezahlte_Immobilie', 'Vorsorge_Ausreichend_Vermoegen'];

            // Show other external label for some products
            if (exceptions.indexOf(this.product.id) !== -1) {
                label = this.$t('products.options.external_alt');
            }

            return label;
        },
    },
    methods: {
        onInput(option) {
            const optionTranslation = this.$t('products.options.' + option.toLowerCase());
            if (this.isPromoted) {
                this.$matomo && this.$matomo.trackEvent('Products', 'click-recommended', optionTranslation);
            } else {
                this.$matomo && this.$matomo.trackEvent('Products', 'click', optionTranslation);
            }
            // Update excludeFromRetirement flag
            if (this.product.moduleId === 'vorsorge') {
                // Handle case with isRetired "off"
                if (!this.isRetired) {
                    if (Object.values(this.product.options).some((option) => option)) {
                        this.$store.commit('UPDATE_EXCLUDE_FROM_RETIREMENT_STATE', {
                            productId: this.product.id,
                            payload: true,
                        });
                    }
                } else {
                    // Handle case with isRetired "on"
                    if (option === 'EXTERNAL' && this.externalOptionState) {
                        this.$store.commit('UPDATE_EXCLUDE_FROM_RETIREMENT_STATE', {
                            productId: this.product.id,
                            payload: true,
                        });
                    }
                }

                // disable excludeFromRetirement if no option is selected
                if (!Object.values(this.product.options).some((option) => option)) {
                    this.$store.commit('UPDATE_EXCLUDE_FROM_RETIREMENT_STATE', {
                        productId: this.product.id,
                        payload: false,
                    });
                }
            }
        },
    },
    watch: {
        retiredOptionState(value) {
            // Toggle "sufficientFunds" in personal data (shows up in clipboard)
            if (this.product.id === 'Vorsorge_Ausreichend_Vermoegen') {
                this.$store.commit('UPDATE_PERSONAL_DATA', {
                    data: 'sufficientFunds',
                    payload: value,
                });
            }

            // Toggle "paidOffProperty" in personal data (shows up in clipboard)
            if (this.product.id === 'Immobilie_Abgezahlte_Immobilie') {
                this.$store.commit('UPDATE_PERSONAL_DATA', {
                    data: 'paidOffProperty',
                    payload: value,
                });
            }
        },
    },
};
</script>
