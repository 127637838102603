<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Mit welcher professionellen Beratung möchten Sie Ihr Anlageziel verwirklichen?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="options" v-model="inputValue" />
            <InfoBox>
                Gern stellt Ihnen die Leipziger Volksbank Basisinformationen zu Investmentfonds und Vermögensverwaltung
                zur Verfügung.
            </InfoBox>
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            options: {
                personal: {
                    icon: '',
                    label: 'Mit umfassender, persönlicher Beratung',
                },
                digital: {
                    icon: '',
                    label: 'Mit automatisierter, digitaler Beratung',
                },
                none: {
                    icon: '',
                    label: 'Ich brauche keine Beratung',
                },
            },
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('consulting');
            },
            set(value) {
                this.isAnswered = value !== '';
                this.updateQuestionState('consulting', value);
            },
        },
    },
};
</script>
