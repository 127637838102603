<template>
    <div class="product" :class="productStatusClass">
        <div>
            <span v-if="isProductRecommended" class="promoted-label">{{ $t('products.recommendation-label') }}</span>
            <toggle-switch
                class="hide-option"
                v-if="isProductRecommended"
                :label="$t('products.relevant')"
                :isActive="!doNotPromote"
                :product-state="productStatusClass"
                @change="togglePromoteState"
            />
        </div>
        <div class="product__content">
            <app-icon custom-class="product__icon" size-px="48px" :icon="productIcon" />
            <div class="product__inner">
                <h5 class="product__headline">{{ product.headline }}</h5>
                <div v-if="inDevelopment">
                    <code>{{ product.id }}</code>
                </div>
                <ul v-if="featureCount > 1">
                    <li v-for="(feature, index) in product.description" :key="index">
                        {{ feature }}
                    </li>
                </ul>
                <p v-else>
                    {{ product.description[0] }}
                    <small v-if="product.id === 'Liquiditaet_mobile_App' || product.id === 'Extra_digitale_girocard'">
                        <i>
                            (Nutzung mindestens einmal in den letzten drei Monaten)
                        </i>
                    </small>
                </p>
            </div>
            <div class="action-buttons">
                <div class="action-buttons-content">
                    <product-sound-button :parent-class="productStatusClass" :product-id="product.id" />
                    <product-image-modal :parent-class="productStatusClass" :product-id="product.id" />
                </div>
            </div>
        </div>
        <ProductOptions :product="product" :is-promoted="isProductRecommended" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconMap from '@/data/productIconMapping.json';
import ProductOptions from '@/components/product/ProductOptions.vue';
import { PRODUCT_FIELD_STATUS } from '@/helpers';
import ProductSoundButton from '@/components/product/ProductSoundButton';
import ProductImageModal from '@/components/product/ProductImageModal';
import AppIcon from '@/components/AppIcon';
import ToggleSwitch from '@/components/inputs/ToggleSwitch';

export default {
    components: {
        ToggleSwitch,
        AppIcon,
        ProductImageModal,
        ProductSoundButton,
        ProductOptions,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    methods: {
        togglePromoteState() {
            this.doNotPromote = !this.doNotPromote;
        },
    },
    computed: {
        ...mapGetters({
            isPrefilled: 'isPrefilled',
            getPromotedProducts: 'recommendation/getPromotedProducts',
            getQuestionnaireRecommendations: 'recommendation/getQuestionnaireRecommendations',
            getHiddenPromotions: 'recommendation/getHiddenPromotions',
        }),
        inDevelopment() {
            return process.env.NODE_ENV === 'development';
        },
        featureCount() {
            let count = 0,
                key;
            for (key in this.product.description) {
                if (this.product.description.hasOwnProperty(key)) {
                    count++;
                }
            }
            return count;
        },
        productStatus() {
            const options = this.product.options;
            let status = PRODUCT_FIELD_STATUS.EMPTY;

            if (options.BANK === true || options.INTEREST === true) {
                return PRODUCT_FIELD_STATUS.FILLED;
            } else if (options.EXTERNAL === true) {
                return PRODUCT_FIELD_STATUS.DASHED;
            }

            return status;
        },
        productIcon() {
            return IconMap[this.product.id];
        },
        isProductRecommended() {
            return this.getPromotedProducts.includes(this.product.id);
            /* UPDATE 2020 12 11: always recommend E3, no difference between open/prefilled!!!
            if (this.isPrefilled) {
                return this.getPromotedProducts.includes(this.product.id);
            }
            return this.getQuestionnaireRecommendations.includes(this.product.id);
             */
        },
        productStatusClass() {
            return {
                'is-dashed': this.productStatus === PRODUCT_FIELD_STATUS.DASHED,
                'is-filled': this.productStatus === PRODUCT_FIELD_STATUS.FILLED,
                'is-promoted': this.isProductRecommended && !this.getHiddenPromotions.includes(this.product.id),
                'is-not-relevant': this.isProductRecommended && this.getHiddenPromotions.includes(this.product.id),
            };
        },
        doNotPromote: {
            get() {
                return this.product.doNotPromote;
            },
            set(value) {
                this.$store.dispatch('updateProductStatus', {
                    productId: this.product.id,
                    option: 'HIDE',
                    payload: value,
                });
            },
        },
    },
};
</script>
