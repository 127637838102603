<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Wie viel können Sie monatlich zurücklegen?
            </h3>
        </div>
        <div class="question__content">
            <CustomRangeSlider v-model="inputValue" :values="backupMoneyValues" label="€" />

            <InfoBox class="pdr-3">
                Wenn Sie diesen Sparbetrag monatlich in einem Wertpapiersparplan anlegen, können Sie damit
                <b class="color-cta">{{ euro(savedAmount) }}</b>
                in 10 Jahren (2008-2018) ansparen. Das sind
                <b class="color-cta">{{ euro(savedAmountDifference) }}</b> mehr als auf einem Giro- oder Tagesgeldkonto.
                <span
                    class="info-btn"
                    v-tooltip.top.hover.ios="
                        'Mit einer dynamischen Sparrate können Sie ihr Sparverhalten automatisch an Gehaltssteigerungen und die Inflation anpassen.'
                    "
                >
                    <svg class="icon" width="20" height="20">
                        <use xlink:href="#icon-info" />
                    </svg>
                </span>
            </InfoBox>

            <div class="mt-1 mb-1">
                <p class="small-text italic-text color-gray">
                    Berechnung basiert auf dem Vergleich eines Index-Fondssparplans mit einem marktüblich habenzinslosen
                    Giro- oder Tagesgeldkonto. Hierbei handelt es sich nicht um eine Anlageberatung, sondern lediglich
                    um ein Rechenbeispiel auf Basis der vergangenen Jahre.
                </p>
            </div>

            <button class="btn btn--small btn--outline btn--icon" @click="openCalculatorOverlay">
                Berechnen Sie selbst
                <svg class="icon" width="16px" height="16px">
                    <use xlink:href="#icon-arrow-right" />
                </svg>
            </button>
        </div>
        <Overlay :headline="`Wertpapieranlagen lohnen sich!`" :is-active="this.getViewState.overlay">
            <strong>Ihr Sparziel:</strong>
            <RangeSlider
                :minRange="0"
                :maxRange="1000000"
                label="€"
                :step="1000"
                v-model="inputValueCustomInvestmentAmount"
            />
            <strong>Ihre gewünschter Anlagezeitraum:</strong>
            <RangeSlider
                :minRange="0"
                :maxRange="30"
                label="Jahre"
                :step="1"
                v-model="inputValueCustomInvestmentDuration"
            />
            <strong>Einzahlung auf Giro-/Tagesgeldkonto:</strong>
            <RangeSlider :minRange="0" :maxRange="5" label="%" :step="0.1" v-model="inputValueCustomReturnCallMoney" />
            <strong>Anlage in Wertpapiersparplan:</strong>
            <RangeSlider :minRange="0" :maxRange="10" label="%" :step="0.1" v-model="inputValueCustomReturn" />
            <p class="small-text">
                Notwendige mtl. Einzahlung zum Erreichen des Sparziels bei Einzahlung auf <em>Giro-/Tagesgeldkonto</em>:
                <b class="color-cta">{{ euro(monthlyRateBank) }}</b>
            </p>
            <p class="small-text">
                Notwendige mtl. Einzahlung zum Erreichen des Sparziels bei Anlage in
                <em>Wertpapiersparplan</em>:
                <b class="color-cta">{{ euro(monthlyRateFunds) }}</b>
            </p>
            <InfoBox>
                Die monatlichen Einzahlungen in einen Wertpapiersparplan wären um ca.
                <b class="color-cta">{{ euro(monthlyRateDifference) }}</b> niedriger als auf ein Giro-/ Tagesgeldkonto.
            </InfoBox>
        </Overlay>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';
import Helpers from '@/helpers';
import investmentCalculation from '@/data/investmentCalculation';
import CustomRangeSlider from '@/components/inputs/CustomRangeSlider';

export default {
    components: { CustomRangeSlider },
    mixins: [questionPropertiesMixin],
    data() {
        return {
            backupMoneyValues: [
                0,
                // 0 - 500 (20 á 25)
                25,
                50,
                75,
                100,
                125,
                150,
                175,
                200,
                225,
                250,
                275,
                300,
                325,
                350,
                375,
                400,
                425,
                450,
                475,
                500,
                // 500 - 2.000 (20 á 75)
                575,
                650,
                725,
                800,
                875,
                950,
                1025,
                1100,
                1175,
                1250,
                1325,
                1400,
                1475,
                1550,
                1625,
                1700,
                1775,
                1850,
                1925,
                2000,
            ],
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('reserve');
            },
            set(value) {
                this.updateQuestionState('reserve', value);
            },
        },
        savedAmount() {
            return this.calcSavedAmount(
                this.inputValue,
                investmentCalculation.assumedReturn,
                investmentCalculation.investmentDuration
            );
        },
        savedAmountDifference() {
            let savedAmountDifference =
                this.calcSavedAmount(
                    this.inputValue,
                    investmentCalculation.assumedReturn,
                    investmentCalculation.investmentDuration
                ) -
                this.calcSavedAmount(
                    this.inputValue,
                    investmentCalculation.assumedReturnCallMoney,
                    investmentCalculation.investmentDuration
                );
            return savedAmountDifference;
        },

        monthlyRateBank() {
            return this.calcMonthlyRate(
                this.inputValueCustomInvestmentAmount,
                Helpers.formatNumberAsPercent(this.inputValueCustomReturnCallMoney, 3),
                this.inputValueCustomInvestmentDuration
            );
        },

        monthlyRateFunds() {
            return this.calcMonthlyRate(
                this.inputValueCustomInvestmentAmount,
                Helpers.formatNumberAsPercent(this.inputValueCustomReturn, 3),
                this.inputValueCustomInvestmentDuration
            );
        },

        monthlyRateDifference() {
            return this.monthlyRateBank - this.monthlyRateFunds;
        },

        inputValueCustomInvestmentAmount: {
            get() {
                return this.getQuestionState('custom_investment_amount');
            },
            set(value) {
                this.updateQuestionState('custom_investment_amount', value);
            },
        },
        inputValueCustomInvestmentDuration: {
            get() {
                return this.getQuestionState('custom_investment_duration');
            },
            set(value) {
                this.updateQuestionState('custom_investment_duration', value);
            },
        },
        inputValueCustomReturnCallMoney: {
            get() {
                // get value as floating number with max 1 decimal (4.8)
                return Helpers.formatPercentAsNumber(this.getQuestionState('custom_return_call_money'), 1);
            },
            set(value) {
                // save value as floating number 0.048 with max 3 decimals
                this.updateQuestionState('custom_return_call_money', Helpers.formatNumberAsPercent(value, 3));
            },
        },
        inputValueCustomReturn: {
            get() {
                // get value as floating number with max 1 decimal (4.8)
                return Helpers.formatPercentAsNumber(this.getQuestionState('custom_return'), 1);
            },
            set(value) {
                // save value as floating number with max 3 decimals (0.048)
                this.updateQuestionState('custom_return', Helpers.formatNumberAsPercent(value, 3));
            },
        },
    },
    methods: {
        euro(value) {
            return Helpers.formatNumberAsEuro(value);
        },
        calcSavedAmount(savedAmount, assumedReturn, investmentDuration) {
            let reserve = savedAmount;
            let monthlyReturn = Math.pow(1 + assumedReturn, 1 / 12) - 1;
            let total =
                (reserve * (monthlyReturn + 1) * (Math.pow(monthlyReturn + 1, investmentDuration * 12) - 1)) /
                monthlyReturn;
            if (Number.isNaN(total)) {
                total = 12 * investmentDuration * reserve;
            }
            return total;
        },
        calcMonthlyRate(saveGoal, investmentReturn, investmentDuration) {
            let saveAmount = Math.round(saveGoal / 100) * 100;
            let monthlyReturn = Math.pow(1 + investmentReturn, 1 / 12) - 1;

            // calc monthly rate
            let monthlyRate =
                (saveAmount / (1 + monthlyReturn) / (Math.pow(1 + monthlyReturn, 12 * investmentDuration) - 1)) *
                monthlyReturn;

            // if monthly rate is not valid
            if (Number.isNaN(monthlyRate)) {
                monthlyRate = saveAmount / 12 / investmentDuration;
            }
            return monthlyRate;
        },
        openCalculatorOverlay() {
            this.$store.commit('UPDATE_VIEW', {
                view: 'overlay',
                payload: true,
            });
        },
    },
    mounted() {
        // Direct set isAnswered because there's no interaction needed
        this.isAnswered = true;
    },
    watch: {
        inputValueCustomReturnCallMoney(value) {
            if (value > this.inputValueCustomReturn) {
                this.inputValueCustomReturn = value;
            }
        },
        inputValueCustomReturn(value) {
            if (value <= this.inputValueCustomReturnCallMoney) {
                this.inputValueCustomReturnCallMoney = value;
            }
        },
    },
};
</script>
