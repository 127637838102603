<template>
    <section class="splash">
        <div class="splash__inner">
            <header class="splash__header">
                <h1>{{ $t(`splashscreen.title`) }}</h1>
            </header>
            <div class="splash__content">
                <div class="video">
                    <video
                        src="../../public/assets/videos/introvideo.mp4"
                        width="640"
                        height="360"
                        controls=""
                        poster="../../public/assets/videos/introvideo-poster.jpg"
                    ></video>
                </div>
            </div>
            <footer class="splash__footer">
                <p
                    class="small-text color-gray align-left"
                    v-html="
                        $t('splashscreen.terms', {
                            privacy: $t('splashscreen.privacy-policy'),
                            privacy2: $t('splashscreen.privacy-policy-2'),
                            termsofuse: $t('splashscreen.terms-of-use'),
                        })
                    "
                ></p>
                <div class="input-block">
                    <label class="label" for="input-code">
                        {{ $t('splashscreen.enter-code') }}
                    </label>
                    <input class="code-input" maxlength="8" type="text" id="input-code" name="Code" v-model="code" />
                </div>
                <button class="btn btn--cta btn--rounded btn--icon" :disabled="isLoading" @click="submit">
                    {{ isLoading ? $t('splashscreen.is-loading') : $t('splashscreen.submit-btn') }}
                    <svg v-if="!isLoading" class="icon" width="16px" height="16px">
                        <use xlink:href="#icon-arrow-right" />
                    </svg>
                </button>
            </footer>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            cookieConsent: false,
            code: '',
        };
    },
    computed: {
        isLoading() {
            return this.$store.getters['getApiIsLoading'];
        },
    },
    methods: {
        /* Update: 2020-12-22: Remove "Start without Tracking" also from Leipzig
        submitNoTracking() {
            this.$matomo && this.$matomo.forgetConsentGiven();
            this.$store.commit('UPDATE_SPLASHSCREEN', false);
        },
        */
        submit() {
            if (this.isLoading) {
                // do not call action if loading
                return;
            }

            this.$matomo && this.$matomo.setConsentGiven();
            this.$matomo && this.$matomo.rememberConsentGiven(24 * 90); // 24h * 90d

            if (this.code && this.code.length > 0) {
                this.$store
                    .dispatch('activatePrefilledMode', this.code)
                    .then(() => {
                        this.$matomo && this.$matomo.trackEvent('Sitzung', 'start', 'mit Code');
                        this.$store.commit('UPDATE_SPLASHSCREEN', false);
                    })
                    .catch(() => {
                        this.$matomo && this.$matomo.trackEvent('Sitzung', 'start', 'ohne Code');
                        this.$store.commit('UPDATE_SPLASHSCREEN', false);
                    });
            } else {
                this.$matomo && this.$matomo.trackEvent('Sitzung', 'start', 'ohne Code');
                this.$store.commit('UPDATE_SPLASHSCREEN', false);
            }
        },
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('code') && urlParams.get('code').length > 0) {
            this.code = urlParams.get('code');
        }
    },
};
</script>
