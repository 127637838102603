<template>
    <div class="question">
        <div class="question__header">
            <h3 class="question__headline">
                Nutzen Sie das Online-Banking und haben Sie ein Smartphone oder Tablet?
            </h3>
        </div>
        <div class="question__content">
            <RadioGroup :options="options" v-model="inputValue" />
        </div>
    </div>
</template>

<script>
import { questionPropertiesMixin } from '@/mixins/questionProperties';

export default {
    mixins: [questionPropertiesMixin],
    data() {
        return {
            options: {
                yes: {
                    icon: '',
                    label: 'Ja',
                },
                no: {
                    icon: '',
                    label: 'Nein',
                },
            },
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.getQuestionState('value');
            },
            set(value) {
                this.isAnswered = value !== '';
                this.updateQuestionState('value', value);
            },
        },
    },
};
</script>
