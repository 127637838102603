import { find } from 'lodash';

export default {
    liquiditaet: {
        Question1: {
            age: 0,
            isAnswered: false,
        },
        Question2a: {
            value: '',
            isAnswered: false,
            condition: (state, products) => {
                // INFO Hardcoded super special case for Leipzig!
                const account = find(products, (obj) => {
                    // the user just can have the 'Liquiditaet_Girokonto' in prefilled mode!!!
                    return obj.id === 'Liquiditaet_Girokonto';
                });
                if (account.options.BANK) {
                    // skip this question if the app in prefilled mode set Girokonto to true!
                    return false;
                }
                return state.Question1.age >= 25;
            },
        },
        Question2b: {
            value: '',
            isAnswered: false,
            hideFromNavigation: true,
        },
        Question3: {
            value: '',
            isAnswered: false,
        },
        Question4a: {
            value: '',
            isAnswered: false,
            condition: (state) => {
                return state.Question3.value === 'yes';
            },
        },
        Question4b: {
            value: 0,
            isAnswered: false,
            hideFromNavigation: true,
        },
        Question5: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question3.value === 'no';
            },
        },
        Question6: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        Question7: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
        },
    },
    absicherung: {
        Question1: {
            age: 0,
            isAnswered: false,
        },
        Question2: {
            family_status: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 18 && state.Question1.age <= 50;
            },
        },
        Question3: {
            children: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 18 && state.Question1.age <= 50;
            },
        },
        Question4: {
            car: '',
            isAnswered: false,
            autoRedirect: true,
        },
        Question5: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
    },
    vermoegen: {
        Question1: {
            age: 0,
            isAnswered: false,
        },
        Question2: {
            liquidity: 0,
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        Question3: {
            income: 0,
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        QuestionCalculateCashReserve: {
            isAnswered: false,
            cash_reserve_gap_is_positive: false,
            cash_reserve_gap: 0,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        QuestionCalculateSecurities: {
            custom_investment_amount: 5000,
            custom_investment_duration: 10,
            custom_return_call_money: 0,
            custom_return: 0.04,
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        Question4: {
            reserve: 50,
            custom_investment_amount: 10000,
            custom_investment_duration: 10,
            custom_return_call_money: 0,
            custom_return: 0.04,
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        Question5: {
            risk_profile: 1,
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18;
            },
        },
        Question6: {
            consulting: '',
            isAnswered: false,
            condition: (state) => {
                return state.Question1.age >= 18 && state.Question5.risk_profile !== 0;
            },
        },
    },
    vorsorge: {
        Question1: {
            age: 0,
            isAnswered: false,
        },
        Question2: {
            job: '',
            isAnswered: false,
            autoRedirect: true,
        },
        Question3: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 16 && state.Question1.age <= 55;
            },
        },
        Question4: {
            value: '',
            isAnswered: false,
            autoRedirect: true,
            condition: (state) => {
                return state.Question1.age >= 56;
            },
        },
    },
    immobilie: {
        Question1: {
            age: 0,
            isAnswered: false,
        },
        Question2a: {
            property: '',
            isAnswered: false,
        },
        Question2b: {
            value: '',
            isAnswered: false,
            hideFromNavigation: true,
        },
        Question3a: {
            renovation: '',
            isAnswered: false,
            condition: (state) => {
                return state.Question2a.property === 'yes';
            },
        },
        Question3b: {
            renovation_timing: '',
            isAnswered: false,
            hideFromNavigation: true,
        },
        Question4a: {
            property: '',
            isAnswered: false,
            condition: (state) => {
                return state.Question2a.property === 'no';
            },
        },
        Question4b: {
            property_timing: '',
            isAnswered: false,
            hideFromNavigation: true,
        },
    },
};
