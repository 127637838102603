<template>
    <div class="bankstatus-container">
        <div class="progress-arrows">
            <app-icon icon="status-indicator" :class="isActive('classic')" class="level-1" />
            <app-icon icon="status-indicator" :class="isActive('comfort')" class="level-2" />
            <app-icon icon="status-indicator" :class="isActive('silver')" class="level-3" />
            <app-icon icon="status-indicator" :class="isActive('gold')" class="level-4" />
        </div>
        <div class="progress-wrapper">
            <div class="progress-wrapper__inner">
                <div v-for="i in maxCount" class="progress-container empty" :key="`empty-${i}`">
                    <div class="progress-container__inner">
                        <div class="empty" />
                    </div>
                </div>
            </div>
            <transition-group name="list" tag="div" class="progress-items">
                <div
                    v-for="module in progressArray"
                    class="progress-container"
                    :class="[module.module, module.type]"
                    :key="module.index"
                >
                    <div class="progress-container__inner">
                        <app-icon :class="module.type" :icon="`${module.module}-small`" />
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppIcon from '@/components/AppIcon';

export default {
    name: 'BankStatusProgress',
    components: { AppIcon },
    methods: {
        isActive(bankStatus) {
            return this.getBankStatus === bankStatus ? 'active' : '';
        },
        computedProgress() {
            let modules = [];
            let computeArray = [];
            for (const module of this.computedFilled) {
                if (!modules[module]) {
                    modules[module] = {
                        name: module,
                        index: 0,
                    };
                }

                const filled = {
                    type: 'filled',
                    module: module,
                    index: `${module}-${modules[module].index}`,
                };
                modules[module].index = modules[module].index + 1;
                computeArray.push(filled);
            }

            for (const module of this.computedDashed) {
                if (!modules[module]) {
                    modules[module] = {
                        name: module,
                        index: 0,
                    };
                }

                const dashed = {
                    type: 'dashed',
                    module: module,
                    index: `${module}-${modules[module].index}`,
                };
                modules[module].index = modules[module].index + 1;
                computeArray.push(dashed);
            }

            this.progressArray = computeArray.slice(0, this.maxCount);
        },
    },
    watch: {
        bankstatusCount: {
            handler: 'computedProgress',
            immediate: true,
        },
    },
    data() {
        return {
            maxCount: 12,
            progressArray: [],
        };
    },
    computed: {
        ...mapGetters(['bankstatusCount', 'getBankStatus']),
        computedFilled() {
            return this.bankstatusCount.filled.slice(0, this.maxCount);
        },
        computedDashed() {
            const showDashed = this.maxCount - this.computedFilled.length;
            return this.bankstatusCount.dashed.slice(0, showDashed);
        },
    },
};
</script>
