import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { sync } from 'vuex-router-sync';
import vbclass from 'vue-body-class';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';
import Tooltip from 'vue-directive-tooltip';
import VueClosable from 'vue-closable';
import VueObserveVisibility from 'vue-observe-visibility';
import VueScrollTo from 'vue-scrollto';
import PortalVue from 'portal-vue';

// Components
import RadioGroup from '@/components/inputs/RadioGroup';
import RangeSlider from '@/components/inputs/RangeSlider';
import RiskSlider from '@/components/inputs/RiskSlider';
import InfoBox from '@/components/inputs/InfoBox';
import Overlay from '@/components/Overlay';

// CSS
import './assets/less/app.less';
import './assets/less/print.less';
import './assets/sass/bulma.sass';

Vue.use(vbclass, router);
Vue.use(VuejsDialog);
Vue.use(VueClosable);
Vue.use(VueObserveVisibility);
Vue.use(VueScrollTo);
Vue.use(PortalVue);

Vue.use(Tooltip, {
    delay: 0,
    placement: 'top',
    triggers: ['hover'],
    offset: 8,
});

Vue.config.productionTip = false;

// Setup form validation
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('required', required);
extend('email', email);
extend('numeric', numeric);

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
    },
    defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values),
});
// End of form validation setup

// Register global input components
Vue.component('RadioGroup', RadioGroup);
Vue.component('RangeSlider', RangeSlider);
Vue.component('RiskSlider', RiskSlider);
Vue.component('InfoBox', InfoBox);
Vue.component('Overlay', Overlay);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

// Initialize vuex-router-sync
sync(store, router);

const urlParams = new URLSearchParams(window.location.search);

// Get user code from query string and save to store
/*
if (urlParams.get('kontofinder')) {
    store.dispatch('activateStandaloneMode');
    store.commit('UPDATE_SPLASHSCREEN', false);
    router.push({ name: 'questionnaire', params: { module: 'liquiditaet' } });
} else if (...)
*/
if (urlParams.get('code')) {
    store.dispatch('activatePrefilledMode', urlParams.get('code'));
} else {
    // START SIMULATION
    if (store.getters.getAnimationState('showIntroSimulation') && !store.state.splashscreen) {
        store.dispatch('showIntroSimulation');
    }
}

if (urlParams.get('berater') === '1') {
    store.dispatch('consultantMode');
} else if (window.location.hostname.includes('hausbank-leipzigervolksbank') || process.env.NODE_ENV === 'development') {
    // INFO: Hardcoded domains;
    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: window.location.hostname.includes('hausbank-leipzigervolksbank')
            ? 'https://analytics.hausbank-leipzigervolksbank.de/'
            : 'http://localhost',
        siteId: 1,

        // Changes the default .js and .php endpoint's filename
        // Default: 'matomo'
        trackerFileName: 'matomo',

        // Overrides the autogenerated tracker endpoint entirely
        // Default: undefined
        // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

        // Overrides the autogenerated tracker script path entirely
        // Default: undefined
        // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

        // Enables automatically registering pageviews on the router
        router: router,

        // Enables link tracking on regular links. Note that this won't
        // work for routing links (ie. internal Vue router links)
        // Default: true
        enableLinkTracking: true,

        // Require consent before sending tracking information to matomo
        // Default: false
        requireConsent: true,

        // Whether to track the initial page view
        // Default: true
        trackInitialView: true, // ?????? turn on or off because of tracking

        // Run Matomo without cookies
        // Default: false
        disableCookies: false,

        // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
        // Default: false
        enableHeartBeatTimer: false,

        // Set the heartbeat timer interval
        // Default: 15
        heartBeatTimerInterval: 15,

        // Whether or not to log debug information
        // Default: false
        debug: false,

        // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
        // Default: undefined
        userId: undefined,

        // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
        // Default: undefined, example '*.example.com'
        cookieDomain: undefined,

        // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
        // Default: undefined, example: '*.example.com'
        domains: undefined,

        // A list of pre-initialization actions that run before matomo is loaded
        preInitActions: [],
    });
}
